/*
----------------------------------------------------------------

Gravity Forms Front End Form Styles
http: //www.gravityforms.com
updated: January 12, 2017 04:02 PM (GMT-05:00) US Eastern Time

Gravity Forms is a Rocketgenius project
copyright 2008-2017 Rocketgenius Inc.
http: //www.rocketgenius.com
this may not be redistributed without the
express written permission of the author.

NOTE: DO NOT EDIT THIS FILE! MAKE ANY MODIFICATIONS IN YOUR
THEME STYLESHEET. THIS FILE IS REPLACED DURING AUTO-UPDATES
AND ANY CHANGES MADE HERE WILL BE OVERWRITTEN.

If you need to make extensive customizations,
copy the contents of this file to your theme
style sheet for editing. Then, go to the form
settings page & set the 'output CSS' option
to no.

----------------------------------------------------------------
*/


/* mobile styles first ​*/

.gform_wrapper {
    margin: 16px 0;
    max-width: 100%;
}

.gform_wrapper form {
    text-align: left;
    max-width: 100%;
    margin: 0 auto;
}

.gform_wrapper *,
.gform_wrapper *:before,
.gform_wrapper *:after {
    box-sizing: border-box !important;
}

.gform_wrapper h1,
.gform_wrapper h2,
.gform_wrapper h3 {
    font-weight: normal;
    border: none;
    background: none;
}

.gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
    font-size: inherit;
    font-family: inherit;
    padding: 5px 4px;
    letter-spacing: normal;
}

.gform_wrapper input[type=image] {
    border: none !important;
    padding: 0 !important;
    width: auto !important;
}

.gform_wrapper textarea {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
}

.gform_wrapper select {
    line-height: 1.5;
}

.gform_wrapper .ginput_container_multiselect select {
    background-image: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid inherit;
    outline: none;
}

.gform_wrapper ul.gform_fields {
    margin: 0 !important;
    list-style-type: none;
    display: block;
}

html>body .entry ul,
.gform_wrapper ul {
    text-indent: 0
}

.gform_wrapper li,
.gform_wrapper form li {
    margin-left: 0 !important;
    list-style-type: none !important;
    list-style-image: none !important;
    list-style: none !important;
    overflow: visible;
}

.gform_wrapper ul li.gfield {
    clear: both;
}

.gform_wrapper ul.gfield_radio li,
.gform_wrapper ul.gfield_checkbox li {
    overflow: hidden;
}

.gform_wrapper ul.gform_fields.right_label li.gfield,
.gform_wrapper form ul.gform_fields.right_label li.gfield,
.gform_wrapper ul.gform_fields.left_label li.gfield,
.gform_wrapper form ul.gform_fields.left_label li.gfield {
    margin-bottom: 14px;
}

.gform_wrapper ul.right_label li ul.gfield_radio li,
.gform_wrapper form ul.right_label li ul.gfield_radio li,
.gform_wrapper ul.left_label li ul.gfield_radio li,
.gform_wrapper form ul.left_label li ul.gfield_radio li,
.gform_wrapper ul.right_label li ul.gfield_checkbox li,
.gform_wrapper form ul.right_label li ul.gfield_checkbox li,
.gform_wrapper ul.left_label li ul.gfield_checkbox li,
.gform_wrapper form ul.left_label li ul.gfield_checkbox li {
    margin-bottom: 10px;
}

.gform_wrapper ul li:before,
.gform_wrapper ul li:after,
.gform_wrapper ul.gform_fields {
    padding: 0;
    margin: 0;
    overflow: visible;
}

.gform_wrapper select {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
}

.gform_wrapper select option {
    padding: 2px;
    display: block;
}

.gform_wrapper .inline {
    display: inline !important;
}

.gform_wrapper .gform_heading {
    width: 100%;
    margin-bottom: 18px;
}

.gform_wrapper .clear-multi{
    display: flex;
}

/* date ​fields */

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_year {
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_year {
    width: 33.333%;
    flex-direction: row;
}

@media only screen and (min-width: 321px) {

    .gform_wrapper .gfield_date_day,
    .gform_wrapper .gfield_date_month {
        max-width: 4rem;
    }

    .gform_wrapper .gfield_date_year,
    .gform_wrapper .gfield_time_ampm {
        max-width: calc(4rem + .5rem);
    }

}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
    vertical-align: top;
    flex-direction: row;
}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
    margin-right: .5rem;
}

.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_day {
    width: 4rem;
    margin-right: .5rem;
}

.gform_wrapper .gfield_date_month input[type="number"],
.gform_wrapper .gfield_date_day input[type="number"] {
    width: calc(3rem + 8px) !important;
}

.gform_wrapper .gfield_date_year input[type="number"] {
    width: calc(4rem + 8px) !important;
}

.gform_wrapper .gfield_date_year {
    width: 5rem;
}

.gform_wrapper .gfield_date_month input,
.gform_wrapper .gfield_date_day input {
    width: calc(3rem + 8px);
}

.gform_wrapper .gfield_date_year input {
    width: 4rem;
}

.gform_wrapper .field_sublabel_above div[class*="gfield_date_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_date_"].ginput_container label {
    width: 3rem;
    text-align: center;
}

.gform_wrapper .field_sublabel_above div.gfield_date_year.ginput_container label,
.gform_wrapper .field_sublabel_below div.gfield_date_year.ginput_container label {
    width: 4rem;
}

/* time ​fields */

.gform_wrapper .gfield_time_hour,
.gform_wrapper .gfield_time_minute,
.gform_wrapper .gfield_time_ampm {
    width: 33.333%;
    flex-direction: row;
}

@media only screen and (min-width: 321px) {

    .gform_wrapper .gfield_time_hour,
    .gform_wrapper .gfield_time_minute {
        max-width: 4rem;
    }

    .gform_wrapper .gfield_time_hour,
    .gform_wrapper .gfield_time_ampm {
        max-width: calc(4rem + .5rem);
    }

    .gform_wrapper .gfield_time_hour {
        max-width: calc(4rem + .5rem);
    }

}

.gform_wrapper ul.gform_fields:not(.top_label) .ginput_container_time span {
    float: left;
}

.gform_wrapper .gfield_time_hour i {
    font-style: normal !important;
    font-family: sans-serif !important;
    width: 10px;
    text-align: center;
    float: right;
    margin-top: 9%;
}

.gform_wrapper .gfield_time_minute {
    margin-right: .5rem;
}

.gform_wrapper .gfield_time_hour {
    margin-right: .25rem;
}

.gform_wrapper .gfield_time_ampm {
    vertical-align: top;
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gfield_time_ampm select {
    min-width: calc(2rem + 25px);
    width: calc(3rem + 25px);
}

.gform_wrapper .gfield_time_hour input,
.gform_wrapper .gfield_time_minute input {
    width: calc(3rem + 8px);
}

.gform_wrapper .gfield_time_hour input[type="number"],
.gform_wrapper .gfield_time_minute input[type="number"] {
    width: calc(3rem + 8px) !important;
}

.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label {
    width: 3rem;
    text-align: center;
}

.gform_wrapper .field_hover {
    border: 1px dashed #2175A9;
    cursor: pointer;
}

.gform_wrapper .field_selected {
    background-color: #DFEFFF;
    border: 1px solid #C2D7EF;
}

.gform_wrapper .field_name_first,
.gform_wrapper .field_name_middle,
.gform_wrapper .field_name_last {
    width: 49.5%;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
}
.gform_wrapper .ginput_complex.ginput_container {
    overflow: visible;
    width: 100%;
}

.gform_wrapper label.gfield_label {
    font-weight: 700;
    font-size: inherit;
}

.gform_wrapper .top_label .gfield_label {
    display: -moz-inline-stack;
    display: inline-block;
    line-height: 1.3;
    clear: both;
}

.gform_wrapper .ginput_complex input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']),
.gform_wrapper .ginput_complex  .ginput_left input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']),
.gform_wrapper .ginput_complex .ginput_right input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']) {
    width: 100%;
}

.gform_wrapper .ginput_complex .ginput_right select,
.gform_wrapper .ginput_complex select {
    width: 100%;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_date_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_date_"].ginput_container label {
    display: block;
    font-size: .813em;
    letter-spacing: .5pt;
    white-space: nowrap;
}

.gform_wrapper ul.gfield_radio li label,
.gform_wrapper ul.gfield_checkbox li label {
    display: -moz-inline-stack;
    display: inline-block;
    letter-spacing: inherit;
    vertical-align: middle;
    cursor: pointer;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label {
    margin: 9px 0 1px 1px;
}

.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_name label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_email label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_password label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_time label {
    margin-top: 0;
}

.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label {
    margin: 1px 0 9px 1px;
}

.gform_wrapper .top_label .field_sublabel_above label.gfield_label + .ginput_complex.ginput_container,
.gform_wrapper .top_label .field_sublabel_above label.gfield_label + div[class*="gfield_time_"].ginput_container {
    margin-top: 0;
}

body .gform_wrapper .top_label div.ginput_container {
    margin-top: 8px;
}

body.gform_wrapper div.clear-multi {
    display: -moz-inline-stack;
    display: inline-block;
}

body .gform_wrapper ul li.field_description_below div.ginput_container_radio,
body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox {
    margin-top: 16px;
    margin-bottom: 0;
}

.gform_wrapper .hidden_label .gfield_label {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.gfield.right_label,
.gfield.left_label {
    padding-top: 10px;
}

.gform_wrapper li.hidden_label input {
    margin-top: 12px;
}

.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container input[type=text],
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container select {
    margin-bottom: 12px;
}

.gform_wrapper label.screen-reader-text, .gform_wrapper label.hidden_sub_label {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
 }

.gform_wrapper input.small,
.gform_wrapper select.small,
.gform_wrapper input.medium,
.gform_wrapper select.medium,
.gform_wrapper input.large,
.gform_wrapper select.large,
.gform_wrapper input.large,
.gform_wrapper select.large {
    width: 100%
}

.gform_wrapper input.datepicker_with_icon.small,
.gform_wrapper input.datepicker_with_icon.medium,
.gform_wrapper input.datepicker_with_icon.large {
    width: calc(100% - 24px);
}

.gform_wrapper .gfield_error input.small,
.gform_wrapper .gfield_error select.small,
.gform_wrapper .gfield_error input.medium,
.gform_wrapper .gfield_error select.medium,
.gform_wrapper .gfield_error input.large,
.gform_wrapper .gfield_error select.large,
.gform_wrapper .gfield_error input.large,
.gform_wrapper .gfield_error select.large {
    width: 100%;
}

.gform_wrapper .gfield_error input.datepicker_with_icon.small,
.gform_wrapper .gfield_error input.datepicker_with_icon.medium,
.gform_wrapper .gfield_error input.datepicker_with_icon.large {
    width: calc(100% - 32px);
}

.gform_wrapper textarea.small {
    height: 80px;
    width: 100%;
}

.gform_wrapper textarea.medium {
    height: 120px;
    width: 100%;
}

.gform_wrapper textarea.large {
    height: 160px;
    width: 100%;
}

.gform_wrapper h2.gsection_title {
    margin: 0 !important;
    padding: 0 !important;
    letter-spacing: normal !important;
}

.gform_wrapper .gsection .gfield_label,
.gform_wrapper h2.gsection_title,
.gform_wrapper h3.gform_title {
    font-weight: 700;
    font-size: 1.25em;
}

.gform_wrapper h3.gform_title {
    letter-spacing: normal !important;
    margin: 10px 0 6px 0;
}

.gform_wrapper li.gfield.field_description_below + li.gsection {
    margin-top: 24px !important;
}

.gform_wrapper span.gform_description {
    font-weight: 400;
    display: block;
    width: calc(100% - 16px);
    margin-bottom: 16px;
}

.gform_wrapper .gsection {
    border-bottom: 1px solid #CCC;
    padding: 0 16px 8px 0;
    margin: 28px 0 28px 0;
    clear: both;
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox],
.gform_wrapper ul.gfield_radio li input[type=radio] {
    width: auto !important;
}

.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
    position: relative;
}

.gform_wrapper ul.gfield_checkbox,
.gform_wrapper ul.gfield_radio {
    margin: 6px 0;
    padding: 0;
}

.gform_wrapper .gfield_checkbox li label,
.gform_wrapper .gfield_radio li label {
    display: -moz-inline-stack;
    display: inline-block;
    padding: 0;
    width: auto;
    line-height: 1.5;
    font-size: .875em;
    vertical-align: middle;
    max-width: 85%;
    white-space: normal;
}

.gform_wrapper .gfield_checkbox li label {
    margin: 0;
}

.gform_wrapper .gfield_radio li label {
    margin: 2px 0 0 4px;
}

.gform_wrapper .gfield_checkbox li input[type=checkbox],
.gform_wrapper .gfield_radio li input[type=radio],
.gform_wrapper .gfield_checkbox li input {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1px;
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description,
.gform_wrapper .instruction {
    font-size: .813em;
    line-height: inherit;
    clear: both;
    font-family: inherit;
    letter-spacing: normal;
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description {
    padding: 0 16px 0 0;
}

.gform_wrapper .field_description_below .gfield_description {
    padding-top: 16px;
}

.gform_wrapper .field_sublabel_above .description,
.gform_wrapper .field_sublabel_above .gfield_description,
.gform_wrapper .field_sublabel_above .gsection_description {
    margin-top: 9px;
}

.gform_wrapper .top_label .gsection_description {
    width: 100%;
    margin-bottom: 9px;
}

.gform_wrapper .gfield_description {
    width: 100%;
}

.gform_wrapper .description_above .gfield_description {
    padding: 0 0 10px 0;
}

.gfield_date_year+.gfield_description {
    padding: 0;
}

.gform_wrapper .gfield_required {
    color: #790000;
    margin-left: 4px;
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: rgba(255,223,224,.25);
    margin-bottom: 6px !important;
    border-top: 1px solid #C89797;
    border-bottom: 1px solid #C89797;
    padding-bottom: 6px;
    padding-top: 8px;
    box-sizing: border-box;
}

.gform_wrapper li.gfield.gfield_creditcard_warning {
    padding: 16px 16px 14px 16px;
    border-top: 2px solid #790000;
    border-bottom: 4px solid #790000;
    background-color: rgba(255, 223, 224, 0.25);
    width: calc(100% - 16px);
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning {
    padding: 16px 16px 14px 16px;
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message {
    font-size: .875em;
    font-weight: 700;
    font-family: inherit;
    min-height: 25px;
    color: #790000;
    max-width: 100%;
    position: relative;
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message span {
    display: block;
    padding: 0 0 14px 24px;
    margin-bottom: 16px;
    line-height: 1.5;
    letter-spacing: .1pt;
    border-bottom: 1px solid #790000;
    text-transform: uppercase;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMTA2NyAyODY1IDI0IDMyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IC0xMDY3IDI4NjUgMjQgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM3OTAwMDA7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tMTA1MywyODY1Yy01LjUsMC0xMCw0LjUtMTAsMTBoNGMwLTMuMywyLjctNiw2LTZjMy4zLDAsNiwyLjcsNiw2djJoLTIwdjE0YzAsMy4zLDIuNyw2LDYsNmgxMg0KCWMzLjMsMCw2LTIuNyw2LTZ2LTE2Qy0xMDQzLDI4NjkuNS0xMDQ3LjUsMjg2NS0xMDUzLDI4NjV6IE0tMTA0OSwyODkzaC0xMmMtMS4xLDAtMi0wLjktMi0ydi0xMGgxNnYxMA0KCUMtMTA0NywyODkyLjEtMTA0Ny45LDI4OTMtMTA0OSwyODkzeiBNLTEwNTMsMjg4N2MwLDEuMS0wLjksMi0yLDJzLTItMC45LTItMmMwLTEuMSwwLjktMiwyLTJTLTEwNTMsMjg4NS45LTEwNTMsMjg4N3oiLz4NCjwvc3ZnPg0K);
    background-size: 1em auto;
    background-repeat: no-repeat;
    background-position: left center;
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning div.gfield_creditcard_warning_message {
    padding: 16px 16px 14px 16px;
}

li.gfield + li.gfield.gfield_creditcard_warning {
    margin-top: 16px !important;
}

.gform_wrapper .top_label .gfield_error {
    width: calc(100% - 2px);
}

.gform_wrapper .top_label .gfield_error input.large,
.gform_wrapper .top_label .gfield_error select.large,
.gform_wrapper .top_label .gfield_error textarea.textarea {
    width: 100%;
}

.gform_wrapper .right_label .gfield_error input.large,
.gform_wrapper .right_label .gfield_error select.large,
.gform_wrapper .right_label .gfield_error textarea.textarea,
.gform_wrapper .left_label .gfield_error input.large,
.gform_wrapper .left_label .gfield_error select.large,
.gform_wrapper .left_label .gfield_error textarea.textarea {
    width: 70%;
}

.gform_wrapper .gfield_error .gfield_label {
    color: #790000;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
    padding-right: 0;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_minute.ginput_container,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_ampm.ginput_container {
    margin-left: 0;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
    max-width: 100%;
    padding-right: 16px;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container {
    margin-top: 12px
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required fieldset legend.gfield_label {
    position: relative;
    top: 12px;
}

.gform_wrapper div.validation_error {
    color: #790000;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 25px;
    border-top: 2px solid #790000;
    border-bottom: 2px solid #790000;
    padding: 16px 0 16px 0;
    clear: both;
    width: 100%;
    text-align: center;
}

.gform_wrapper ol.validation_list {
    clear: both;
    width: 100%;
    margin-bottom: 25px;
    color: #790000;
}

.gform_wrapper ol.validation_list li {
    list-style-type: decimal !important;
}

.gform_wrapper ol.validation_list li a {
    color: #790000;
}

.gform_wrapper ol.validation_list:after {
    content: "";
}

.gform_wrapper div#error {
    margin-bottom: 25px;
}

.gform_wrapper table.gfield_list tbody tr td.gfield_list_icons {
    vertical-align: middle !important;
}

.gform_wrapper li.gfield fieldset {
    margin: 0px;
    padding: 0px;
    display: block;
    position: relative;
    width: 100%;
}

.gform_wrapper li.gfield fieldset > * { width: auto }
.gform_wrapper li.gfield fieldset legend.gfield_label {
    width: 100%;
    line-height: 32px;
}

div.gf_page_steps+div.validation_error {
    margin-top: 16px;
}

.gform_wrapper div.gfield_description.validation_error {
    color: #790000;
    font-weight: 700;
    font-size: .875em;
    line-height: 1.20;
    margin-bottom: 16px;
}

.gform_wrapper .validation_message {
    color: #790000;
    font-weight: 700;
    letter-spacing: normal;
}

.gform_wrapper li.gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
.gform_wrapper li.gfield_error textarea {
    border: 1px solid #790000;
}

.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
.gform_wrapper li.gfield_error ul.gfield_checkbox,
.gform_wrapper li.gfield_error ul.gfield_radio {
    color: #790000;
}

.gform_wrapper .gform_footer {
    padding: 16px 0 10px 0;
    margin: 16px 0 0 0;
    clear: both;
    width: 100%;
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_footer input[type=submit],
.gform_wrapper .gform_page_footer input[type=submit] {
    font-size: 1em;
    width: 100%;
    margin: 0 0 16px 0;
}

.gform_wrapper .gform_footer a.gform_save_link,
.gform_wrapper .gform_page_footer a.gform_save_link {
    font-size: 1em;
    display: block;
    text-align: center;
}

.gform_wrapper .gform_footer input[type=image] {
    padding: 0;
    width: auto !important;
    background: none !important;
    border: none !important;
    margin: 0 auto 16px auto !important;
    display: block;
    max-width: 100%;
}

.gform_wrapper .ginput_complex .ginput_left,
.gform_wrapper .ginput_complex .ginput_right,
.gform_wrapper .ginput_complex .ginput_full {
    min-height: 45px;
}

.gform_wrapper .ginput_complex .ginput_full input[type=text],
.gform_wrapper .ginput_complex .ginput_full input[type=url],
.gform_wrapper .ginput_complex .ginput_full input[type=email],
.gform_wrapper .ginput_complex .ginput_full input[type=tel],
.gform_wrapper .ginput_complex .ginput_full input[type=number],
.gform_wrapper .ginput_complex .ginput_full input[type=password] {
    width: 100%;
}

.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=text],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=url],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=email],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=tel],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=number],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=password],
.gform_wrapper .gfield_error .ginput_complex .ginput_full select {
    width: 100%;
}

.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
    margin: 0 0 16px 0;
    line-height: 1.2;
}

.gform_wrapper ul.gfield_radio li input[type="radio"]:checked+label,
.gform_wrapper ul.gfield_checkbox li input[type="checkbox"]:checked+label {
    font-weight: 700;
}

.gform_wrapper input.datepicker.datepicker_with_icon {
    margin-right: 4px !important;
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper input[type=hidden],
.gform_wrapper input.gform_hidden,
.gform_wrapper .gform_hidden,
.gform_wrapper .gf_hidden {
    display: none !important;
    max-height: 1px !important;
    overflow: hidden;
}

.gform_wrapper .gfield_visibility_hidden,
.gform_wrapper .gf_invisible {
    visibility: hidden;
    position: absolute;
    left: -9999px;
}

.gform_wrapper .ginput_full br,
.gform_wrapper .ginput_left br,
.gform_wrapper .ginput_right br {
    display: none !important;
}

.gform_wrapper ul.gfield_checkbox li,
.gform_wrapper ul.gfield_radio li {
    padding: 0 !important;
}

.gform_wrapper ul.gfield_radio li input+input {
    margin-left: 4px;
}

.gform_wrapper ul.gfield_radio li input[value=gf_other_choice] {
    margin-right: 6px;
    margin-top: 4px;
}

.gform_wrapper .gfield_description + .gform_wrapper .gfield_description.validation_message {
    margin-top: 6px;
}

.gform_wrapper .ginput_container.ginput_list + .gfield_description.validation_message {
    margin-top: 0;
}

.gform_wrapper li.gfield_html {
    max-width: 100%;
}

.gform_wrapper .gfield_html.gfield_no_follows_desc {
    margin-top: 10px;
}

body .gform_wrapper ul li.gfield.gfield_html img {
    max-width: 100%  !important;
}

.gform_wrapper .gform_ajax_spinner {
    padding-left: 10px;
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
    display: none !important;
    position: absolute !important;
    left: -9000px;
}

.gform_wrapper .gfield_captcha_input_container {
    padding-top: 3px;
}

.gform_wrapper .simple_captcha_small input {
    width: 64px;
}

.gform_wrapper .simple_captcha_medium input {
    width: 96px;
}

.gform_wrapper .simple_captcha_large input {
    width: 128px;
}

.gform_wrapper .gform_wrapper .left_label .simple_captcha_small,
.gform_wrapper .right_label .simple_captcha_small,
.gform_wrapper .left_label .simple_captcha_medium,
.gform_wrapper .right_label .simple_captcha_medium,
.gform_wrapper .left_label .simple_captcha_large,
.gform_wrapper .right_label .simple_captcha_large {
    margin-left: 32%;
}

.gform_wrapper .gfield_captcha_container img.gfield_captcha {
    border: none !important;
    background: none !important;
    float: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.gform_wrapper .math_small input {
    width: 70px;
}

.gform_wrapper .math_medium input {
    width: 510px;
}

.gform_wrapper .math_large input {
    width: 612px;
}

.gform_wrapper .left_label .math_small,
.gform_wrapper .right_label .math_small,
.gform_wrapper .left_label .math_medium,
.gform_wrapper .right_label .math_medium,
.gform_wrapper .left_label .math_large,
.gform_wrapper .right_label .math_large {
    margin-left: 32%;
}


.gform_wrapper div.charleft {
    font-size: .688em;
    margin-top: 4px;
    color: #B7B7B7;
    width: 92% !important;
    white-space: nowrap !important;
}

.gform_wrapper div.charleft[style] {
    width: 92% !important;
}

.gform_wrapper .left_label div.charleft,
.gform_wrapper .right_label div.charleft {
    margin-left: 32%;
}

.gform_wrapper div.charleft.ginput_counter_tinymce{
    border:  1px solid #eee;
    border-width: 1px 0 0;
    padding: 4px 9px;
    width: 100% !important;
}

.gform_wrapper div.charleft.warningTextareaInfo {
    color: #A1A1A1;
}

.gform_wrapper div.charleft.ginput_counter_error {
    color: red;
}

.gform_wrapper li.gf_hide_charleft div.charleft {
    display: none !important;
}

.gf_submission_limit_message {
    color: #790000;
    font-size: 1.375em;
}

.gform_wrapper .ginput_price {
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}

.gform_wrapper span.ginput_total {
    color: #060;
    font-size: inherit;
}

.gform_wrapper .top_label span.ginput_total {
    margin: 8px 0;
}

.gform_wrapper span.ginput_product_price_label {
    margin-right: 2px;
}

.gform_wrapper span.ginput_product_price {
    color: #900;
}

.gform_wrapper span.ginput_quantity_label {
    margin-left: 10px;
    margin-right: 2px;
}

.gform_wrapper input.ginput_quantity[type=text] {
    width: 3rem;
}

.gform_wrapper input.ginput_quantity[type=number] {
    width: 4rem;
}

.gform_wrapper .gform_page_footer {
    margin: 14px 0;
    width: 100%;
    border-top: 1px dotted #CCC;
    padding: 16px 0 0 0;
    clear: both;
}

.gform_wrapper .gform_page_footer.left_label,
.gform_wrapper .gform_page_footer.right_label {
    padding: 16px 0 0 29%;
}

.gform_wrapper .gform_page_footer .button.gform_previous_button, .gform_wrapper .gform_page_footer .button.gform_next_button {
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gform_page_footer .button.gform_previous_button {
    margin-bottom: 8px;
}

.gform_wrapper .gf_progressbar_wrapper {
    clear: both;
    width: 100%;
    margin: 0 0 16px 0;
    padding: 0 0 16px 0;
}

.gform_wrapper .gf_progressbar_wrapper h3.gf_progressbar_title {
    font-size: .813em;
    line-height: 1 !important;
    margin: 0 0 8px 12px !important;
    padding: 0 !important;
    clear: both;
    filter: alpha(opacity=60);
    -moz-opacity: .6;
    -khtml-opacity: .6;
    opacity: .6;
}

.gform_wrapper .gf_progressbar {
    padding: 10px;
    position: relative;
    background-color:rgba(0, 0, 0, 0.1);
    -moz-border-radius: 25px;
    -webkit-border-radius: 25px;
    border-radius: 25px;
    -webkit-box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,0.05);
    -moz-box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,0.05);
    box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,0.05);
    width: 100%;
}

.gform_wrapper .gf_progressbar_percentage {
    height: 24px;
    text-align: right;
    font-family: helvetica,arial,sans-serif;
    font-size: .813em !important;
    text-shadow: 0 1px 1px rgba(0,0,0,.50);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index:999;
    vertical-align: middle;
    background: repeating-linear-gradient( -45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px),linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}

.gform_wrapper .gf_progressbar:after {
    content:"";
    display: block;
    width: 100%;
    z-index:990;
    height: 24px;
    margin-top:-24px;
    background-color:rgba(0, 0, 0, 0.1);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    -webkit-box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);
    -moz-box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);
    box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);
}

.gform_wrapper .gf_progressbar_percentage.percentbar_100 {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.gform_wrapper .gf_progressbar_percentage span {
    display: block;
    width: auto;
    float: right;
    margin-right: 5px;
    margin-left: 5px;
    line-height: 1.8;
}

.gform_wrapper .gf_progressbar_percentage.percentbar_0 span {
    color: #959595;
    text-shadow: none;
    float: none !important;
    margin-left: 12px;
    word-wrap: normal;
}

.gform_wrapper .percentbar_blue {
    background-color: #036493;
    color: #FFF;
}

.gform_wrapper .percentbar_gray {
    background-color: #7C7C7C ;
    color: #FFF;
}

.gform_wrapper .percentbar_green {
    background-color: #88B831;
    color: #FFF;
}

.gform_wrapper .percentbar_orange {
    background-color: #FF7120;
    color: #FFF;
}

.gform_wrapper .percentbar_red {
    background-color: #FF2A1A;
    color: #FFF;
}

.gform_wrapper .gf_page_steps {
    width: 100%;
    margin: 0 0 8px 0;
    padding: 0 0 4px 0;
    border-bottom: 1px dotted #CCC;
}

.gform_wrapper .gf_step {
    width: 100%;
    margin: 24px 24px 24px 0;
    font-size: .875em;
    height: 14px;
    line-height: 1.25 !important;
    filter: alpha(opacity=20);
    -moz-opacity: .2;
    -khtml-opacity: .2;
    opacity: .2;
    font-family: inherit;
}

.gform_wrapper .gf_step span.gf_step_number {
    font-size: 1.25em;
    font-family: arial,sans-serif;
    margin-right: 4px;
}

.gform_wrapper .gf_step span.gf_step_number,
.gform_wrapper .gf_step span.gf_step_label {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
}

.gform_wrapper .gf_step.gf_step_active {
    filter: alpha(opacity=100);
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    opacity: 1.0;
}

.gform_wrapper .gf_step_clear {
    display: block;
    clear: both;
    height: 1px;
    overflow: hidden;
}

.gform_wrapper .gfield_password_strength {
    border: 1px solid #DDD;
    margin-top: 18px;
    margin-bottom: 18px;
    line-height: 1.8;
    padding: 10px 5px;
    text-align: center;
    background-color: #EEE;
    border-bottom: 3px solid #DDD;
}

.gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
    margin-right: 16px;
}

.gform_wrapper ul.left_label li.gfield .gfield_password_strength,
.gform_wrapper ul.right_label li.gfield .gfield_password_strength {
    margin-top: 18px;
    margin-bottom: 18px;
    width: 100%;
}

.gform_wrapper .gfield_password_strength.bad {
    background-color: #FFB78C;
    border-color: #FF853C;
    border-bottom: 3px solid #FF853C;
}

.gform_wrapper .gfield_password_strength.good {
    background-color: #FFEC8b;
    border-color: #FC0;
    border-bottom: 3px solid #FC0;
}

.gform_wrapper .gfield_password_strength.short,
.gform_wrapper .gfield_password_strength.mismatch {
    background-color: #FFA0A0;
    border-color: #f04040;
    border-bottom: 3px solid #f04040;
}

.gform_wrapper .gfield_password_strength.strong {
    background-color: #C3FF88;
    border-color: #8DFF1C;
    border-bottom: 3px solid #8DFF1C;
}

.gform_wrapper table.gfield_list,
.gform_wrapper table.gfield_list caption,
.gform_wrapper table.gfield_list tbody,
.gform_wrapper table.gfield_list tfoot,
.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr,
.gform_wrapper table.gfield_list th,
.gform_wrapper table.gfield_list td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.gform_wrapper table.gfield_list {
    border-spacing: 0;
    border-collapse: collapse;
}

/* fix for twentyfifteen theme table layout issue */

.gform_wrapper table.gfield_list {
    table-layout: auto !important ;
}

.gform_wrapper table.gfield_list thead th {
    font-weight: 700;
    text-align: left;
}

.gform_wrapper table.gfield_list colgroup {
    width: 100%;
    display: block;
}

.gform_wrapper li.gfield.gfield_error table.gfield_list thead th {
    color: #790000;
}

.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr {
    padding: 0;
    margin: 0;
}

.gform_wrapper table.gfield_list th,
.gform_wrapper table.gfield_list td {
    padding: 4px 0 4px 0;
}

.gform_wrapper ul.gform_fields:not(.top_label) table.gfield_list th {
    padding: 0 0 4px 0;
}

.gform_wrapper table.gfield_list th + th,
.gform_wrapper table.gfield_list td + td {
    padding: 0 0 0 16px;
}

.gform_wrapper .gfield_list {
    width: 100%;
}

.gform_wrapper .gfield_list td.gfield_list_cell input {
    width: 100%;
}

.gfield_icon_disabled {
    cursor: default !important;
    filter: alpha(opacity=60);
    -moz-opacity: .6;
    -khtml-opacity: .6;
    opacity: .6;
}

.gform_wrapper table.gfield_list tr td.gfield_list_icons {
    width: 48px !important;
    padding: 0 0 0 4px  !important;
    box-sizing: border-box;
}

.gform_wrapper table.gfield_list td.gfield_list_icons img {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
}

.gform_wrapper select.chosen-select {
    visibility: hidden;
    height: 28px !important;
    min-height: 28px !important;
}

.gform_wrapper .chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.gform_wrapper .chosen-container,
.gform_wrapper .chosen-container * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.gform_wrapper .chosen-container.chosen-container-multi .chosen-choices {
    min-height: 28px !important;
}

.gform_wrapper .ginput_container.ginput_container_multiselect .chosen-container.chosen-container-multi ul.chosen-choices li.search-field input.default {
    height: 24px;
}

.gform_wrapper .chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 1010;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #AAA;
    border-top: 0;
    background: #FFF;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
}

.gform_wrapper .chosen-container.chosen-with-drop .chosen-drop {
    left: 0;
}
.gform_wrapper .chosen-container a {
    cursor: pointer;
}
.gform_wrapper .chosen-container-single .chosen-single {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 8px;
    height: 23px;
    border: 1px solid #AAA;
    border-radius: 5px;
    background-color: #FFF;
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #FFF), color-stop(50%, #F6F6F6), color-stop(52%, #EEE), color-stop(100%, #F4F4F4));
    background: -webkit-linear-gradient(top, #FFF 20%, #F6F6F6 50%, #EEE 52%, #F4F4F4 100%);
    background: -moz-linear-gradient(top, #FFF 20%, #F6F6F6 50%, #EEE 52%, #F4F4F4 100%);
    background: -o-linear-gradient(top, #FFF 20%, #F6F6F6 50%, #EEE 52%, #F4F4F4 100%);
    background: linear-gradient(to bottom, #FFF 20%, #F6F6F6 50%, #EEE 52%, #F4F4F4 100%);
    background-clip: padding-box;
    box-shadow: 0 0 3px white inset, 0 1px 1px rgba(0, 0, 0, .1);
    color: #444;
    text-decoration: none;
    white-space: nowrap;
    line-height: 24px;
}

.gform_wrapper .chosen-container-single .chosen-default {
    color: #999;
}

.gform_wrapper .chosen-container-single .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gform_wrapper .chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px;
}

.gform_wrapper .chosen-container-single .chosen-single abbr {
    position: absolute;
    top: 6px;
    right: 26px;
    display: block;
    width: 12px;
    height: 12px;
    background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
    font-size: 1px;
}

.gform_wrapper .chosen-container-single .chosen-single abbr:hover {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-single.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-single .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%;
}

.gform_wrapper .chosen-container-single .chosen-single div b {
    display: block;
    width: 100%;
    height: 100%;
    background: url('../images/chosen-sprite.png') no-repeat 0px 2px;
}

.gform_wrapper .chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap;
}

.gform_wrapper .chosen-container-single .chosen-search input[type="text"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 1px 0;
    padding: 4px 20px 4px 5px;
    width: 100%;
    height: auto;
    border: 1px solid #AAA;
    background: white url('../images/chosen-sprite.png') no-repeat 100% -20px;
    background: url('../images/chosen-sprite.png') no-repeat 100% -20px;
    font-size: 1em;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
}

.gform_wrapper .chosen-container-single .chosen-drop {
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
}

.gform_wrapper .chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px;
}

.gform_wrapper .chosen-container .chosen-results {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 4px 4px 0;
    padding: 0 0 0 4px;
    max-height: 240px;
    -webkit-overflow-scrolling: touch;
}

.gform_wrapper .chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    -webkit-touch-callout: none;
}

.gform_wrapper .chosen-container .chosen-results li.active-result {
    display: list-item;
    cursor: pointer;
}

.gform_wrapper .chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: #CCC;
    cursor: default;
}

.gform_wrapper .chosen-container .chosen-results li.highlighted {
    background-color: #3875d7;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #3875d7), color-stop(90%, #2a62bc));
    background-image: -webkit-linear-gradient(#3875d7 20%, #2a62bc 90%);
    background-image: -moz-linear-gradient(#3875d7 20%, #2a62bc 90%);
    background-image: -o-linear-gradient(#3875d7 20%, #2a62bc 90%);
    background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
    color: #FFF;
}

.gform_wrapper .chosen-container .chosen-results li.no-results {
    display: list-item;
    background: #F4F4F4;
}

.gform_wrapper .chosen-container .chosen-results li.group-result {
    display: list-item;
    font-weight: 700;
    cursor: default;
}

.gform_wrapper .chosen-container .chosen-results li.group-option {
    padding-left: 15px;
}

.gform_wrapper .chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: underline;
}

.gform_wrapper .chosen-container-multi .chosen-choices {
    position: relative;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto !important;
    height: 1%;
    border: 1px solid #AAA;
    background-color: #FFF;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(1%, #EEE), color-stop(15%, #FFF));
    background-image: -webkit-linear-gradient(#EEE 1%, #FFF 15%);
    background-image: -moz-linear-gradient(#EEE 1%, #FFF 15%);
    background-image: -o-linear-gradient(#EEE 1%, #FFF 15%);
    background-image: linear-gradient(#EEE 1%, #FFF 15%);
    cursor: text;
}

.gform_wrapper .chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    margin: 1px 0;
    padding: 5px;
    height: 15px;
    border: 0 !important;
    background: transparent !important;
    box-shadow: none;
    color: #666;
    font-size: 100%;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field .default {
    color: #999;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 5px 0 5px 5px !important;
    padding: 3px 20px 3px 5px;
    border: 1px solid #AAA;
    border-radius: 3px;
    background-color: #E4E4E4;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #F4F4F4), color-stop(50%, #F0F0F0), color-stop(52%, #E8E8E8), color-stop(100%, #EEE));
    background-image: -webkit-linear-gradient(#F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-image: -moz-linear-gradient(#F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-image: -o-linear-gradient(#F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-image: linear-gradient(#F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-clip: padding-box;
    box-shadow: 0 0 2px white inset, 0 1px 0 rgba(0, 0, 0, .05);
    color: #333;
    line-height: 13px;
    cursor: default;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: 4px;
    right: 3px;
    display: block;
    width: 12px;
    height: 12px;
    background: url('../images/chosen-sprite.png') -42px 1px no-repeat;
    font-size: 1px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-disabled {
    padding-right: 5px;
    border: 1px solid #CCC;
    background-color: #E4E4E4;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #F4F4F4), color-stop(50%, #F0F0F0), color-stop(52%, #E8E8E8), color-stop(100%, #EEE));
    background-image: -webkit-linear-gradient(top, #F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-image: -moz-linear-gradient(top, #F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-image: -o-linear-gradient(top, #F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-image: linear-gradient(top, #F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    color: #666;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus {
    background: #D4D4D4;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-multi .chosen-results {
    margin: 0;
    padding: 0;
}

.gform_wrapper .chosen-container-multi .chosen-drop .result-selected {
    display: list-item;
    color: #CCC;
    cursor: default;
}

.gform_wrapper .chosen-container-active .chosen-single {
    border: 1px solid #5897FB;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #AAA;
    -moz-border-radius-bottomright: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-left-radius: 0;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(20%, #EEE), color-stop(80%, #FFF));
    background-image: -webkit-linear-gradient(#EEE 20%, #FFF 80%);
    background-image: -moz-linear-gradient(#EEE 20%, #FFF 80%);
    background-image: -o-linear-gradient(#EEE 20%, #FFF 80%);
    background-image: linear-gradient(#EEE 20%, #FFF 80%);
    box-shadow: 0 1px 0 #fff inset;
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div {
    border-left: none;
    background: transparent;
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 2px;
}

.gform_wrapper .chosen-container-active .chosen-choices {
    border: 1px solid #5897FB;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.gform_wrapper .chosen-container-active .chosen-choices li.search-field input[type="text"] {
    color: #111 !important;
}

.gform_wrapper .chosen-disabled {
    opacity: .5 !important;
    cursor: default;
}

.gform_wrapper .chosen-disabled .chosen-single {
    cursor: default;
}

.gform_wrapper .chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default;
}

.gform_wrapper .chosen-rtl {
    text-align: right;
}

.gform_wrapper .chosen-rtl .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0;
}

.gform_wrapper .chosen-rtl .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl;
}

.gform_wrapper .chosen-rtl .chosen-single-with-deselect span {
    margin-left: 38px;
}

.gform_wrapper .chosen-rtl .chosen-single div {
    right: auto;
    left: 3px;
}

.gform_wrapper .chosen-rtl .chosen-single abbr {
    right: auto;
    left: 26px;
}

.gform_wrapper .chosen-rtl .chosen-choices li {
    float: right;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-field input[type="text"] {
    direction: rtl;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice {
    margin: 3px 5px 3px 0;
    padding: 3px 5px 3px 19px;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice .search-choice-close {
    right: auto;
    left: 4px;
}

.gform_wrapper .chosen-rtl.chosen-container-single-nosearch .chosen-search, .gform_wrapper .chosen-rtl .chosen-drop {
    left: 9999px;
}

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0;
}

.gform_wrapper .chosen-rtl .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0;
}

.gform_wrapper .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none;
}

.gform_wrapper .chosen-rtl .chosen-search input[type="text"] {
    padding: 4px 5px 4px 20px;
    background: white url('../images/chosen-sprite.png') no-repeat -30px -20px;
    background: url('../images/chosen-sprite.png') no-repeat -30px -20px;
    direction: rtl;
}

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-single div b {
    background-position: 6px 2px;
}

.gform_wrapper .chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
    background-position: -12px 2px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 144dpi) {
    .gform_wrapper .chosen-rtl .chosen-search input[type="text"],
    .gform_wrapper .chosen-container-single .chosen-single abbr,
    .gform_wrapper .chosen-container-single .chosen-single div b,
    .gform_wrapper .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
    .gform_wrapper .chosen-container .chosen-results-scroll-down span,
    .gform_wrapper .chosen-container .chosen-results-scroll-up span {
        background-image: url('../images/chosen-sprite-2x.png') !important;
        background-position: 0 0;
        background-size: 52px 37px !important;
        background-repeat: no-repeat !important;
    }
}

.gform_wrapper .gform_card_icon_container {
    margin: 8px 0 6px 0;
    height: 32px;
}

.gform_wrapper div.gform_card_icon {
    margin-right: 4px;
    text-indent: -9000px;
    background-image: url(../images/gf-creditcard-icons.png);
    background-repeat: no-repeat;
    width: 36px;
    height: 32px;
    float: left;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_visa {
    background-position: 0 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa { background-position: 0 -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa { background-position: 0 -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_mastercard {
    background-position: -36px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
    background-position: -36px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
    background-position: -36px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_amex {
    background-position: -72px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
    background-position: -72px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
    background-position: -72px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_discover {
    background-position: -108px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
    background-position: -108px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
    background-position: -108px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_maestro {
    background-position: -144px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
    background-position: -144px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
    background-position: -144px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_jcb {
    background-position: -180px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
    background-position: -180px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
    background-position: -180px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_visa {
    background-position: 0 -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa {
    background-position: 0 -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa {
    background-position: 0 -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_mastercard {
    background-position: -36px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
    background-position: -36px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
    background-position: -36px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_amex {
    background-position: -72px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
    background-position: -72px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
    background-position: -72px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_discover {
    background-position: -108px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
    background-position: -108px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
    background-position: -108px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_maestro {
    background-position: -144px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
    background-position: -144px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
    background-position: -144px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_jcb {
    background-position: -180px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
    background-position: -180px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
    background-position: -180px -256px;
}

.gform_card_icon_container input[type=radio]#gform_payment_method_creditcard {
    float: left;
    position: relative;
    top: 4px!important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right {
    min-width: 85px !important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left label, .gform_wrapper .ginput_complex .ginput_cardinfo_right label {
    white-space: nowrap !important;
}

.gform_wrapper .ginput_complex span.ginput_cardextras {
    display: block;
    overflow: hidden;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container {
    position: relative;
    display: block;
    min-width: 160px !important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container select {
    margin-bottom: 8px;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right input.ginput_card_security_code {
    max-width: 50% !important
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
    width: 32px;
    height: 23px;
    background-image: url(../images/gf-creditcard-icons.png);
    background-repeat: no-repeat;
    background-position: 0 -128px;
    position: relative;
    top: -1px;
    left: 6px;
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gform_fileupload_multifile .gform_drop_area {
    padding: 24px;
    border: 1px dashed #CCC;
    text-align: center;
    color: #AAA;
    margin-bottom: 16px;
    background: rgba(0, 0, 0, 0.02);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.gform_wrapper span.gform_drop_instructions {
    display: block;
    margin-bottom: 8px;
}

.gform_delete {
    vertical-align: middle;
    cursor: pointer;
}

.gform_wrapper .copy_values_option_container .copy_values_enabled {
    width: auto;
}

.gform_wrapper li.field_sublabel_above .ginput_complex input[type=text] {
    margin-bottom: 1px;
}

.gform_wrapper .form_saved_message{
    margin: 16px 0 16px 0;
    width: 100%;
    font-size: 1em;
    line-height: 1.8;
    border: 2px solid #91AFC7;
    background-color: #E3F3F3;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.gform_wrapper .form_saved_message form{
    text-align: center;
}

.gform_wrapper .form_saved_message span:first-child {
    display: block;
    padding: 28px 16px 28px 16px;
}

.form_saved_message a.resume_form_link {
    word-wrap: break-word;
}

.form_saved_message a.resume_form_link:before,
.form_saved_message a.resume_form_link:after {
    content: "";
    display: block;
}

.form_saved_message a.resume_form_link:before {
    margin-top: 12px;
}

.form_saved_message a.resume_form_link:after {
    margin-bottom: 12px;
}

.form_saved_message br + br,
.form_saved_message a + br {
    display: none;
}

.form_saved_message .form_saved_message_emailform {
    margin: 16px 0 0 0;
}

div.form_saved_message div.form_saved_message_emailform form input[type=text] {
    line-height: 2.25;
    height: 24px;
    margin-bottom: 4px;
}

div.form_saved_message div.form_saved_message_emailform form input[type=submit] {
    margin: 12px auto 0 auto;
    display: block;
}

.form_saved_message .form_saved_message_emailform .validation_message {
    color: #790000;
}

.form_saved_message_sent {
    margin: 16px 0 16px 0;
    width: 100%;
    font-size: 1em;
    border: 2px solid #CFE2C0;
    background-color: #E9FFD9;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
}

.form_saved_message_sent span:first-child {
    display: block;
    padding: 16px;
}

body #content .entry-content .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area,
body .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area {
    line-height: 1 !important;
}

body .gform_wrapper img.ui-datepicker-trigger {
    width: 17px !important;
    height: 16px !important;
    display: -moz-inline-stack;
    display: inline-block;
}

body .gform_wrapper img.add_list_item,
body .gform_wrapper img.delete_list_item {
    display: -moz-inline-stack;
    display: inline-block;
    margin-top: -2px;
}

.gform_wrapper ul li.gfield.field_sublabel_above.field_description_below + li.gfield {
    margin-top: 16px;
}

.gform_wrapper li.gfield ul.gfield_radio li input[type="radio"] + input[type="text"] {
    width: 45.25%;
}

.gform_wrapper table.recaptchatable {
    table-layout: auto;
}

/* adjusting the captcha margin for left or right label settings */

.gform_wrapper ul.gform_fields:not(.top_label) div.ginput_container_captcha {
    margin-left: 29%;
}

/* rich text editor */

.gfield .wp-editor-container {
    border: 1px solid #ddd;
}

.gfield .wp-switch-editor {
    border-color: #ddd;
}

.gfield .tmce-active .switch-tmce,
.gfield .html-active .switch-html {
    border-bottom-color: #f5f5f5;
}

.gfield .mce-edit-area iframe {
    width: 99.9% !important;
    margin: 0 !important;
}

body .gform_wrapper ul li.gfield {
    margin-top: 16px;
    padding-top: 0;
}

body .gform_wrapper ul.gfields li.gfield label.gfield_label {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

/* do our best to restore styles for lists inside the HTML field */

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html {
    line-height: inherit;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul li {
    list-style-type: disc !important;
    margin: 0 0 8px 0;
    overflow: visible;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul {
    list-style-type: disc !important;
    margin: 16px 0 16px 28px !important;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li {
    list-style-type: decimal!important;
    overflow: visible;
    margin: 0 0 8px 0;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol {
    list-style-type: decimal !important;
    margin: 16px 0 18px 32px;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
    list-style-type: disc !important;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul {
    margin: 16px 0 16px 16px;
}

body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
    margin: 0 0 8px;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl {
    margin: 0 0 18px 0;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dt {
    font-weight: 700;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dd {
    margin: 0 0 16px 18px;
}

.gform_wrapper span.gf_clear_span {
    display: block;
    height: 1px;
    overflow: hidden;
    width: 100%;
    float: none;
    clear: both;
    margin-top: -1px;
}

@media only screen and (max-width: 641px)  {

    /* make the inputs a little larger for mobile devices */

    .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='image']):not([type='file']) {
        line-height: 2;
        min-height: 2rem;
    }

    .gform_wrapper textarea {
        line-height: 1.5;
    }

    .gform_wrapper .chosen-container.chosen-container-single[style] {
        width: 100% !important;
    }

    .gform_wrapper .chosen-container-single .chosen-single {
        height: 40px;
        line-height: 2.8;
    }

    .gform_wrapper .chosen-container-single .chosen-single div b {
        position: relative;
        top: 10px;
    }

    .gform_wrapper div.chosen-container.chosen-container-multi[style] {
        width: 100% !important;
    }

    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice,
    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-field {
        width: calc(100% - 10px);
        line-height: 2;
        float: none;
    }

    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice span {
        margin-bottom: 0;
    }

    .gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
        top: calc(50% - 6px);
    }

    .gform_wrapper .ginput_container span:not(.ginput_price) {
        margin-bottom: 8px;
        display: block;
    }

    .gform_wrapper li.field_sublabel_below .ginput_complex {
        margin-top: 12px !important;
    }

    .gform_wrapper ul.gfield_radio li label,
    .gform_wrapper ul.gfield_checkbox li label {
        width: 85%;
        cursor: pointer;
    }

 }

@media only screen and (max-width: 761px), (min-device-width: 768px) and (max-device-width: 1023px)  {

    .gform_wrapper ul.gform_fields li.gfield.gfield_error + li.gfield.gfield_error {
        margin-top: 32px;
    }

    /* force the list table to not behave like a table anymore */

    .gform_wrapper table.gfield_list {
      border: 0;
    }

    .gform_wrapper table.gfield_list thead  {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .gform_wrapper table.gfield_list tr {
        margin-bottom: 10px;
        display: block;
        background: #fff;
        position:relative;
        border: 1px solid #CCC;
        border-top: 2px solid #CCC;
        border-bottom: 4px solid #CCC;
    }

    .gform_wrapper table.gfield_list td {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
    }

    .gform_wrapper table.gfield_list td.gfield_list_icons {
        border-bottom: 0;
        margin: 16px 0 0 0 !important;
    }

    .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before,
    .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
        content: attr(data-label);
        font-size: .875em;
        letter-spacing: 0.5pt;
        white-space: nowrap;
        display: block;
        clear: both;
    }

    .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before {
        margin: 8px 0 3px 8px;
    }

    .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
      margin: 3px 0 8px 8px;
    }

    .gform_wrapper table.gfield_list th + th,
    .gform_wrapper table.gfield_list td + td {
        padding: 4px 0 0 0;
    }

    .gform_wrapper table.gfield_list tr.gfield_list_row_odd,
    .gform_wrapper table.gfield_list tr.gfield_list_row_even {
        margin: 16px 0;
    }

    .gform_wrapper table.gfield_list tr.gfield_list_row_odd:first-child {
         padding-top: 12px;
    }

    .gform_wrapper table.gfield_list tr {
         padding-top: 12px;
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons {
        width: 100% !important;
        padding: 0 4px 4px 4px !important;
        background-color: #EEE;
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons img.add_list_item  {
        margin: 12px 0 0 16px !important;
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons img.delete_list_item  {
        margin: 12px 0 0 8px !important;
    }

    .gform_wrapper .gform_footer a.gform_save_link,
    .gform_wrapper .gform_page_footer a.gform_save_link {
        margin-left: 0;
    }

    .gform_wrapper .gfield_list td.gfield_list_cell input {
        width: calc(100% - 16px);
        margin-left: 8px;
        margin-right: 8px;
    }

}

/* media queries - portrait mode tablet or desktop styles */

@media only screen and (min-width: 641px) {

    .gform_wrapper .gform_body {
        width: 100%;
    }

    .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
        max-width: calc(100% - 16px) !important;
    }

    .gform_wrapper .gfield_checkbox li,
    .gform_wrapper .gfield_radio li {
        margin: 0 0 8px 0;
        line-height: 1.3;
    }

    .gform_wrapper .gsection {
        margin: 28px 16px 28px 0;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gfield_label {
        float: left;
        width: 29%;
        padding-right: 16px;
        margin-bottom: 16px;
    }

    .gform_wrapper .right_label .gfield_label {
        text-align: right;
    }

    .gform_wrapper .left_label .gfield_description,
    .gform_wrapper .right_label .gfield_description {
        width: 70%;
    }

    .gform_wrapper ul.right_label li ul.gfield_radio li,
    .gform_wrapper form ul.right_label li ul.gfield_radio li,
    .gform_wrapper ul.left_label li ul.gfield_radio li,
    .gform_wrapper form ul.left_label li ul.gfield_radio li,
    .gform_wrapper ul.right_label li ul.gfield_checkbox li,
    .gform_wrapper form ul.right_label li ul.gfield_checkbox li,
    .gform_wrapper ul.left_label li ul.gfield_checkbox li,
    .gform_wrapper form ul.left_label li ul.gfield_checkbox li {
        margin-bottom: 10px;
    }

    .gform_wrapper .right_label .gfield_description,
    .gform_wrapper .left_label .gfield_description,
    .gform_wrapper .left_label .instruction,
    .gform_wrapper .right_label .instruction {
        margin-left: 29%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gsection .gsection_description {
        margin-left: 0;
        padding-top: 4px;
        padding-left: 0;
        line-height: 1.5;
    }

    .gform_wrapper form ul.gform_fields:not(.top_label) li.gfield_html_formatted {
        margin-left: 29% !important;
        width: 70%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_checkbox,
    .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_radio {
        overflow: hidden;
    }

    .gform_wrapper .right_label div.ginput_complex,
    .gform_wrapper .left_label div.ginput_complex {
        width: 70%;
        margin-left: 29%;
    }

    .gform_wrapper .right_label input.small,
    .gform_wrapper .right_label select.small,
    .gform_wrapper .left_label input.small,
    .gform_wrapper .left_label select.small {
        width: 15%;
    }

    .gform_wrapper .right_label input.medium,
    .gform_wrapper .right_label select.medium,
    .gform_wrapper .left_label input.medium,
    .gform_wrapper .left_label select.medium {
        width: calc(35% - 8px);
    }

    .gform_wrapper .right_label input.large,
    .gform_wrapper .right_label select.large,
    .gform_wrapper .left_label input.large,
    .gform_wrapper .left_label select.large {
        width: 70%;
    }

    .gform_wrapper .right_label textarea.small,
    .gform_wrapper .left_label textarea.small,
    .gform_wrapper .right_label textarea.medium,
    .gform_wrapper .left_label textarea.medium,
    .gform_wrapper .right_label textarea.large,
    .gform_wrapper .left_label textarea.large {
        width: 70%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .ginput_list {
        width: 70%;
        margin-left: 29% !important;
    }

    .gform_wrapper .top_label .gfield_list {
        width: 100%;
    }

    .gform_wrapper .left_label .gf_list_one_column,
    .gform_wrapper .right_label .gf_list_one_column {
        width: 45%;
    }

    .gform_wrapper .top_label .gf_list_one_column {
        width: 46%;
    }

    .gform_wrapper .left_label li.hidden_label input,
    .gform_wrapper .right_label li.hidden_label input {
        margin-left: 3.7%;
    }

    .gform_wrapper .left_label li.gfield .gfield_password_strength,
    .gform_wrapper .right_label li.gfield .gfield_password_strength {
        margin-left: 29%;
    }

    .gform_wrapper .top_label input.small,
    .gform_wrapper .top_label select.small {
        width: 25%;
    }

    .gform_wrapper .top_label input.medium,
    .gform_wrapper .top_label select.medium {
        width: calc(50% - 8px);
    }

    .gform_wrapper.gform_validation_error .top_label input.medium,
    .gform_wrapper.gform_validation_error .top_label select.medium {
        width: 50%;
    }

    .gform_wrapper .top_label input.large,
    .gform_wrapper .top_label select.large,
    .gform_wrapper .hidden_label input.large,
    .gform_wrapper .hidden_label select.large {
        width: 100%;
    }

    .gform_wrapper .top_label input.small.datepicker,
    .gform_wrapper .top_label input.medium.datepicker,
    .gform_wrapper .top_label input.large.datepicker {
        width: auto;
    }

    .gform_wrapper textarea.small {
        height: 80px;
        width: 100%;
    }

    .gform_wrapper textarea.medium {
        height: 160px;
        width: 100%;
    }

    .gform_wrapper textarea.large {
        height: 320px;
        width: 100%;
    }

    .gform_wrapper .ginput_complex .ginput_left,
    .gform_wrapper .ginput_complex .ginput_right {
        width: 50%;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        margin: 0 -4px 0 0;
    }

    .gform_wrapper .gfield_error .ginput_complex .ginput_left,
    .gform_wrapper .gfield_error .ginput_complex .ginput_right {
        width: 50%;
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left,
    .gform_wrapper .ginput_complex .ginput_cardinfo_right {
        min-height: 43px;
        position: relative;
        float: left;
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left {
        width: 50%;
    }

    .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_month,
    .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_year {
        width: 47% !important;
        display: -moz-inline-stack;
        display: inline-block;
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left select.ginput_card_expiration.ginput_card_expiration_month {
        margin-right: 4px;
    }

    .gform_wrapper .gf_step {
        width:auto;
        display: -moz-inline-stack;
        display: inline-block;
        margin: 16px 32px 16px 0;
    }

    .gform_wrapper .gform_footer input.button,
    .gform_wrapper .gform_footer input[type=submit],
    .gform_wrapper .gform_footer input[type=image] {
        display: -moz-inline-stack;
        display: inline-block;
    }

    .gform_wrapper .gform_footer input[type=image] {
        vertical-align: middle;
    }

    .gform_wrapper .gform_footer a.gform_save_link,
    .gform_wrapper .gform_page_footer a.gform_save_link {
        font-size: 1em;
        margin-left: 16px;
        display: -moz-inline-stack;
        display: inline-block;
    }

    .gform_wrapper .gform_footer input.button,
    .gform_wrapper .gform_page_footer input.button,
    .gform_wrapper .gform_footer input[type=submit],
    .gform_wrapper .gform_page_footer input[type=submit] {
        font-size: 1em;
        width: auto;
        margin: 0 16px 0 0;
    }

    .gform_wrapper ul.gform_fields li.gfield {
        padding-right: 16px;
    }

    .gform_wrapper .ginput_complex .ginput_left {
        padding-right: 8px;
    }

    .gform_wrapper .left_label .ginput_complex.ginput_container_address:after {
        content: "";
        display: block;
    }

    .gform_wrapper .ginput_complex.ginput_container_address .ginput_left {
        margin-right: 8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
        margin-right: -8px;
        padding-right: 8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_full + span.ginput_right {
        padding-right: 8px !important ;
        margin-right: 8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left {
        padding-right: 8px !important ;
        margin-right: -8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left.address_zip {
        margin-right: 8px;
    }

    .gform_wrapper .gfield_error .ginput_container {
        padding-right: 0;
    }

    .gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
        margin: 18px 0 18px 0;
    }

    .gform_wrapper .gform_footer:not(.top_label) {
        padding: 16px 0 10px 0;
        margin-left: 29%;
        width: 70%;
    }

    /* adjusting the name field so it aligns properly */

    .top_label div.ginput_complex.ginput_container.gf_name_has_1,
    .top_label div.ginput_complex.ginput_container.gf_name_has_2,
    .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(100% + 15px);
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_4 {
        width: calc(100% + 13px);
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_5 {
        width: calc(100% + 14px);
    }

    /* a little better specificty to avoid problems with forms in widgets */

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_1,
    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_2,
    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(70% + 15px);
        margin-left: 29%;
    }

    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1,
    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2,
    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(70% + 24px);
    }

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_4 {
        width: calc(70% + 13px);
        margin-left: 29%;
    }

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_5 {
        width: calc(70% + 14px);
        margin-left: 29%;
    }

    div.ginput_container_name span {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        padding-right: 16px;
        margin-right: -4px;
    }

    div.ginput_complex.ginput_container.gf_name_has_1 span {
        width: 100%;
    }

    div.ginput_complex.ginput_container.gf_name_has_2 span {
        width: 50%;    }

    div.ginput_complex.ginput_container.gf_name_has_3 span {
        width: 33.3%;
    }

    div.ginput_complex.ginput_container.gf_name_has_4 span {
        width: 25%;
    }

    div.ginput_complex.ginput_container.gf_name_has_5 span {
        width: 19.95%;
    }

    /* tweak the sizes for validation and left/right label layouts */

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1 span {
        width: 99.75% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2 span {
        width: 49.75% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 span {
        width: 33% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_4 span {
        width: 24.75% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_5 span {
        width: 19.75% !important;
    }

    html div.ginput_complex.ginput_container.gf_name_has_2 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_3 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_4 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_5 span:first-child {
        margin-left: 0!important;
    }

    .gform_wrapper .top_label .ginput_complex.ginput_container.ginput_container_email {
        width: calc(100% + 15px);
    }

    .gform_wrapper ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 16px);
        margin-left: 29%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 17px);
    }

    li.gfield.gfield_error

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error .ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 8px) !important;
    }

    .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_left,
    .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_right {
        padding-right: 16px;
    }

    .gform_wrapper ul.left_label li.gfield .gfield_password_strength,
    .gform_wrapper ul.right_label li.gfield .gfield_password_strength {
        width: 70%;
    }

    /* adjusting the address field alignment for some scenarios */

    .gform_wrapper .ginput_complex span.ginput_left + input.gform_hidden + span.ginput_left {
        padding-right: 0;
    }

    .gform_wrapper .ginput_complex span.ginput_left.address_country {
        margin-right: 0;
    }

    html:not[dir="rtl"] .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container:not(.ginput_container_time),
    html:not[dir="rtl"] .gform_wrapper ul.gform_fields:not(.top_label) .clear-multi,
    html:not[dir="rtl"] .gform_wrapper ul.gform_fields:not(.top_label) .gfield_description {
        width: 70%;
        margin-left: 29% !important;
    }

    .gform_wrapper .gf_progressbar {
        width: calc(100% - 16px);
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gform_fileupload_multifile .gform_drop_area {
        margin-left: 29%;
        width: 70%
    }

    body .gform_wrapper span.ginput_left.address_city + input.gform_hidden + span.ginput_left.address_country {
        width: calc(50% - 8px);
        margin-right: 0;
    }

    /* force the CAPTCHA field over for right and left label layouts */

    .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container.ginput_recaptcha {
        margin-left: 29% !important;
    }

    .gform_wrapper .gform_page_footer .button.gform_previous_button {
        margin-bottom: 0;
    }

    .gform_wrapper .gform_page_footer .button.gform_next_button,
    .gform_wrapper .gform_page_footer .button.gform_button {
        margin-right: 10px;
    }

}
