@import url(https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,600,700);
/*!
 * Bootstrap v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
 :root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  
  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
  }
  
  @-ms-viewport {
    width: device-width;
  }
  
  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }
  
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
  }
  
  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
  }
  
  ol,
  ul,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0;
  }
  
  dt {
    font-weight: 700;
  }
  
  dd {
    margin-bottom: .5rem;
    margin-left: 0;
  }
  
  blockquote {
    margin: 0 0 1rem;
  }
  
  dfn {
    font-style: italic;
  }
  
  b,
  strong {
    font-weight: bolder;
  }
  
  small {
    font-size: 80%;
  }
  
  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  
  sub {
    bottom: -.25em;
  }
  
  sup {
    top: -.5em;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
  
  a:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  
  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
  }
  
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
  }
  
  a:not([href]):not([tabindex]):focus {
    outline: 0;
  }
  
  pre,
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em;
  }
  
  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
  }
  
  figure {
    margin: 0 0 1rem;
  }
  
  img {
    vertical-align: middle;
    border-style: none;
  }
  
  svg:not(:root) {
    overflow: hidden;
  }
  
  table {
    border-collapse: collapse;
  }
  
  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
  }
  
  th {
    text-align: inherit;
  }
  
  label {
    display: inline-block;
    margin-bottom: .5rem;
  }
  
  button {
    border-radius: 0;
  }
  
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  
  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  button,
  input {
    overflow: visible;
  }
  
  button,
  select {
    text-transform: none;
  }
  
  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
  }
  
  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox;
  }
  
  textarea {
    overflow: auto;
    resize: vertical;
  }
  
  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
  }
  
  progress {
    vertical-align: baseline;
  }
  
  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }
  
  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  
  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  
  output {
    display: inline-block;
  }
  
  summary {
    display: list-item;
    cursor: pointer;
  }
  
  template {
    display: none;
  }
  
  [hidden] {
    display: none !important;
  }
  
  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
  }
  
  h1, .h1 {
    font-size: 2.5rem;
  }
  
  h2, .h2 {
    font-size: 2rem;
  }
  
  h3, .h3 {
    font-size: 1.75rem;
  }
  
  h4, .h4 {
    font-size: 1.5rem;
  }
  
  h5, .h5 {
    font-size: 1.25rem;
  }
  
  h6, .h6 {
    font-size: 1rem;
  }
  
  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }
  
  .display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  .display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
  }
  
  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  small,
  .small {
    font-size: 80%;
    font-weight: 400;
  }
  
  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3;
  }
  
  .list-unstyled {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline {
    padding-left: 0;
    list-style: none;
  }
  
  .list-inline-item {
    display: inline-block;
  }
  
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
  }
  
  .initialism {
    font-size: 90%;
    text-transform: uppercase;
  }
  
  .blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
  }
  
  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
  }
  
  .blockquote-footer::before {
    content: "\2014 \00A0";
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
  }
  
  .figure {
    display: inline-block;
  }
  
  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
  }
  
  .figure-caption {
    font-size: 90%;
    color: #6c757d;
  }
  
  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  }
  
  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
  }
  
  a > code {
    color: inherit;
  }
  
  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529;
    border-radius: 0.2rem;
  }
  
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
  }
  
  pre {
    display: block;
    font-size: 87.5%;
    color: #212529;
  }
  
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
  }
  
  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
  }
  
  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  @media (min-width: 576px) {
    .container {
      max-width: 540px;
    }
  }
  
  @media (min-width: 768px) {
    .container {
      max-width: 720px;
    }
  }
  
  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }
  
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }
  
  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  
  .col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  
  .col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  
  .col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  
  .col-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  
  .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  
  .col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  
  .col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  
  .col-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  
  .order-first {
    order: -1;
  }
  
  .order-last {
    order: 13;
  }
  
  .order-0 {
    order: 0;
  }
  
  .order-1 {
    order: 1;
  }
  
  .order-2 {
    order: 2;
  }
  
  .order-3 {
    order: 3;
  }
  
  .order-4 {
    order: 4;
  }
  
  .order-5 {
    order: 5;
  }
  
  .order-6 {
    order: 6;
  }
  
  .order-7 {
    order: 7;
  }
  
  .order-8 {
    order: 8;
  }
  
  .order-9 {
    order: 9;
  }
  
  .order-10 {
    order: 10;
  }
  
  .order-11 {
    order: 11;
  }
  
  .order-12 {
    order: 12;
  }
  
  .offset-1 {
    margin-left: 8.333333%;
  }
  
  .offset-2 {
    margin-left: 16.666667%;
  }
  
  .offset-3 {
    margin-left: 25%;
  }
  
  .offset-4 {
    margin-left: 33.333333%;
  }
  
  .offset-5 {
    margin-left: 41.666667%;
  }
  
  .offset-6 {
    margin-left: 50%;
  }
  
  .offset-7 {
    margin-left: 58.333333%;
  }
  
  .offset-8 {
    margin-left: 66.666667%;
  }
  
  .offset-9 {
    margin-left: 75%;
  }
  
  .offset-10 {
    margin-left: 83.333333%;
  }
  
  .offset-11 {
    margin-left: 91.666667%;
  }
  
  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sm-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sm-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sm-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sm-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-sm-first {
      order: -1;
    }
    .order-sm-last {
      order: 13;
    }
    .order-sm-0 {
      order: 0;
    }
    .order-sm-1 {
      order: 1;
    }
    .order-sm-2 {
      order: 2;
    }
    .order-sm-3 {
      order: 3;
    }
    .order-sm-4 {
      order: 4;
    }
    .order-sm-5 {
      order: 5;
    }
    .order-sm-6 {
      order: 6;
    }
    .order-sm-7 {
      order: 7;
    }
    .order-sm-8 {
      order: 8;
    }
    .order-sm-9 {
      order: 9;
    }
    .order-sm-10 {
      order: 10;
    }
    .order-sm-11 {
      order: 11;
    }
    .order-sm-12 {
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.333333%;
    }
    .offset-sm-2 {
      margin-left: 16.666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.333333%;
    }
    .offset-sm-5 {
      margin-left: 41.666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.333333%;
    }
    .offset-sm-8 {
      margin-left: 66.666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.333333%;
    }
    .offset-sm-11 {
      margin-left: 91.666667%;
    }
  }
  
  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-md-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-md-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-md-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-md-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-first {
      order: -1;
    }
    .order-md-last {
      order: 13;
    }
    .order-md-0 {
      order: 0;
    }
    .order-md-1 {
      order: 1;
    }
    .order-md-2 {
      order: 2;
    }
    .order-md-3 {
      order: 3;
    }
    .order-md-4 {
      order: 4;
    }
    .order-md-5 {
      order: 5;
    }
    .order-md-6 {
      order: 6;
    }
    .order-md-7 {
      order: 7;
    }
    .order-md-8 {
      order: 8;
    }
    .order-md-9 {
      order: 9;
    }
    .order-md-10 {
      order: 10;
    }
    .order-md-11 {
      order: 11;
    }
    .order-md-12 {
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.333333%;
    }
    .offset-md-2 {
      margin-left: 16.666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.333333%;
    }
    .offset-md-5 {
      margin-left: 41.666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.333333%;
    }
    .offset-md-8 {
      margin-left: 66.666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.333333%;
    }
    .offset-md-11 {
      margin-left: 91.666667%;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-lg-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-lg-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-lg-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-lg-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-lg-first {
      order: -1;
    }
    .order-lg-last {
      order: 13;
    }
    .order-lg-0 {
      order: 0;
    }
    .order-lg-1 {
      order: 1;
    }
    .order-lg-2 {
      order: 2;
    }
    .order-lg-3 {
      order: 3;
    }
    .order-lg-4 {
      order: 4;
    }
    .order-lg-5 {
      order: 5;
    }
    .order-lg-6 {
      order: 6;
    }
    .order-lg-7 {
      order: 7;
    }
    .order-lg-8 {
      order: 8;
    }
    .order-lg-9 {
      order: 9;
    }
    .order-lg-10 {
      order: 10;
    }
    .order-lg-11 {
      order: 11;
    }
    .order-lg-12 {
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.333333%;
    }
    .offset-lg-2 {
      margin-left: 16.666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.333333%;
    }
    .offset-lg-5 {
      margin-left: 41.666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.333333%;
    }
    .offset-lg-8 {
      margin-left: 66.666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.333333%;
    }
    .offset-lg-11 {
      margin-left: 91.666667%;
    }
  }
  
  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xl-1 {
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-xl-2 {
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xl-5 {
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xl-8 {
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-xl-11 {
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-xl-first {
      order: -1;
    }
    .order-xl-last {
      order: 13;
    }
    .order-xl-0 {
      order: 0;
    }
    .order-xl-1 {
      order: 1;
    }
    .order-xl-2 {
      order: 2;
    }
    .order-xl-3 {
      order: 3;
    }
    .order-xl-4 {
      order: 4;
    }
    .order-xl-5 {
      order: 5;
    }
    .order-xl-6 {
      order: 6;
    }
    .order-xl-7 {
      order: 7;
    }
    .order-xl-8 {
      order: 8;
    }
    .order-xl-9 {
      order: 9;
    }
    .order-xl-10 {
      order: 10;
    }
    .order-xl-11 {
      order: 11;
    }
    .order-xl-12 {
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.333333%;
    }
    .offset-xl-2 {
      margin-left: 16.666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.333333%;
    }
    .offset-xl-5 {
      margin-left: 41.666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.333333%;
    }
    .offset-xl-8 {
      margin-left: 66.666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.333333%;
    }
    .offset-xl-11 {
      margin-left: 91.666667%;
    }
  }
  
  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
  }
  
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
  
  .table tbody + tbody {
    border-top: 2px solid #dee2e6;
  }
  
  .table .table {
    background-color: #fff;
  }
  
  .table-sm th,
  .table-sm td {
    padding: 0.3rem;
  }
  
  .table-bordered {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6;
  }
  
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px;
  }
  
  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #b8daff;
  }
  
  .table-hover .table-primary:hover {
    background-color: #9fcdff;
  }
  
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff;
  }
  
  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: #d6d8db;
  }
  
  .table-hover .table-secondary:hover {
    background-color: #c8cbcf;
  }
  
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf;
  }
  
  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #c3e6cb;
  }
  
  .table-hover .table-success:hover {
    background-color: #b1dfbb;
  }
  
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb;
  }
  
  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #bee5eb;
  }
  
  .table-hover .table-info:hover {
    background-color: #abdde5;
  }
  
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5;
  }
  
  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #ffeeba;
  }
  
  .table-hover .table-warning:hover {
    background-color: #ffe8a1;
  }
  
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
  }
  
  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #f5c6cb;
  }
  
  .table-hover .table-danger:hover {
    background-color: #f1b0b7;
  }
  
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7;
  }
  
  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: #fdfdfe;
  }
  
  .table-hover .table-light:hover {
    background-color: #ececf6;
  }
  
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6;
  }
  
  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #c6c8ca;
  }
  
  .table-hover .table-dark:hover {
    background-color: #b9bbbe;
  }
  
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe;
  }
  
  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
  }
  
  .table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e;
  }
  
  .table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .table-dark {
    color: #fff;
    background-color: #212529;
  }
  
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e;
  }
  
  .table-dark.table-bordered {
    border: 0;
  }
  
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
  }
  
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
  }
  
  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-sm > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-md > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-lg > .table-bordered {
      border: 0;
    }
  }
  
  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive-xl > .table-bordered {
      border: 0;
    }
  }
  
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  
  .table-responsive > .table-bordered {
    border: 0;
  }
  
  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }
  
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
  
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 2px);
  }
  
  select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }
  
  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%;
  }
  
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  
  .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  }
  
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
  }
  
  .form-control-sm, .input-group-sm > .form-control,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(1.8125rem + 2px);
  }
  
  .form-control-lg, .input-group-lg > .form-control,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
  .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(2.875rem + 2px);
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  }
  
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
  
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  }
  
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d;
  }
  
  .form-check-label {
    margin-bottom: 0;
  }
  
  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  }
  
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
  }
  
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
  }
  
  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.8);
    border-radius: .2rem;
  }
  
  .was-validated .form-control:valid, .form-control.is-valid, .was-validated
  .custom-select:valid,
  .custom-select.is-valid {
    border-color: #28a745;
  }
  
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745;
  }
  
  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a;
  }
  
  .was-validated .custom-control-input:valid ~ .valid-feedback,
  .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
  .custom-control-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #34ce57;
  }
  
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745;
  }
  
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit;
  }
  
  .was-validated .custom-file-input:valid ~ .valid-feedback,
  .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
  .custom-file-input.is-valid ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
  }
  
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
  
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.8);
    border-radius: .2rem;
  }
  
  .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
  .custom-select:invalid,
  .custom-select.is-invalid {
    border-color: #dc3545;
  }
  
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #dc3545;
  }
  
  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: #dc3545;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9;
  }
  
  .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
  .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #e4606d;
  }
  
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #dc3545;
  }
  
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit;
  }
  
  .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
  .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  
  .form-inline .form-check {
    width: 100%;
  }
  
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
      display: inline-block;
    }
    .form-inline .input-group {
      width: auto;
    }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0;
    }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center;
    }
    .form-inline .custom-control-label {
      margin-bottom: 0;
    }
  }
  
  .btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  .btn:hover, .btn:focus {
    text-decoration: none;
  }
  
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .btn.disabled, .btn:disabled {
    opacity: 0.65;
  }
  
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
  }
  
  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none;
  }
  
  .btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
  }
  
  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
  
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
  }
  
  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
  
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }
  
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
  }
  
  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
  }
  
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-outline-primary {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
  }
  
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
  }
  
  .btn-outline-secondary {
    color: #6c757d;
    background-color: transparent;
    background-image: none;
    border-color: #6c757d;
  }
  
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
  }
  
  .btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: #28a745;
  }
  
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
  }
  
  .btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
  }
  
  .btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
  }
  
  .btn-outline-danger {
    color: #dc3545;
    background-color: transparent;
    background-image: none;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
  }
  
  .btn-outline-light {
    color: #f8f9fa;
    background-color: transparent;
    background-image: none;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
  }
  
  .btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
  }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
  }
  
  .btn-link {
    font-weight: 400;
    color: #007bff;
    background-color: transparent;
  }
  
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
  }
  
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
  }
  
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
  }
  
  .btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  .btn-block {
    display: block;
    width: 100%;
  }
  
  .btn-block + .btn-block {
    margin-top: 0.5rem;
  }
  
  input[type="submit"].btn-block,
  input[type="reset"].btn-block,
  input[type="button"].btn-block {
    width: 100%;
  }
  
  .fade {
    opacity: 0;
    transition: opacity 0.15s linear;
  }
  
  .fade.show {
    opacity: 1;
  }
  
  .collapse {
    display: none;
  }
  
  .collapse.show {
    display: block;
  }
  
  tr.collapse.show {
    display: table-row;
  }
  
  tbody.collapse.show {
    display: table-row-group;
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  .dropup,
  .dropdown {
    position: relative;
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
  }
  
  .dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: 0.125rem;
  }
  
  .dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
  }
  
  .dropup .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-menu {
    margin-top: 0;
    margin-left: 0.125rem;
  }
  
  .dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
  }
  
  .dropright .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropright .dropdown-toggle::after {
    vertical-align: 0;
  }
  
  .dropleft .dropdown-menu {
    margin-top: 0;
    margin-right: 0.125rem;
  }
  
  .dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
  }
  
  .dropleft .dropdown-toggle::after {
    display: none;
  }
  
  .dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
  }
  
  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
  }
  
  .dropleft .dropdown-toggle::before {
    vertical-align: 0;
  }
  
  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }
  
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
  
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    background-color: transparent;
  }
  
  .dropdown-menu.show {
    display: block;
  }
  
  .dropdown-header {
    display: block;
    padding: 0.5rem 1.5rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
  }
  
  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  }
  
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto;
  }
  
  .btn-group > .btn:hover,
  .btn-group-vertical > .btn:hover {
    z-index: 1;
  }
  
  .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1;
  }
  
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px;
  }
  
  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  
  .btn-toolbar .input-group {
    width: auto;
  }
  
  .btn-group > .btn:first-child {
    margin-left: 0;
  }
  
  .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .btn-group > .btn:not(:first-child),
  .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
  }
  
  .dropdown-toggle-split::after {
    margin-left: 0;
  }
  
  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
  }
  
  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
  
  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%;
  }
  
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0;
  }
  
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
  }
  
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }
  
  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
  }
  
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
  }
  
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file:focus {
    z-index: 3;
  }
  
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    margin-left: -1px;
  }
  
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group > .custom-file {
    display: flex;
    align-items: center;
  }
  
  .input-group > .custom-file:not(:last-child) .custom-file-label,
  .input-group > .custom-file:not(:last-child) .custom-file-label::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .custom-file:not(:first-child) .custom-file-label,
  .input-group > .custom-file:not(:first-child) .custom-file-label::before {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .input-group-prepend,
  .input-group-append {
    display: flex;
  }
  
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2;
  }
  
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px;
  }
  
  .input-group-prepend {
    margin-right: -1px;
  }
  
  .input-group-append {
    margin-left: -1px;
  }
  
  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0;
  }
  
  .input-group > .input-group-prepend > .btn,
  .input-group > .input-group-prepend > .input-group-text,
  .input-group > .input-group-append:not(:last-child) > .btn,
  .input-group > .input-group-append:not(:last-child) > .input-group-text,
  .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
  .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .input-group > .input-group-append > .btn,
  .input-group > .input-group-append > .input-group-text,
  .input-group > .input-group-prepend:not(:first-child) > .btn,
  .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
  .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
  .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
  }
  
  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
  }
  
  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff;
  }
  
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
  }
  
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d;
  }
  
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #e9ecef;
  }
  
  .custom-control-label {
    margin-bottom: 0;
  }
  
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: #dee2e6;
  }
  
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  
  .custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007bff;
  }
  
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #007bff;
  }
  
  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
  }
  
  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-radio .custom-control-label::before {
    border-radius: 50%;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #007bff;
  }
  
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  }
  
  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
  }
  
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(128, 189, 255, 0.5);
  }
  
  .custom-select:focus::-ms-value {
    color: #495057;
    background-color: #fff;
  }
  
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
  }
  
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }
  
  .custom-select::-ms-expand {
    opacity: 0;
  }
  
  .custom-select-sm {
    height: calc(1.8125rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
  }
  
  .custom-select-lg {
    height: calc(2.875rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
  }
  
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
  }
  
  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;
  }
  
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .custom-file-input:focus ~ .custom-file-control::before {
    border-color: #80bdff;
  }
  
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
  }
  
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0;
  }
  
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  
  .nav-link:hover, .nav-link:focus {
    text-decoration: none;
  }
  
  .nav-link.disabled {
    color: #6c757d;
  }
  
  .nav-tabs {
    border-bottom: 1px solid #dee2e6;
  }
  
  .nav-tabs .nav-item {
    margin-bottom: -1px;
  }
  
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
  }
  
  .nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
  }
  
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .nav-pills .nav-link {
    border-radius: 0.25rem;
  }
  
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #007bff;
  }
  
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > .tab-pane {
    display: none;
  }
  
  .tab-content > .active {
    display: block;
  }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  }
  
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
  }
  
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
      display: none;
    }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
      display: none;
    }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
      display: none;
    }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
      display: none;
    }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%;
    }
  }
  
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  
  .navbar-expand .navbar-nav {
    flex-direction: row;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
  }
  
  .navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap;
  }
  
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  
  .navbar-expand .navbar-toggler {
    display: none;
  }
  
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
  
  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
  }
  
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
  }
  
  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }
  
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
  }
  
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
  }
  
  .navbar-dark .navbar-brand {
    color: #fff;
  }
  
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff;
  }
  
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
  }
  
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
  }
  
  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
  }
  
  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .navbar-dark .navbar-text a {
    color: #fff;
  }
  
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff;
  }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
  }
  
  .card > hr {
    margin-right: 0;
    margin-left: 0;
  }
  
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
  
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }
  
  .card-link:hover {
    text-decoration: none;
  }
  
  .card-link + .card-link {
    margin-left: 1.25rem;
  }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  }
  
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0;
  }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
  }
  
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  
  .card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
  }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
  }
  
  .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
  }
  
  .card-deck {
    display: flex;
    flex-direction: column;
  }
  
  .card-deck .card {
    margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
    }
    .card-deck .card {
      display: flex;
      flex: 1 0;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
  
  .card-group {
    display: flex;
    flex-direction: column;
  }
  
  .card-group > .card {
    margin-bottom: 15px;
  }
  
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap;
    }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0;
    }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0;
    }
    .card-group > .card:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-top,
    .card-group > .card:first-child .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:first-child .card-img-bottom,
    .card-group > .card:first-child .card-footer {
      border-bottom-right-radius: 0;
    }
    .card-group > .card:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-top,
    .card-group > .card:last-child .card-header {
      border-top-left-radius: 0;
    }
    .card-group > .card:last-child .card-img-bottom,
    .card-group > .card:last-child .card-footer {
      border-bottom-left-radius: 0;
    }
    .card-group > .card:only-child {
      border-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-img-top,
    .card-group > .card:only-child .card-header {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }
    .card-group > .card:only-child .card-img-bottom,
    .card-group > .card:only-child .card-footer {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
      border-radius: 0;
    }
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
      border-radius: 0;
    }
  }
  
  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      -webkit-column-count: 3;
      column-count: 3;
      -webkit-column-gap: 1.25rem;
      -moz-column-gap: 1.25rem;
      grid-column-gap: 1.25rem;
      column-gap: 1.25rem;
    }
    .card-columns .card {
      display: inline-block;
      width: 100%;
    }
  }
  
  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: #6c757d;
    content: "/";
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
  }
  
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
  }
  
  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
  }
  
  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
  }
  
  .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6;
  }
  
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
  
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6;
  }
  
  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  
  .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
  }
  
  .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
  
  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
  }
  
  .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
  }
  
  .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
  }
  
  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
  }
  
  .badge:empty {
    display: none;
  }
  
  .btn .badge {
    position: relative;
    top: -1px;
  }
  
  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
  }
  
  .badge-primary {
    color: #fff;
    background-color: #007bff;
  }
  
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc;
  }
  
  .badge-secondary {
    color: #fff;
    background-color: #6c757d;
  }
  
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #545b62;
  }
  
  .badge-success {
    color: #fff;
    background-color: #28a745;
  }
  
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34;
  }
  
  .badge-info {
    color: #fff;
    background-color: #17a2b8;
  }
  
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b;
  }
  
  .badge-warning {
    color: #212529;
    background-color: #ffc107;
  }
  
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00;
  }
  
  .badge-danger {
    color: #fff;
    background-color: #dc3545;
  }
  
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130;
  }
  
  .badge-light {
    color: #212529;
    background-color: #f8f9fa;
  }
  
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5;
  }
  
  .badge-dark {
    color: #fff;
    background-color: #343a40;
  }
  
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124;
  }
  
  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
  }
  
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem;
    }
  }
  
  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
  }
  
  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }
  
  .alert-heading {
    color: inherit;
  }
  
  .alert-link {
    font-weight: 700;
  }
  
  .alert-dismissible {
    padding-right: 4rem;
  }
  
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
  }
  
  .alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
  }
  
  .alert-primary hr {
    border-top-color: #9fcdff;
  }
  
  .alert-primary .alert-link {
    color: #002752;
  }
  
  .alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
  }
  
  .alert-secondary hr {
    border-top-color: #c8cbcf;
  }
  
  .alert-secondary .alert-link {
    color: #202326;
  }
  
  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  .alert-success hr {
    border-top-color: #b1dfbb;
  }
  
  .alert-success .alert-link {
    color: #0b2e13;
  }
  
  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }
  
  .alert-info hr {
    border-top-color: #abdde5;
  }
  
  .alert-info .alert-link {
    color: #062c33;
  }
  
  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  
  .alert-warning hr {
    border-top-color: #ffe8a1;
  }
  
  .alert-warning .alert-link {
    color: #533f03;
  }
  
  .alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }
  
  .alert-danger hr {
    border-top-color: #f1b0b7;
  }
  
  .alert-danger .alert-link {
    color: #491217;
  }
  
  .alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
  }
  
  .alert-light hr {
    border-top-color: #ececf6;
  }
  
  .alert-light .alert-link {
    color: #686868;
  }
  
  .alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
  }
  
  .alert-dark hr {
    border-top-color: #b9bbbe;
  }
  
  .alert-dark .alert-link {
    color: #040505;
  }
  
  @-webkit-keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
    to {
      background-position: 0 0;
    }
  }
  
  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.25rem;
  }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    background-color: #007bff;
    transition: width 0.6s ease;
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    -webkit-animation: progress-bar-stripes 1s linear infinite;
    animation: progress-bar-stripes 1s linear infinite;
  }
  
  .media {
    display: flex;
    align-items: flex-start;
  }
  
  .media-body {
    flex: 1 1;
  }
  
  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
  }
  
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef;
  }
  
  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
  }
  
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    background-color: #fff;
  }
  
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }
  
  .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
  }
  
  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
  }
  
  .list-group-item-primary {
    color: #004085;
    background-color: #b8daff;
  }
  
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff;
  }
  
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085;
  }
  
  .list-group-item-secondary {
    color: #383d41;
    background-color: #d6d8db;
  }
  
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf;
  }
  
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41;
  }
  
  .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb;
  }
  
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb;
  }
  
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724;
  }
  
  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb;
  }
  
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5;
  }
  
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460;
  }
  
  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
  }
  
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
  }
  
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404;
  }
  
  .list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb;
  }
  
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7;
  }
  
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24;
  }
  
  .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
  }
  
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
  }
  
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
  }
  
  .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
  }
  
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe;
  }
  
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
  }
  
  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
  }
  
  .close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
  }
  
  .close:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
  
  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
  }
  
  .modal-open {
    overflow: hidden;
  }
  
  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
  }
  
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
  }
  
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
  }
  
  .modal.show .modal-dialog {
    transform: translate(0, 0);
  }
  
  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
  }
  
  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
  }
  
  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
  }
  
  .modal-backdrop.fade {
    opacity: 0;
  }
  
  .modal-backdrop.show {
    opacity: 0.5;
  }
  
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }
  
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
  }
  
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
  }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e9ecef;
  }
  
  .modal-footer > :not(:first-child) {
    margin-left: .25rem;
  }
  
  .modal-footer > :not(:last-child) {
    margin-right: .25rem;
  }
  
  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
  }
  
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2));
    }
    .modal-sm {
      max-width: 300px;
    }
  }
  
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px;
    }
  }
  
  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
  }
  
  .tooltip.show {
    opacity: 0.9;
  }
  
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
  }
  
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
  }
  
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
  }
  
  .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
  }
  
  .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
  }
  
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
  }
  
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
  }
  
  .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
  }
  
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
  }
  
  .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
  }
  
  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
  }
  
  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
  }
  
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
  }
  
  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
  }
  
  .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
  }
  
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
  }
  
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
  }
  
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-top .arrow::after, .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff;
  }
  
  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
  }
  
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
  }
  
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-right .arrow::after, .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff;
  }
  
  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
  }
  
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
  }
  
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
  }
  
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff;
  }
  
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
  }
  
  .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
  }
  
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
  }
  
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
  }
  
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
  }
  
  .bs-popover-left .arrow::after, .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff;
  }
  
  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
  }
  
  .popover-header:empty {
    display: none;
  }
  
  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
  }
  
  .carousel {
    position: relative;
  }
  
  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
  }
  
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block;
  }
  
  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0;
  }
  
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateX(0);
  }
  
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0);
    }
  }
  
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translateX(100%);
  }
  
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0);
    }
  }
  
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translateX(-100%);
  }
  
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 0.5;
  }
  
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
  
  .carousel-control-prev {
    left: 0;
  }
  
  .carousel-control-next {
    right: 0;
  }
  
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
  }
  
  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
  }
  
  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
  }
  
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
  }
  
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }
  
  .carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
  }
  
  .carousel-indicators .active {
    background-color: #fff;
  }
  
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
  }
  
  .align-baseline {
    vertical-align: baseline !important;
  }
  
  .align-top {
    vertical-align: top !important;
  }
  
  .align-middle {
    vertical-align: middle !important;
  }
  
  .align-bottom {
    vertical-align: bottom !important;
  }
  
  .align-text-bottom {
    vertical-align: text-bottom !important;
  }
  
  .align-text-top {
    vertical-align: text-top !important;
  }
  
  .bg-primary {
    background-color: #007bff !important;
  }
  
  a.bg-primary:hover, a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #0062cc !important;
  }
  
  .bg-secondary {
    background-color: #6c757d !important;
  }
  
  a.bg-secondary:hover, a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: #545b62 !important;
  }
  
  .bg-success {
    background-color: #28a745 !important;
  }
  
  a.bg-success:hover, a.bg-success:focus,
  button.bg-success:hover,
  button.bg-success:focus {
    background-color: #1e7e34 !important;
  }
  
  .bg-info {
    background-color: #17a2b8 !important;
  }
  
  a.bg-info:hover, a.bg-info:focus,
  button.bg-info:hover,
  button.bg-info:focus {
    background-color: #117a8b !important;
  }
  
  .bg-warning {
    background-color: #ffc107 !important;
  }
  
  a.bg-warning:hover, a.bg-warning:focus,
  button.bg-warning:hover,
  button.bg-warning:focus {
    background-color: #d39e00 !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
  }
  
  a.bg-danger:hover, a.bg-danger:focus,
  button.bg-danger:hover,
  button.bg-danger:focus {
    background-color: #bd2130 !important;
  }
  
  .bg-light {
    background-color: #f8f9fa !important;
  }
  
  a.bg-light:hover, a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #dae0e5 !important;
  }
  
  .bg-dark {
    background-color: #343a40 !important;
  }
  
  a.bg-dark:hover, a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: #1d2124 !important;
  }
  
  .bg-white {
    background-color: #fff !important;
  }
  
  .bg-transparent {
    background-color: transparent !important;
  }
  
  .border {
    border: 1px solid #dee2e6 !important;
  }
  
  .border-top {
    border-top: 1px solid #dee2e6 !important;
  }
  
  .border-right {
    border-right: 1px solid #dee2e6 !important;
  }
  
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  
  .border-left {
    border-left: 1px solid #dee2e6 !important;
  }
  
  .border-0 {
    border: 0 !important;
  }
  
  .border-top-0 {
    border-top: 0 !important;
  }
  
  .border-right-0 {
    border-right: 0 !important;
  }
  
  .border-bottom-0 {
    border-bottom: 0 !important;
  }
  
  .border-left-0 {
    border-left: 0 !important;
  }
  
  .border-primary {
    border-color: #007bff !important;
  }
  
  .border-secondary {
    border-color: #6c757d !important;
  }
  
  .border-success {
    border-color: #28a745 !important;
  }
  
  .border-info {
    border-color: #17a2b8 !important;
  }
  
  .border-warning {
    border-color: #ffc107 !important;
  }
  
  .border-danger {
    border-color: #dc3545 !important;
  }
  
  .border-light {
    border-color: #f8f9fa !important;
  }
  
  .border-dark {
    border-color: #343a40 !important;
  }
  
  .border-white {
    border-color: #fff !important;
  }
  
  .rounded {
    border-radius: 0.25rem !important;
  }
  
  .rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  
  .rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  
  .rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  .rounded-circle {
    border-radius: 50% !important;
  }
  
  .rounded-0 {
    border-radius: 0 !important;
  }
  
  .clearfix::after {
    display: block;
    clear: both;
    content: "";
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-row {
    display: table-row !important;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .d-inline-flex {
    display: inline-flex !important;
  }
  
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: inline-flex !important;
    }
  }
  
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: flex !important;
    }
    .d-print-inline-flex {
      display: inline-flex !important;
    }
  }
  
  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  
  .embed-responsive::before {
    display: block;
    content: "";
  }
  
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
  
  .embed-responsive-21by9::before {
    padding-top: 42.857143%;
  }
  
  .embed-responsive-16by9::before {
    padding-top: 56.25%;
  }
  
  .embed-responsive-4by3::before {
    padding-top: 75%;
  }
  
  .embed-responsive-1by1::before {
    padding-top: 100%;
  }
  
  .flex-row {
    flex-direction: row !important;
  }
  
  .flex-column {
    flex-direction: column !important;
  }
  
  .flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  
  .justify-content-start {
    justify-content: flex-start !important;
  }
  
  .justify-content-end {
    justify-content: flex-end !important;
  }
  
  .justify-content-center {
    justify-content: center !important;
  }
  
  .justify-content-between {
    justify-content: space-between !important;
  }
  
  .justify-content-around {
    justify-content: space-around !important;
  }
  
  .align-items-start {
    align-items: flex-start !important;
  }
  
  .align-items-end {
    align-items: flex-end !important;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  
  .align-items-baseline {
    align-items: baseline !important;
  }
  
  .align-items-stretch {
    align-items: stretch !important;
  }
  
  .align-content-start {
    align-content: flex-start !important;
  }
  
  .align-content-end {
    align-content: flex-end !important;
  }
  
  .align-content-center {
    align-content: center !important;
  }
  
  .align-content-between {
    align-content: space-between !important;
  }
  
  .align-content-around {
    align-content: space-around !important;
  }
  
  .align-content-stretch {
    align-content: stretch !important;
  }
  
  .align-self-auto {
    align-self: auto !important;
  }
  
  .align-self-start {
    align-self: flex-start !important;
  }
  
  .align-self-end {
    align-self: flex-end !important;
  }
  
  .align-self-center {
    align-self: center !important;
  }
  
  .align-self-baseline {
    align-self: baseline !important;
  }
  
  .align-self-stretch {
    align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
    .flex-sm-row {
      flex-direction: row !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .align-items-sm-baseline {
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      align-items: stretch !important;
    }
    .align-content-sm-start {
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      align-content: center !important;
    }
    .align-content-sm-between {
      align-content: space-between !important;
    }
    .align-content-sm-around {
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      align-self: auto !important;
    }
    .align-self-sm-start {
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      align-self: center !important;
    }
    .align-self-sm-baseline {
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 768px) {
    .flex-md-row {
      flex-direction: row !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .align-items-md-start {
      align-items: flex-start !important;
    }
    .align-items-md-end {
      align-items: flex-end !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .align-items-md-baseline {
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      align-items: stretch !important;
    }
    .align-content-md-start {
      align-content: flex-start !important;
    }
    .align-content-md-end {
      align-content: flex-end !important;
    }
    .align-content-md-center {
      align-content: center !important;
    }
    .align-content-md-between {
      align-content: space-between !important;
    }
    .align-content-md-around {
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      align-content: stretch !important;
    }
    .align-self-md-auto {
      align-self: auto !important;
    }
    .align-self-md-start {
      align-self: flex-start !important;
    }
    .align-self-md-end {
      align-self: flex-end !important;
    }
    .align-self-md-center {
      align-self: center !important;
    }
    .align-self-md-baseline {
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 992px) {
    .flex-lg-row {
      flex-direction: row !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .align-items-lg-baseline {
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      align-items: stretch !important;
    }
    .align-content-lg-start {
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      align-content: center !important;
    }
    .align-content-lg-between {
      align-content: space-between !important;
    }
    .align-content-lg-around {
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      align-self: auto !important;
    }
    .align-self-lg-start {
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      align-self: center !important;
    }
    .align-self-lg-baseline {
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 1200px) {
    .flex-xl-row {
      flex-direction: row !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .align-items-xl-baseline {
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      align-items: stretch !important;
    }
    .align-content-xl-start {
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      align-content: center !important;
    }
    .align-content-xl-between {
      align-content: space-between !important;
    }
    .align-content-xl-around {
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      align-self: auto !important;
    }
    .align-self-xl-start {
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      align-self: center !important;
    }
    .align-self-xl-baseline {
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      align-self: stretch !important;
    }
  }
  
  .float-left {
    float: left !important;
  }
  
  .float-right {
    float: right !important;
  }
  
  .float-none {
    float: none !important;
  }
  
  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important;
    }
    .float-sm-right {
      float: right !important;
    }
    .float-sm-none {
      float: none !important;
    }
  }
  
  @media (min-width: 768px) {
    .float-md-left {
      float: left !important;
    }
    .float-md-right {
      float: right !important;
    }
    .float-md-none {
      float: none !important;
    }
  }
  
  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important;
    }
    .float-lg-right {
      float: right !important;
    }
    .float-lg-none {
      float: none !important;
    }
  }
  
  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important;
    }
    .float-xl-right {
      float: right !important;
    }
    .float-xl-none {
      float: none !important;
    }
  }
  
  .position-static {
    position: static !important;
  }
  
  .position-relative {
    position: relative !important;
  }
  
  .position-absolute {
    position: absolute !important;
  }
  
  .position-fixed {
    position: fixed !important;
  }
  
  .position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
  }
  
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  
  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
  
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020;
    }
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    border: 0;
  }
  
  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
    -webkit-clip-path: none;
    clip-path: none;
  }
  
  .w-25 {
    width: 25% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .h-25 {
    height: 25% !important;
  }
  
  .h-50 {
    height: 50% !important;
  }
  
  .h-75 {
    height: 75% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .mw-100 {
    max-width: 100% !important;
  }
  
  .mh-100 {
    max-height: 100% !important;
  }
  
  .m-0 {
    margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  
  .m-1 {
    margin: 0.25rem !important;
  }
  
  .mt-1,
  .my-1 {
    margin-top: 0.25rem !important;
  }
  
  .mr-1,
  .mx-1 {
    margin-right: 0.25rem !important;
  }
  
  .mb-1,
  .my-1 {
    margin-bottom: 0.25rem !important;
  }
  
  .ml-1,
  .mx-1 {
    margin-left: 0.25rem !important;
  }
  
  .m-2 {
    margin: 0.5rem !important;
  }
  
  .mt-2,
  .my-2 {
    margin-top: 0.5rem !important;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 0.5rem !important;
  }
  
  .mb-2,
  .my-2 {
    margin-bottom: 0.5rem !important;
  }
  
  .ml-2,
  .mx-2 {
    margin-left: 0.5rem !important;
  }
  
  .m-3 {
    margin: 1rem !important;
  }
  
  .mt-3,
  .my-3 {
    margin-top: 1rem !important;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 1rem !important;
  }
  
  .mb-3,
  .my-3 {
    margin-bottom: 1rem !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 1rem !important;
  }
  
  .m-4 {
    margin: 1.5rem !important;
  }
  
  .mt-4,
  .my-4 {
    margin-top: 1.5rem !important;
  }
  
  .mr-4,
  .mx-4 {
    margin-right: 1.5rem !important;
  }
  
  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }
  
  .ml-4,
  .mx-4 {
    margin-left: 1.5rem !important;
  }
  
  .m-5 {
    margin: 3rem !important;
  }
  
  .mt-5,
  .my-5 {
    margin-top: 3rem !important;
  }
  
  .mr-5,
  .mx-5 {
    margin-right: 3rem !important;
  }
  
  .mb-5,
  .my-5 {
    margin-bottom: 3rem !important;
  }
  
  .ml-5,
  .mx-5 {
    margin-left: 3rem !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  
  .p-1 {
    padding: 0.25rem !important;
  }
  
  .pt-1,
  .py-1 {
    padding-top: 0.25rem !important;
  }
  
  .pr-1,
  .px-1 {
    padding-right: 0.25rem !important;
  }
  
  .pb-1,
  .py-1 {
    padding-bottom: 0.25rem !important;
  }
  
  .pl-1,
  .px-1 {
    padding-left: 0.25rem !important;
  }
  
  .p-2 {
    padding: 0.5rem !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 0.5rem !important;
  }
  
  .pr-2,
  .px-2 {
    padding-right: 0.5rem !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 0.5rem !important;
  }
  
  .pl-2,
  .px-2 {
    padding-left: 0.5rem !important;
  }
  
  .p-3 {
    padding: 1rem !important;
  }
  
  .pt-3,
  .py-3 {
    padding-top: 1rem !important;
  }
  
  .pr-3,
  .px-3 {
    padding-right: 1rem !important;
  }
  
  .pb-3,
  .py-3 {
    padding-bottom: 1rem !important;
  }
  
  .pl-3,
  .px-3 {
    padding-left: 1rem !important;
  }
  
  .p-4 {
    padding: 1.5rem !important;
  }
  
  .pt-4,
  .py-4 {
    padding-top: 1.5rem !important;
  }
  
  .pr-4,
  .px-4 {
    padding-right: 1.5rem !important;
  }
  
  .pb-4,
  .py-4 {
    padding-bottom: 1.5rem !important;
  }
  
  .pl-4,
  .px-4 {
    padding-left: 1.5rem !important;
  }
  
  .p-5 {
    padding: 3rem !important;
  }
  
  .pt-5,
  .py-5 {
    padding-top: 3rem !important;
  }
  
  .pr-5,
  .px-5 {
    padding-right: 3rem !important;
  }
  
  .pb-5,
  .py-5 {
    padding-bottom: 3rem !important;
  }
  
  .pl-5,
  .px-5 {
    padding-left: 3rem !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
    .m-sm-1 {
      margin: 0.25rem !important;
    }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.25rem !important;
    }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.25rem !important;
    }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.25rem !important;
    }
    .m-sm-2 {
      margin: 0.5rem !important;
    }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 0.5rem !important;
    }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 0.5rem !important;
    }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 0.5rem !important;
    }
    .m-sm-3 {
      margin: 1rem !important;
    }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1rem !important;
    }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1rem !important;
    }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1rem !important;
    }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1rem !important;
    }
    .m-sm-4 {
      margin: 1.5rem !important;
    }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 1.5rem !important;
    }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 1.5rem !important;
    }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 1.5rem !important;
    }
    .m-sm-5 {
      margin: 3rem !important;
    }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3rem !important;
    }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3rem !important;
    }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3rem !important;
    }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3rem !important;
    }
    .p-sm-0 {
      padding: 0 !important;
    }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
    .p-sm-1 {
      padding: 0.25rem !important;
    }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.25rem !important;
    }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.25rem !important;
    }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.25rem !important;
    }
    .p-sm-2 {
      padding: 0.5rem !important;
    }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 0.5rem !important;
    }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 0.5rem !important;
    }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 0.5rem !important;
    }
    .p-sm-3 {
      padding: 1rem !important;
    }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1rem !important;
    }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1rem !important;
    }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1rem !important;
    }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1rem !important;
    }
    .p-sm-4 {
      padding: 1.5rem !important;
    }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 1.5rem !important;
    }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 1.5rem !important;
    }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 1.5rem !important;
    }
    .p-sm-5 {
      padding: 3rem !important;
    }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3rem !important;
    }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3rem !important;
    }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3rem !important;
    }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3rem !important;
    }
    .m-sm-auto {
      margin: auto !important;
    }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
    .m-md-1 {
      margin: 0.25rem !important;
    }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.25rem !important;
    }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.25rem !important;
    }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.25rem !important;
    }
    .m-md-2 {
      margin: 0.5rem !important;
    }
    .mt-md-2,
    .my-md-2 {
      margin-top: 0.5rem !important;
    }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 0.5rem !important;
    }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 0.5rem !important;
    }
    .m-md-3 {
      margin: 1rem !important;
    }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1rem !important;
    }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1rem !important;
    }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1rem !important;
    }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1rem !important;
    }
    .m-md-4 {
      margin: 1.5rem !important;
    }
    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important;
    }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 1.5rem !important;
    }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 1.5rem !important;
    }
    .m-md-5 {
      margin: 3rem !important;
    }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3rem !important;
    }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3rem !important;
    }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3rem !important;
    }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3rem !important;
    }
    .p-md-0 {
      padding: 0 !important;
    }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
    .p-md-1 {
      padding: 0.25rem !important;
    }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.25rem !important;
    }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.25rem !important;
    }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.25rem !important;
    }
    .p-md-2 {
      padding: 0.5rem !important;
    }
    .pt-md-2,
    .py-md-2 {
      padding-top: 0.5rem !important;
    }
    .pr-md-2,
    .px-md-2 {
      padding-right: 0.5rem !important;
    }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-md-2,
    .px-md-2 {
      padding-left: 0.5rem !important;
    }
    .p-md-3 {
      padding: 1rem !important;
    }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1rem !important;
    }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1rem !important;
    }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1rem !important;
    }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1rem !important;
    }
    .p-md-4 {
      padding: 1.5rem !important;
    }
    .pt-md-4,
    .py-md-4 {
      padding-top: 1.5rem !important;
    }
    .pr-md-4,
    .px-md-4 {
      padding-right: 1.5rem !important;
    }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-md-4,
    .px-md-4 {
      padding-left: 1.5rem !important;
    }
    .p-md-5 {
      padding: 3rem !important;
    }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3rem !important;
    }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3rem !important;
    }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3rem !important;
    }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3rem !important;
    }
    .m-md-auto {
      margin: auto !important;
    }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important;
    }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
    .m-lg-1 {
      margin: 0.25rem !important;
    }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.25rem !important;
    }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.25rem !important;
    }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.25rem !important;
    }
    .m-lg-2 {
      margin: 0.5rem !important;
    }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 0.5rem !important;
    }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 0.5rem !important;
    }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 0.5rem !important;
    }
    .m-lg-3 {
      margin: 1rem !important;
    }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1rem !important;
    }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1rem !important;
    }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1rem !important;
    }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1rem !important;
    }
    .m-lg-4 {
      margin: 1.5rem !important;
    }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 1.5rem !important;
    }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 1.5rem !important;
    }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 1.5rem !important;
    }
    .m-lg-5 {
      margin: 3rem !important;
    }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3rem !important;
    }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3rem !important;
    }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3rem !important;
    }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3rem !important;
    }
    .p-lg-0 {
      padding: 0 !important;
    }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
    .p-lg-1 {
      padding: 0.25rem !important;
    }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.25rem !important;
    }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.25rem !important;
    }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.25rem !important;
    }
    .p-lg-2 {
      padding: 0.5rem !important;
    }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 0.5rem !important;
    }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 0.5rem !important;
    }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 0.5rem !important;
    }
    .p-lg-3 {
      padding: 1rem !important;
    }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1rem !important;
    }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1rem !important;
    }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1rem !important;
    }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1rem !important;
    }
    .p-lg-4 {
      padding: 1.5rem !important;
    }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 1.5rem !important;
    }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 1.5rem !important;
    }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 1.5rem !important;
    }
    .p-lg-5 {
      padding: 3rem !important;
    }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3rem !important;
    }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3rem !important;
    }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3rem !important;
    }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3rem !important;
    }
    .m-lg-auto {
      margin: auto !important;
    }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
    .m-xl-1 {
      margin: 0.25rem !important;
    }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.25rem !important;
    }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.25rem !important;
    }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.25rem !important;
    }
    .m-xl-2 {
      margin: 0.5rem !important;
    }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 0.5rem !important;
    }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 0.5rem !important;
    }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 0.5rem !important;
    }
    .m-xl-3 {
      margin: 1rem !important;
    }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1rem !important;
    }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1rem !important;
    }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1rem !important;
    }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1rem !important;
    }
    .m-xl-4 {
      margin: 1.5rem !important;
    }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 1.5rem !important;
    }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 1.5rem !important;
    }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 1.5rem !important;
    }
    .m-xl-5 {
      margin: 3rem !important;
    }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3rem !important;
    }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3rem !important;
    }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3rem !important;
    }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3rem !important;
    }
    .p-xl-0 {
      padding: 0 !important;
    }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
    .p-xl-1 {
      padding: 0.25rem !important;
    }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.25rem !important;
    }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.25rem !important;
    }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.25rem !important;
    }
    .p-xl-2 {
      padding: 0.5rem !important;
    }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 0.5rem !important;
    }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 0.5rem !important;
    }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 0.5rem !important;
    }
    .p-xl-3 {
      padding: 1rem !important;
    }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1rem !important;
    }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1rem !important;
    }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1rem !important;
    }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1rem !important;
    }
    .p-xl-4 {
      padding: 1.5rem !important;
    }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 1.5rem !important;
    }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 1.5rem !important;
    }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 1.5rem !important;
    }
    .p-xl-5 {
      padding: 3rem !important;
    }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3rem !important;
    }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3rem !important;
    }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3rem !important;
    }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3rem !important;
    }
    .m-xl-auto {
      margin: auto !important;
    }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  
  .text-justify {
    text-align: justify !important;
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .text-left {
    text-align: left !important;
  }
  
  .text-right {
    text-align: right !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
    .text-sm-right {
      text-align: right !important;
    }
    .text-sm-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important;
    }
    .text-md-right {
      text-align: right !important;
    }
    .text-md-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important;
    }
    .text-lg-right {
      text-align: right !important;
    }
    .text-lg-center {
      text-align: center !important;
    }
  }
  
  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important;
    }
    .text-xl-right {
      text-align: right !important;
    }
    .text-xl-center {
      text-align: center !important;
    }
  }
  
  .text-lowercase {
    text-transform: lowercase !important;
  }
  
  .text-uppercase {
    text-transform: uppercase !important;
  }
  
  .text-capitalize {
    text-transform: capitalize !important;
  }
  
  .font-weight-light {
    font-weight: 300 !important;
  }
  
  .font-weight-normal {
    font-weight: 400 !important;
  }
  
  .font-weight-bold {
    font-weight: 700 !important;
  }
  
  .font-italic {
    font-style: italic !important;
  }
  
  .text-white {
    color: #fff !important;
  }
  
  .text-primary {
    color: #007bff !important;
  }
  
  a.text-primary:hover, a.text-primary:focus {
    color: #0062cc !important;
  }
  
  .text-secondary {
    color: #6c757d !important;
  }
  
  a.text-secondary:hover, a.text-secondary:focus {
    color: #545b62 !important;
  }
  
  .text-success {
    color: #28a745 !important;
  }
  
  a.text-success:hover, a.text-success:focus {
    color: #1e7e34 !important;
  }
  
  .text-info {
    color: #17a2b8 !important;
  }
  
  a.text-info:hover, a.text-info:focus {
    color: #117a8b !important;
  }
  
  .text-warning {
    color: #ffc107 !important;
  }
  
  a.text-warning:hover, a.text-warning:focus {
    color: #d39e00 !important;
  }
  
  .text-danger {
    color: #dc3545 !important;
  }
  
  a.text-danger:hover, a.text-danger:focus {
    color: #bd2130 !important;
  }
  
  .text-light {
    color: #f8f9fa !important;
  }
  
  a.text-light:hover, a.text-light:focus {
    color: #dae0e5 !important;
  }
  
  .text-dark {
    color: #343a40 !important;
  }
  
  a.text-dark:hover, a.text-dark:focus {
    color: #1d2124 !important;
  }
  
  .text-muted {
    color: #6c757d !important;
  }
  
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  
  .visible {
    visibility: visible !important;
  }
  
  .invisible {
    visibility: hidden !important;
  }
  
  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      box-shadow: none !important;
    }
    a:not(.btn) {
      text-decoration: underline;
    }
    abbr[title]::after {
      content: " (" attr(title) ")";
    }
    pre {
      white-space: pre-wrap !important;
    }
    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }
    thead {
      display: table-header-group;
    }
    tr,
    img {
      page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }
    h2,
    h3 {
      page-break-after: avoid;
    }
    @page {
      size: a3;
    }
    body {
      min-width: 992px !important;
    }
    .container {
      min-width: 992px !important;
    }
    .navbar {
      display: none;
    }
    .badge {
      border: 1px solid #000;
    }
    .table {
      border-collapse: collapse !important;
    }
    .table td,
    .table th {
      background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #ddd !important;
    }
  }
  /*# sourceMappingURL=bootstrap.css.map */
body {
    font-family: 'Montserrat', sans-serif;
    font-size: .8em;
    line-height: 25px;
    color: #4b4b4b;
    overflow: auto;
}

body > div:nth-child(2){
    padding-top: 50px;
}

h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    font-size: 2.7em;
}

h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 2.5em;
}

h2 > span {
    display: block;
    font-size: .38em;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

p {
    margin: 25px 0;
}

.height-vh {
    height: calc(100vh - 60px);
    min-height: 800px;
}
.min-height-vh {
    min-height: 100vh;
}

.default-background {
    background-image: linear-gradient(to right, #371a90, #2f4cce);
    color: white;
}

.section {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}

.section.supplier{
    padding-bottom: 0;
}

.slanted {
    width: 100%;
    position: absolute;
    bottom: -1px;
}

.slanted.top {
    bottom: inherit;
    top: -1px;
}


/* navbar */

.navbar {
    padding: 0;
    overflow: visible;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: -3px;
}





.navbar .hidden-md-up > div {
    margin-top: 70px;
    box-shadow: 0 8px 8px 2px rgba(0, 0, 0, 0.38);
}
.navbar .hidden-md-up {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}



.navbar-brand {
    height: 10px;
    transform: translateY(25px);
}

.navbar .menu-item a {
    color: #ffffff;
}

.navbar .menu-item {
    padding: 12px 12px;
    padding-bottom: 6px;
    font-size: 1em;
    font-weight: 600;
    height: 100%;
    cursor: pointer;
    font-size: 13px;
    background: #323ebd;
    transition: all .5s ease-in-out;
}
 .navbar.transparant .menu-item,
 .navbar.transparant .button-wrapper {
  background: transparent;
}

.navbar .menu-item:first-child {
  border-bottom-left-radius: 4px;
}

.navbar .menu-item:last-child {
}

.navbar .menu-item.active {
    background: #32baec;
}

/* button */
.button *{
    color: #fff;
}
.button {
    color: white!important;
    background-image: linear-gradient(to right, #50c0d4 0%, #2e7fc1 51%, #50c0d4 100%);
    background-size: 200% auto;
    text-transform: uppercase;
    font-weight: 800;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    text-align: center;
    font-size: 13px;
    letter-spacing: 1.1px;
    transform: scale(1, 0.8);
    -webkit-transform: scale(1, 0.9); /* Safari and Chrome */
    -moz-transform: scale(1, 0.9); /* Firefox */
    -ms-transform: scale(1, 0.9); /* IE 9+ */
    -o-transform: scale(1, 0.9); /* Opera */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: none;
    position: relative;
    padding: 15px 36px 15px 15px;
    min-width: 200px;
    border-radius: 2px;
}

.ReactCollapse--collapse {
  transition: height 350ms;
}

.whitepaper-popup-container {
  position: fixed;
  z-index: 1000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.2);
}

.whitepaper-popup-content {
  background-color: #fff;
  margin: auto;
  padding: 30px;
  width: 550px;
  border-radius: 3px;
}

.custom-field.two input {
  border-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background:
    linear-gradient(90deg,  #606cfe, #606cfe) center bottom/0 0.15em no-repeat,
    linear-gradient(90deg, #75cbfe, #75cbfe) left bottom/100% 0.15em no-repeat,
    linear-gradient(90deg, #fafafa, #fafafa) left bottom/100% no-repeat;
  transition: background-size 0.3s ease;
}

.custom-field {
  position: relative;
  font-size: 14px;
  border-top: 20px solid transparent;
  margin-bottom: 5px;
  --field-padding: 12px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  color: #aaa;
  transition: 
    top 0.3s ease,
    color 0.3s ease,
    font-size 0.3s ease;
}

.custom-field.two input:not(:-ms-input-placeholder) {
  background-size: 100% 0.15em, 100% 0.1em, 100%;
}

.custom-field.two input.dirty,
.custom-field.two input:not(:placeholder-shown),
.custom-field.two input:focus {
  background-size: 100% 0.15em, 100% 0.1em, 100%;
}

.custom-field input:not(:-ms-input-placeholder) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #606cfe;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #606cfe;
}

.button:not(.my-ltx):after {
    content: url(/static/media/arrow-btn.ecabda42.svg);
    font-family: 'Zapf Dingbats';
    color: #fff;
    position: absolute;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    top: 17px;
    right: 16px;
    font-size: 12px;
}
.button:hover:after {
    opacity: 0;
    right: 50px;
}

.button:hover {
    background-position: right center; /* change the direction of the change here */
    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, .15), 0 5px 20px 0 rgba(0, 0, 0, .1);
    padding: 15px;
}

.button.arrow:after {

}

.button.try {
  box-shadow: none;
  background-image: none;
  background: #3080c2;
  font-weight: normal;
  display: inline-block;
}

.button.alt {
    background-image: linear-gradient(to right, #2f7fc4 0%, #7629c3 51%, #2f7fc4 100%);
}

.button.gform_button  {
    padding: 15px;
}
.button.my-ltx {
    background: #e71e7a;
    width: 150px;
    font-size: 1em;
    margin-top: 10px;
    line-height: 15px;
    height: 45px;
    padding: 15px;
    min-width: 150px;

}
.button.start {
    background: #e71e7a;
}

.button.demo {
  background: #FF7A0F;
}

.button.disable {
  background: gray;
}

/* opening */
.opening {
    /*text-shadow: 0 0 10px rgba(0, 0, 0, 0.07);*/
}

.opening h1 span {
    color: #44c3ff;
}

.opening p {
    font-weight: 600;
    letter-spacing: .9px;
}

@media only screen and (min-width: 768px) {
    .opening .container > .row {
        position: relative;
        top: 35vh;
        transform: translateY(-50%);
    }

    .section:not(.opening) .row > div:not(:first-child) {
        /*padding-left: 50px;*/
    }

    .navbar-expand-md .navbar-nav {
        margin-left: auto;
    }

    .navbar-brand{
        margin-left: 20px;
    }


    .hidden-md-up{
        display: none;
    }
}

/* speed */
.floating-img img {
    position: absolute;
    margin-left: 25px;
    width: calc(100% - 150px);
    left: 0;
    top: 0;
}

@media only screen and (max-width: 768px) {

    .floating-img {
        height: 200px;
    }

    .navbar-brand {
        transform: translateX(85%);
    }

    .opening .container > .row {
        margin-top: 100px;
    }
    .hidden-md-down{
        display: none;
    }

    .navbar.transparant { 
      box-shadow: none;
    }

    .navbar {
      background-image: linear-gradient(to right, #371a90, #2f4cce)!important;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
      transition: box-shadow .5s ease-in-out;
    }
    .navbar > .container-fluid 
    {
      padding: 10px
    }
    .navbar .button-wrapper {
      background: transparent;
    }
    .button-wrapper .button.my-ltx {
      margin-top: 0;
    }

    .navbar-brand {
    padding-top: 0.7rem;
    }
}

/* tabs */
.icon-tabs {

}
.collab .col-10 p {
    margin-top: 0;
}
.tabs ul {
    list-style: none;
    display: flex;
    padding: 0;
    text-align: center;

}

.tabs li {
    cursor: pointer;
    transition: all .1s ease-in-out;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.tabs .content > div {
    opacity: 0;
    transition: opacity .15s ease-in-out;
}

.tabs .content > div.active {
    opacity: 1;
}

.icon-tabs li {

    flex: 1 1;
}

.icon-tabs li .img-wrapper {
    height: 30px;
}

.icon-tabs li span {
    display: block;
    padding-top: 20px;
    border-top: 2px solid #dee3e6;
    margin-top: 20px;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
}

.icon-tabs li span::after {
    transition: all .1s ease-in-out;
    bottom: 0;
    content: "";
    display: block;
    margin: 0 auto;
    position: absolute;
    left: calc(50% - 6px);
    top: -6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    border: 3px solid #dee3e6;
}

.icon-tabs .content {
    background: #edf2f5;
    height: 300px;
    position: relative;
}

.icon-tabs .content > div {
position: absolute;
top: 50px;
left: 50px;
right: 50px;
}

.icon-tabs li.active {
    color: #64a7e2;
}

.icon-tabs li.active span::after {
    left: calc(50% - 9px);
    top: -9px;
    width: 18px;
    height: 18px;
    border-color: #64a7e2;
    border-width: 5px;
}

.title {
    font-size: 2em;
    font-weight: bold;
}

.text-tabs ul {
    color: #8480bc;
    margin: 70px 0 30px;
}

.text-tabs li.active {
    color: white;
}

/* prices */
.price-card-wrapper {
 padding: 0
}


.price-card {
    background: white;
    color: #363636;
    text-align: center;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    margin-top: 30px;
    position: relative;
    padding: 30px 10px 0 10px;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
}

.price-card::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 4px;
    background-image: linear-gradient(to right, #75cbfe, #606cfe);
}

.col-md-4:nth-child(2) .price-card::before {
    background-image: linear-gradient(to right, #fedd01, #fe5a01);
}

.col-md-4:nth-child(3) .price-card::before {
    background-image: linear-gradient(to right, #56ff74, #d75cfe);
}

.col-md-4:nth-child(4) .price-card::before {
    background-image: linear-gradient(to right, #fedd01, #fe5a01);
}

.price-card .name {
    font-size: 1.8em;
    /* font-weight: bold; */
    color: #3334b0;
}

.card-premium .name {
  font-size: 1.8em;
  color: #3334b0;
}

.price-card .price {
    margin-top: 20px;
    font-size: 2em;
    font-weight: 600;
}
.default-background .price-card hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 30px ;
  margin-bottom: 30px ;
}

.price-card .note {
  font-size: 1.1em;
}

.card-premium .price {
  margin-top: 20px;
  font-size: 2em;
  font-weight: 600;
}
.default-background .card-premium hr {
border-top: 1px solid rgba(0, 0, 0, 0.1);
margin-top: 20px;
margin-bottom: 10px;
width: 80%;
}

.card-premium .note {
font-size: 1.1em;
}

.lrg-price {
    font-size: 1.5em;
    color: #75cbfe;
    margin-top: 20px;

}

/* new cards style */

.card-container-premium {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.card-premium {
  transition: 0.4s ease-out;
  position: relative;
  left: 0px;
  background: white;
  color: #363636;
  text-align: center;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  position: relative;
  padding: 30px 5px 0 0;
  height: 28rem;
  border-radius: 3px;
  overflow: hidden;
}

.card-premium:not(:first-child) {
    margin-left: -50px;
}

.card-premium:hover {
  transition: 0.4s ease-out;
  min-width: 120px;
}

.card-premium:hover ~ .card-premium {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}


.card-premium::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  background-image: linear-gradient(to right, #75cbfe, #606cfe);
}


@media (max-width: 768px) {
  .card-container-premium {
    flex-direction: column;
  }
  
  .card-premium {
    margin-top: 30px;
  }

  .card-premium:hover {
    transform: none;
  }

  .card-premium:not(:first-child) {
    margin-left: 0;
  }  

  .card-premium:hover ~ .card-premium {
    left: 0;
    transition: none;
  }
}



/* footer */

.footer {
    padding-top: 50px;
    background: rgb(5, 8, 59);
    color: rgba(255, 255, 255, 0.5);

}

.footer * {
    color: rgba(255, 255, 255, 0.5);

}

.footer h4 {
    color: #ffffff;
    font-size: 1.2em;
    font-weight: bold;
}

.footer .bottom-bar {
    background: rgb(3, 4, 48);
}

.footer .bottom-bar .textwidget {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: space-between;
    margin: 0 20px;
}

.footer p {
    margin: 15px 0;
}


/* animations */
.reveal
{
    opacity: 0;
    transition: all .5s ease-in-out;
    transform: translateY(20px);
}

.reveal.reveal_visible{
    opacity: 1;
    transform: none;
}


a:hover
{
    text-decoration: none;
}


/* input */
.ginput_container > * {
    background: #f2f2f2;
    border: 0;
    transition: background-color .2s ease-in-out;
}
.ginput_container > * {
    background: #e6e6e6;
}


.list-style-none {
    list-style-type: none;
    padding-left: 0;

}

.contact-gegevens a{
    color: white;
}
.contact-gegevens img{
    margin: 10px;
    width: 30px;
}

.card-item {
}

.card-item > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 12px;
  font-size: 1.1em;
}


.intro-card {
  background: white;
  border-radius: 2px;
  color: #4b4b4b;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  padding-top: .2rem;
  padding-bottom: .2rem;

}

.default-background hr {
  border-color: rgba(255, 255, 255, 0.2);
}

hr {
  margin-top: .2rem;
  margin-bottom: .2rem;
}


.navbar, .navbar>.container, .navbar>.container-fluid {
  position: -webkit-sticky;
  position: sticky;
  background: none;
}

.app {
  background-image: linear-gradient(90deg,#371a90,#2f4cce);
}


.button-wrapper {
  background:  #323ebd; 
  padding-left: 6px; 
  padding-right: 6px; 
  transition: all .5s ease-in-out;
}

.navbar > .container, .navbar > .container-fluid {
  align-items: initial;
  padding-right: 0;
}


.join-steps .card-item img {
  width: 50px;
  padding: 7px;
}


.lang-switch {
  width: 26px;
  height: 26px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 3px solid white
}

.prices > div{
padding: 20px
}

@media only screen and (min-width: 768px) { 
  .prices > div{
  padding: 0
  }
  .price-pos-1 {
    z-index: 1;
    transform: translateY(-5px)
  }
  .price-pos-2 {
    z-index: 2;
    transform: translateY(-10px)
  }

  
}

/*
----------------------------------------------------------------

Gravity Forms Front End Form Styles
http: //www.gravityforms.com
updated: January 12, 2017 04:02 PM (GMT-05:00) US Eastern Time

Gravity Forms is a Rocketgenius project
copyright 2008-2017 Rocketgenius Inc.
http: //www.rocketgenius.com
this may not be redistributed without the
express written permission of the author.

NOTE: DO NOT EDIT THIS FILE! MAKE ANY MODIFICATIONS IN YOUR
THEME STYLESHEET. THIS FILE IS REPLACED DURING AUTO-UPDATES
AND ANY CHANGES MADE HERE WILL BE OVERWRITTEN.

If you need to make extensive customizations,
copy the contents of this file to your theme
style sheet for editing. Then, go to the form
settings page & set the 'output CSS' option
to no.

----------------------------------------------------------------
*/


/* mobile styles first ​*/

.gform_wrapper {
    margin: 16px 0;
    max-width: 100%;
}

.gform_wrapper form {
    text-align: left;
    max-width: 100%;
    margin: 0 auto;
}

.gform_wrapper *,
.gform_wrapper *:before,
.gform_wrapper *:after {
    box-sizing: border-box !important;
}

.gform_wrapper h1,
.gform_wrapper h2,
.gform_wrapper h3 {
    font-weight: normal;
    border: none;
    background: none;
}

.gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']) {
    font-size: inherit;
    font-family: inherit;
    padding: 5px 4px;
    letter-spacing: normal;
}

.gform_wrapper input[type=image] {
    border: none !important;
    padding: 0 !important;
    width: auto !important;
}

.gform_wrapper textarea {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
    padding: 6px 8px;
    line-height: 1.5;
    resize: none;
}

.gform_wrapper select {
    line-height: 1.5;
}

.gform_wrapper .ginput_container_multiselect select {
    background-image: none !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid inherit;
    outline: none;
}

.gform_wrapper ul.gform_fields {
    margin: 0 !important;
    list-style-type: none;
    display: block;
}

html>body .entry ul,
.gform_wrapper ul {
    text-indent: 0
}

.gform_wrapper li,
.gform_wrapper form li {
    margin-left: 0 !important;
    list-style-type: none !important;
    list-style-image: none !important;
    list-style: none !important;
    overflow: visible;
}

.gform_wrapper ul li.gfield {
    clear: both;
}

.gform_wrapper ul.gfield_radio li,
.gform_wrapper ul.gfield_checkbox li {
    overflow: hidden;
}

.gform_wrapper ul.gform_fields.right_label li.gfield,
.gform_wrapper form ul.gform_fields.right_label li.gfield,
.gform_wrapper ul.gform_fields.left_label li.gfield,
.gform_wrapper form ul.gform_fields.left_label li.gfield {
    margin-bottom: 14px;
}

.gform_wrapper ul.right_label li ul.gfield_radio li,
.gform_wrapper form ul.right_label li ul.gfield_radio li,
.gform_wrapper ul.left_label li ul.gfield_radio li,
.gform_wrapper form ul.left_label li ul.gfield_radio li,
.gform_wrapper ul.right_label li ul.gfield_checkbox li,
.gform_wrapper form ul.right_label li ul.gfield_checkbox li,
.gform_wrapper ul.left_label li ul.gfield_checkbox li,
.gform_wrapper form ul.left_label li ul.gfield_checkbox li {
    margin-bottom: 10px;
}

.gform_wrapper ul li:before,
.gform_wrapper ul li:after,
.gform_wrapper ul.gform_fields {
    padding: 0;
    margin: 0;
    overflow: visible;
}

.gform_wrapper select {
    font-size: inherit;
    font-family: inherit;
    letter-spacing: normal;
}

.gform_wrapper select option {
    padding: 2px;
    display: block;
}

.gform_wrapper .inline {
    display: inline !important;
}

.gform_wrapper .gform_heading {
    width: 100%;
    margin-bottom: 18px;
}

.gform_wrapper .clear-multi{
    display: flex;
}

/* date ​fields */

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_year {
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gfield_date_day,
.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_year {
    width: 33.333%;
    flex-direction: row;
}

@media only screen and (min-width: 321px) {

    .gform_wrapper .gfield_date_day,
    .gform_wrapper .gfield_date_month {
        max-width: 4rem;
    }

    .gform_wrapper .gfield_date_year,
    .gform_wrapper .gfield_time_ampm {
        max-width: calc(4rem + .5rem);
    }

}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
    vertical-align: top;
    flex-direction: row;
}

.gform_wrapper .gfield_date_dropdown_month,
.gform_wrapper .gfield_date_dropdown_day,
.gform_wrapper .gfield_date_dropdown_year {
    margin-right: .5rem;
}

.gform_wrapper .gfield_date_month,
.gform_wrapper .gfield_date_day {
    width: 4rem;
    margin-right: .5rem;
}

.gform_wrapper .gfield_date_month input[type="number"],
.gform_wrapper .gfield_date_day input[type="number"] {
    width: calc(3rem + 8px) !important;
}

.gform_wrapper .gfield_date_year input[type="number"] {
    width: calc(4rem + 8px) !important;
}

.gform_wrapper .gfield_date_year {
    width: 5rem;
}

.gform_wrapper .gfield_date_month input,
.gform_wrapper .gfield_date_day input {
    width: calc(3rem + 8px);
}

.gform_wrapper .gfield_date_year input {
    width: 4rem;
}

.gform_wrapper .field_sublabel_above div[class*="gfield_date_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_date_"].ginput_container label {
    width: 3rem;
    text-align: center;
}

.gform_wrapper .field_sublabel_above div.gfield_date_year.ginput_container label,
.gform_wrapper .field_sublabel_below div.gfield_date_year.ginput_container label {
    width: 4rem;
}

/* time ​fields */

.gform_wrapper .gfield_time_hour,
.gform_wrapper .gfield_time_minute,
.gform_wrapper .gfield_time_ampm {
    width: 33.333%;
    flex-direction: row;
}

@media only screen and (min-width: 321px) {

    .gform_wrapper .gfield_time_hour,
    .gform_wrapper .gfield_time_minute {
        max-width: 4rem;
    }

    .gform_wrapper .gfield_time_hour,
    .gform_wrapper .gfield_time_ampm {
        max-width: calc(4rem + .5rem);
    }

    .gform_wrapper .gfield_time_hour {
        max-width: calc(4rem + .5rem);
    }

}

.gform_wrapper ul.gform_fields:not(.top_label) .ginput_container_time span {
    float: left;
}

.gform_wrapper .gfield_time_hour i {
    font-style: normal !important;
    font-family: sans-serif !important;
    width: 10px;
    text-align: center;
    float: right;
    margin-top: 9%;
}

.gform_wrapper .gfield_time_minute {
    margin-right: .5rem;
}

.gform_wrapper .gfield_time_hour {
    margin-right: .25rem;
}

.gform_wrapper .gfield_time_ampm {
    vertical-align: top;
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gfield_time_ampm select {
    min-width: calc(2rem + 25px);
    width: calc(3rem + 25px);
}

.gform_wrapper .gfield_time_hour input,
.gform_wrapper .gfield_time_minute input {
    width: calc(3rem + 8px);
}

.gform_wrapper .gfield_time_hour input[type="number"],
.gform_wrapper .gfield_time_minute input[type="number"] {
    width: calc(3rem + 8px) !important;
}

.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label {
    width: 3rem;
    text-align: center;
}

.gform_wrapper .field_hover {
    border: 1px dashed #2175A9;
    cursor: pointer;
}

.gform_wrapper .field_selected {
    background-color: #DFEFFF;
    border: 1px solid #C2D7EF;
}

.gform_wrapper .field_name_first,
.gform_wrapper .field_name_middle,
.gform_wrapper .field_name_last {
    width: 49.5%;
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: top;
}
.gform_wrapper .ginput_complex.ginput_container {
    overflow: visible;
    width: 100%;
}

.gform_wrapper label.gfield_label {
    font-weight: 700;
    font-size: inherit;
}

.gform_wrapper .top_label .gfield_label {
    display: -moz-inline-stack;
    display: inline-block;
    line-height: 1.3;
    clear: both;
}

.gform_wrapper .ginput_complex input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']),
.gform_wrapper .ginput_complex  .ginput_left input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']),
.gform_wrapper .ginput_complex .ginput_right input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='image']):not([type='file']) {
    width: 100%;
}

.gform_wrapper .ginput_complex .ginput_right select,
.gform_wrapper .ginput_complex select {
    width: 100%;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_date_"].ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_date_"].ginput_container label {
    display: block;
    font-size: .813em;
    letter-spacing: .5pt;
    white-space: nowrap;
}

.gform_wrapper ul.gfield_radio li label,
.gform_wrapper ul.gfield_checkbox li label {
    display: -moz-inline-stack;
    display: inline-block;
    letter-spacing: inherit;
    vertical-align: middle;
    cursor: pointer;
}

.gform_wrapper .field_sublabel_above .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_above div[class*="gfield_time_"].ginput_container label {
    margin: 9px 0 1px 1px;
}

.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_name label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_email label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_password label,
.gform_wrapper ul.gform_fields:not(.top_label) .field_sublabel_above .ginput_container.ginput_container_time label {
    margin-top: 0;
}

.gform_wrapper .field_sublabel_below .ginput_complex.ginput_container label,
.gform_wrapper .field_sublabel_below div[class*="gfield_time_"].ginput_container label {
    margin: 1px 0 9px 1px;
}

.gform_wrapper .top_label .field_sublabel_above label.gfield_label + .ginput_complex.ginput_container,
.gform_wrapper .top_label .field_sublabel_above label.gfield_label + div[class*="gfield_time_"].ginput_container {
    margin-top: 0;
}

body .gform_wrapper .top_label div.ginput_container {
    margin-top: 8px;
}

body.gform_wrapper div.clear-multi {
    display: -moz-inline-stack;
    display: inline-block;
}

body .gform_wrapper ul li.field_description_below div.ginput_container_radio,
body .gform_wrapper ul li.field_description_below div.ginput_container_checkbox {
    margin-top: 16px;
    margin-bottom: 0;
}

.gform_wrapper .hidden_label .gfield_label {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.gfield.right_label,
.gfield.left_label {
    padding-top: 10px;
}

.gform_wrapper li.hidden_label input {
    margin-top: 12px;
}

.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container input[type=text],
.gform_wrapper .field_sublabel_hidden_label .ginput_complex.ginput_container select {
    margin-bottom: 12px;
}

.gform_wrapper label.screen-reader-text, .gform_wrapper label.hidden_sub_label {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
 }

.gform_wrapper input.small,
.gform_wrapper select.small,
.gform_wrapper input.medium,
.gform_wrapper select.medium,
.gform_wrapper input.large,
.gform_wrapper select.large,
.gform_wrapper input.large,
.gform_wrapper select.large {
    width: 100%
}

.gform_wrapper input.datepicker_with_icon.small,
.gform_wrapper input.datepicker_with_icon.medium,
.gform_wrapper input.datepicker_with_icon.large {
    width: calc(100% - 24px);
}

.gform_wrapper .gfield_error input.small,
.gform_wrapper .gfield_error select.small,
.gform_wrapper .gfield_error input.medium,
.gform_wrapper .gfield_error select.medium,
.gform_wrapper .gfield_error input.large,
.gform_wrapper .gfield_error select.large,
.gform_wrapper .gfield_error input.large,
.gform_wrapper .gfield_error select.large {
    width: 100%;
}

.gform_wrapper .gfield_error input.datepicker_with_icon.small,
.gform_wrapper .gfield_error input.datepicker_with_icon.medium,
.gform_wrapper .gfield_error input.datepicker_with_icon.large {
    width: calc(100% - 32px);
}

.gform_wrapper textarea.small {
    height: 80px;
    width: 100%;
}

.gform_wrapper textarea.medium {
    height: 120px;
    width: 100%;
}

.gform_wrapper textarea.large {
    height: 160px;
    width: 100%;
}

.gform_wrapper h2.gsection_title {
    margin: 0 !important;
    padding: 0 !important;
    letter-spacing: normal !important;
}

.gform_wrapper .gsection .gfield_label,
.gform_wrapper h2.gsection_title,
.gform_wrapper h3.gform_title {
    font-weight: 700;
    font-size: 1.25em;
}

.gform_wrapper h3.gform_title {
    letter-spacing: normal !important;
    margin: 10px 0 6px 0;
}

.gform_wrapper li.gfield.field_description_below + li.gsection {
    margin-top: 24px !important;
}

.gform_wrapper span.gform_description {
    font-weight: 400;
    display: block;
    width: calc(100% - 16px);
    margin-bottom: 16px;
}

.gform_wrapper .gsection {
    border-bottom: 1px solid #CCC;
    padding: 0 16px 8px 0;
    margin: 28px 0 28px 0;
    clear: both;
}

.gform_wrapper ul.gfield_checkbox li input[type=checkbox],
.gform_wrapper ul.gfield_radio li input[type=radio] {
    width: auto !important;
}

.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
    position: relative;
}

.gform_wrapper ul.gfield_checkbox,
.gform_wrapper ul.gfield_radio {
    margin: 6px 0;
    padding: 0;
}

.gform_wrapper .gfield_checkbox li label,
.gform_wrapper .gfield_radio li label {
    display: -moz-inline-stack;
    display: inline-block;
    padding: 0;
    width: auto;
    line-height: 1.5;
    font-size: .875em;
    vertical-align: middle;
    max-width: 85%;
    white-space: normal;
}

.gform_wrapper .gfield_checkbox li label {
    margin: 0;
}

.gform_wrapper .gfield_radio li label {
    margin: 2px 0 0 4px;
}

.gform_wrapper .gfield_checkbox li input[type=checkbox],
.gform_wrapper .gfield_radio li input[type=radio],
.gform_wrapper .gfield_checkbox li input {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
    margin-left: 1px;
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description,
.gform_wrapper .instruction {
    font-size: .813em;
    line-height: inherit;
    clear: both;
    font-family: inherit;
    letter-spacing: normal;
}

.gform_wrapper .description,
.gform_wrapper .gfield_description,
.gform_wrapper .gsection_description {
    padding: 0 16px 0 0;
}

.gform_wrapper .field_description_below .gfield_description {
    padding-top: 16px;
}

.gform_wrapper .field_sublabel_above .description,
.gform_wrapper .field_sublabel_above .gfield_description,
.gform_wrapper .field_sublabel_above .gsection_description {
    margin-top: 9px;
}

.gform_wrapper .top_label .gsection_description {
    width: 100%;
    margin-bottom: 9px;
}

.gform_wrapper .gfield_description {
    width: 100%;
}

.gform_wrapper .description_above .gfield_description {
    padding: 0 0 10px 0;
}

.gfield_date_year+.gfield_description {
    padding: 0;
}

.gform_wrapper .gfield_required {
    color: #790000;
    margin-left: 4px;
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    background-color: rgba(255,223,224,.25);
    margin-bottom: 6px !important;
    border-top: 1px solid #C89797;
    border-bottom: 1px solid #C89797;
    padding-bottom: 6px;
    padding-top: 8px;
    box-sizing: border-box;
}

.gform_wrapper li.gfield.gfield_creditcard_warning {
    padding: 16px 16px 14px 16px;
    border-top: 2px solid #790000;
    border-bottom: 4px solid #790000;
    background-color: rgba(255, 223, 224, 0.25);
    width: calc(100% - 16px);
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning {
    padding: 16px 16px 14px 16px;
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message {
    font-size: .875em;
    font-weight: 700;
    font-family: inherit;
    min-height: 25px;
    color: #790000;
    max-width: 100%;
    position: relative;
}

.gform_wrapper li.gfield.gfield_creditcard_warning div.gfield_creditcard_warning_message span {
    display: block;
    padding: 0 0 14px 24px;
    margin-bottom: 16px;
    line-height: 1.5;
    letter-spacing: .1pt;
    border-bottom: 1px solid #790000;
    text-transform: uppercase;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSItMTA2NyAyODY1IDI0IDMyIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IC0xMDY3IDI4NjUgMjQgMzI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiM3OTAwMDA7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0tMTA1MywyODY1Yy01LjUsMC0xMCw0LjUtMTAsMTBoNGMwLTMuMywyLjctNiw2LTZjMy4zLDAsNiwyLjcsNiw2djJoLTIwdjE0YzAsMy4zLDIuNyw2LDYsNmgxMg0KCWMzLjMsMCw2LTIuNyw2LTZ2LTE2Qy0xMDQzLDI4NjkuNS0xMDQ3LjUsMjg2NS0xMDUzLDI4NjV6IE0tMTA0OSwyODkzaC0xMmMtMS4xLDAtMi0wLjktMi0ydi0xMGgxNnYxMA0KCUMtMTA0NywyODkyLjEtMTA0Ny45LDI4OTMtMTA0OSwyODkzeiBNLTEwNTMsMjg4N2MwLDEuMS0wLjksMi0yLDJzLTItMC45LTItMmMwLTEuMSwwLjktMiwyLTJTLTEwNTMsMjg4NS45LTEwNTMsMjg4N3oiLz4NCjwvc3ZnPg0K);
    background-size: 1em auto;
    background-repeat: no-repeat;
    background-position: left center;
}

.gform_wrapper li.gfield.gfield_error.gfield_creditcard_warning div.gfield_creditcard_warning_message {
    padding: 16px 16px 14px 16px;
}

li.gfield + li.gfield.gfield_creditcard_warning {
    margin-top: 16px !important;
}

.gform_wrapper .top_label .gfield_error {
    width: calc(100% - 2px);
}

.gform_wrapper .top_label .gfield_error input.large,
.gform_wrapper .top_label .gfield_error select.large,
.gform_wrapper .top_label .gfield_error textarea.textarea {
    width: 100%;
}

.gform_wrapper .right_label .gfield_error input.large,
.gform_wrapper .right_label .gfield_error select.large,
.gform_wrapper .right_label .gfield_error textarea.textarea,
.gform_wrapper .left_label .gfield_error input.large,
.gform_wrapper .left_label .gfield_error select.large,
.gform_wrapper .left_label .gfield_error textarea.textarea {
    width: 70%;
}

.gform_wrapper .gfield_error .gfield_label {
    color: #790000;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required {
    padding-right: 0;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_minute.ginput_container,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_time_ampm.ginput_container {
    margin-left: 0;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
    max-width: 100%;
    padding-right: 16px;
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container {
    margin-top: 12px
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required fieldset legend.gfield_label {
    position: relative;
    top: 12px;
}

.gform_wrapper div.validation_error {
    color: #790000;
    font-size: 1em;
    font-weight: 700;
    margin-bottom: 25px;
    border-top: 2px solid #790000;
    border-bottom: 2px solid #790000;
    padding: 16px 0 16px 0;
    clear: both;
    width: 100%;
    text-align: center;
}

.gform_wrapper ol.validation_list {
    clear: both;
    width: 100%;
    margin-bottom: 25px;
    color: #790000;
}

.gform_wrapper ol.validation_list li {
    list-style-type: decimal !important;
}

.gform_wrapper ol.validation_list li a {
    color: #790000;
}

.gform_wrapper ol.validation_list:after {
    content: "";
}

.gform_wrapper div#error {
    margin-bottom: 25px;
}

.gform_wrapper table.gfield_list tbody tr td.gfield_list_icons {
    vertical-align: middle !important;
}

.gform_wrapper li.gfield fieldset {
    margin: 0px;
    padding: 0px;
    display: block;
    position: relative;
    width: 100%;
}

.gform_wrapper li.gfield fieldset > * { width: auto }
.gform_wrapper li.gfield fieldset legend.gfield_label {
    width: 100%;
    line-height: 32px;
}

div.gf_page_steps+div.validation_error {
    margin-top: 16px;
}

.gform_wrapper div.gfield_description.validation_error {
    color: #790000;
    font-weight: 700;
    font-size: .875em;
    line-height: 1.20;
    margin-bottom: 16px;
}

.gform_wrapper .validation_message {
    color: #790000;
    font-weight: 700;
    letter-spacing: normal;
}

.gform_wrapper li.gfield_error input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
.gform_wrapper li.gfield_error textarea {
    border: 1px solid #790000;
}

.gform_wrapper li.gfield_error div.ginput_complex.ginput_container label,
.gform_wrapper li.gfield_error ul.gfield_checkbox,
.gform_wrapper li.gfield_error ul.gfield_radio {
    color: #790000;
}

.gform_wrapper .gform_footer {
    padding: 16px 0 10px 0;
    margin: 16px 0 0 0;
    clear: both;
    width: 100%;
}

.gform_wrapper .gform_footer input.button,
.gform_wrapper .gform_page_footer input.button,
.gform_wrapper .gform_footer input[type=submit],
.gform_wrapper .gform_page_footer input[type=submit] {
    font-size: 1em;
    width: 100%;
    margin: 0 0 16px 0;
}

.gform_wrapper .gform_footer a.gform_save_link,
.gform_wrapper .gform_page_footer a.gform_save_link {
    font-size: 1em;
    display: block;
    text-align: center;
}

.gform_wrapper .gform_footer input[type=image] {
    padding: 0;
    width: auto !important;
    background: none !important;
    border: none !important;
    margin: 0 auto 16px auto !important;
    display: block;
    max-width: 100%;
}

.gform_wrapper .ginput_complex .ginput_left,
.gform_wrapper .ginput_complex .ginput_right,
.gform_wrapper .ginput_complex .ginput_full {
    min-height: 45px;
}

.gform_wrapper .ginput_complex .ginput_full input[type=text],
.gform_wrapper .ginput_complex .ginput_full input[type=url],
.gform_wrapper .ginput_complex .ginput_full input[type=email],
.gform_wrapper .ginput_complex .ginput_full input[type=tel],
.gform_wrapper .ginput_complex .ginput_full input[type=number],
.gform_wrapper .ginput_complex .ginput_full input[type=password] {
    width: 100%;
}

.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=text],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=url],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=email],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=tel],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=number],
.gform_wrapper .gfield_error .ginput_complex .ginput_full input[type=password],
.gform_wrapper .gfield_error .ginput_complex .ginput_full select {
    width: 100%;
}

.gform_wrapper .gfield_checkbox li,
.gform_wrapper .gfield_radio li {
    margin: 0 0 16px 0;
    line-height: 1.2;
}

.gform_wrapper ul.gfield_radio li input[type="radio"]:checked+label,
.gform_wrapper ul.gfield_checkbox li input[type="checkbox"]:checked+label {
    font-weight: 700;
}

.gform_wrapper input.datepicker.datepicker_with_icon {
    margin-right: 4px !important;
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper input[type=hidden],
.gform_wrapper input.gform_hidden,
.gform_wrapper .gform_hidden,
.gform_wrapper .gf_hidden {
    display: none !important;
    max-height: 1px !important;
    overflow: hidden;
}

.gform_wrapper .gfield_visibility_hidden,
.gform_wrapper .gf_invisible {
    visibility: hidden;
    position: absolute;
    left: -9999px;
}

.gform_wrapper .ginput_full br,
.gform_wrapper .ginput_left br,
.gform_wrapper .ginput_right br {
    display: none !important;
}

.gform_wrapper ul.gfield_checkbox li,
.gform_wrapper ul.gfield_radio li {
    padding: 0 !important;
}

.gform_wrapper ul.gfield_radio li input+input {
    margin-left: 4px;
}

.gform_wrapper ul.gfield_radio li input[value=gf_other_choice] {
    margin-right: 6px;
    margin-top: 4px;
}

.gform_wrapper .gfield_description + .gform_wrapper .gfield_description.validation_message {
    margin-top: 6px;
}

.gform_wrapper .ginput_container.ginput_list + .gfield_description.validation_message {
    margin-top: 0;
}

.gform_wrapper li.gfield_html {
    max-width: 100%;
}

.gform_wrapper .gfield_html.gfield_no_follows_desc {
    margin-top: 10px;
}

body .gform_wrapper ul li.gfield.gfield_html img {
    max-width: 100%  !important;
}

.gform_wrapper .gform_ajax_spinner {
    padding-left: 10px;
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper .gform_body ul.gform_fields li.gfield.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
    display: none !important;
    position: absolute !important;
    left: -9000px;
}

.gform_wrapper .gfield_captcha_input_container {
    padding-top: 3px;
}

.gform_wrapper .simple_captcha_small input {
    width: 64px;
}

.gform_wrapper .simple_captcha_medium input {
    width: 96px;
}

.gform_wrapper .simple_captcha_large input {
    width: 128px;
}

.gform_wrapper .gform_wrapper .left_label .simple_captcha_small,
.gform_wrapper .right_label .simple_captcha_small,
.gform_wrapper .left_label .simple_captcha_medium,
.gform_wrapper .right_label .simple_captcha_medium,
.gform_wrapper .left_label .simple_captcha_large,
.gform_wrapper .right_label .simple_captcha_large {
    margin-left: 32%;
}

.gform_wrapper .gfield_captcha_container img.gfield_captcha {
    border: none !important;
    background: none !important;
    float: none !important;
    margin: 0 !important;
    padding: 0 !important;
}

.gform_wrapper .math_small input {
    width: 70px;
}

.gform_wrapper .math_medium input {
    width: 510px;
}

.gform_wrapper .math_large input {
    width: 612px;
}

.gform_wrapper .left_label .math_small,
.gform_wrapper .right_label .math_small,
.gform_wrapper .left_label .math_medium,
.gform_wrapper .right_label .math_medium,
.gform_wrapper .left_label .math_large,
.gform_wrapper .right_label .math_large {
    margin-left: 32%;
}


.gform_wrapper div.charleft {
    font-size: .688em;
    margin-top: 4px;
    color: #B7B7B7;
    width: 92% !important;
    white-space: nowrap !important;
}

.gform_wrapper div.charleft[style] {
    width: 92% !important;
}

.gform_wrapper .left_label div.charleft,
.gform_wrapper .right_label div.charleft {
    margin-left: 32%;
}

.gform_wrapper div.charleft.ginput_counter_tinymce{
    border:  1px solid #eee;
    border-width: 1px 0 0;
    padding: 4px 9px;
    width: 100% !important;
}

.gform_wrapper div.charleft.warningTextareaInfo {
    color: #A1A1A1;
}

.gform_wrapper div.charleft.ginput_counter_error {
    color: red;
}

.gform_wrapper li.gf_hide_charleft div.charleft {
    display: none !important;
}

.gf_submission_limit_message {
    color: #790000;
    font-size: 1.375em;
}

.gform_wrapper .ginput_price {
    filter: alpha(opacity=70);
    -moz-opacity: .7;
    -khtml-opacity: .7;
    opacity: .7;
}

.gform_wrapper span.ginput_total {
    color: #060;
    font-size: inherit;
}

.gform_wrapper .top_label span.ginput_total {
    margin: 8px 0;
}

.gform_wrapper span.ginput_product_price_label {
    margin-right: 2px;
}

.gform_wrapper span.ginput_product_price {
    color: #900;
}

.gform_wrapper span.ginput_quantity_label {
    margin-left: 10px;
    margin-right: 2px;
}

.gform_wrapper input.ginput_quantity[type=text] {
    width: 3rem;
}

.gform_wrapper input.ginput_quantity[type=number] {
    width: 4rem;
}

.gform_wrapper .gform_page_footer {
    margin: 14px 0;
    width: 100%;
    border-top: 1px dotted #CCC;
    padding: 16px 0 0 0;
    clear: both;
}

.gform_wrapper .gform_page_footer.left_label,
.gform_wrapper .gform_page_footer.right_label {
    padding: 16px 0 0 29%;
}

.gform_wrapper .gform_page_footer .button.gform_previous_button, .gform_wrapper .gform_page_footer .button.gform_next_button {
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gform_page_footer .button.gform_previous_button {
    margin-bottom: 8px;
}

.gform_wrapper .gf_progressbar_wrapper {
    clear: both;
    width: 100%;
    margin: 0 0 16px 0;
    padding: 0 0 16px 0;
}

.gform_wrapper .gf_progressbar_wrapper h3.gf_progressbar_title {
    font-size: .813em;
    line-height: 1 !important;
    margin: 0 0 8px 12px !important;
    padding: 0 !important;
    clear: both;
    filter: alpha(opacity=60);
    -moz-opacity: .6;
    -khtml-opacity: .6;
    opacity: .6;
}

.gform_wrapper .gf_progressbar {
    padding: 10px;
    position: relative;
    background-color:rgba(0, 0, 0, 0.1);
    border-radius: 25px;
    box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,0.05);
    width: 100%;
}

.gform_wrapper .gf_progressbar_percentage {
    height: 24px;
    text-align: right;
    font-family: helvetica,arial,sans-serif;
    font-size: .813em !important;
    text-shadow: 0 1px 1px rgba(0,0,0,.50);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    z-index:999;
    vertical-align: middle;
    background: repeating-linear-gradient( -45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 0.1) 10px, rgba(0, 0, 0, 0.1) 20px),linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
}

.gform_wrapper .gf_progressbar:after {
    content:"";
    display: block;
    width: 100%;
    z-index:990;
    height: 24px;
    margin-top:-24px;
    background-color:rgba(0, 0, 0, 0.1);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: inset 0px 0px 2px 2px rgba(0,0,0,0.05);
}

.gform_wrapper .gf_progressbar_percentage.percentbar_100 {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.gform_wrapper .gf_progressbar_percentage span {
    display: block;
    width: auto;
    float: right;
    margin-right: 5px;
    margin-left: 5px;
    line-height: 1.8;
}

.gform_wrapper .gf_progressbar_percentage.percentbar_0 span {
    color: #959595;
    text-shadow: none;
    float: none !important;
    margin-left: 12px;
    word-wrap: normal;
}

.gform_wrapper .percentbar_blue {
    background-color: #036493;
    color: #FFF;
}

.gform_wrapper .percentbar_gray {
    background-color: #7C7C7C ;
    color: #FFF;
}

.gform_wrapper .percentbar_green {
    background-color: #88B831;
    color: #FFF;
}

.gform_wrapper .percentbar_orange {
    background-color: #FF7120;
    color: #FFF;
}

.gform_wrapper .percentbar_red {
    background-color: #FF2A1A;
    color: #FFF;
}

.gform_wrapper .gf_page_steps {
    width: 100%;
    margin: 0 0 8px 0;
    padding: 0 0 4px 0;
    border-bottom: 1px dotted #CCC;
}

.gform_wrapper .gf_step {
    width: 100%;
    margin: 24px 24px 24px 0;
    font-size: .875em;
    height: 14px;
    line-height: 1.25 !important;
    filter: alpha(opacity=20);
    -moz-opacity: .2;
    -khtml-opacity: .2;
    opacity: .2;
    font-family: inherit;
}

.gform_wrapper .gf_step span.gf_step_number {
    font-size: 1.25em;
    font-family: arial,sans-serif;
    margin-right: 4px;
}

.gform_wrapper .gf_step span.gf_step_number,
.gform_wrapper .gf_step span.gf_step_label {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
}

.gform_wrapper .gf_step.gf_step_active {
    filter: alpha(opacity=100);
    -moz-opacity: 1.0;
    -khtml-opacity: 1.0;
    opacity: 1.0;
}

.gform_wrapper .gf_step_clear {
    display: block;
    clear: both;
    height: 1px;
    overflow: hidden;
}

.gform_wrapper .gfield_password_strength {
    border: 1px solid #DDD;
    margin-top: 18px;
    margin-bottom: 18px;
    line-height: 1.8;
    padding: 10px 5px;
    text-align: center;
    background-color: #EEE;
    border-bottom: 3px solid #DDD;
}

.gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
    margin-right: 16px;
}

.gform_wrapper ul.left_label li.gfield .gfield_password_strength,
.gform_wrapper ul.right_label li.gfield .gfield_password_strength {
    margin-top: 18px;
    margin-bottom: 18px;
    width: 100%;
}

.gform_wrapper .gfield_password_strength.bad {
    background-color: #FFB78C;
    border-color: #FF853C;
    border-bottom: 3px solid #FF853C;
}

.gform_wrapper .gfield_password_strength.good {
    background-color: #FFEC8b;
    border-color: #FC0;
    border-bottom: 3px solid #FC0;
}

.gform_wrapper .gfield_password_strength.short,
.gform_wrapper .gfield_password_strength.mismatch {
    background-color: #FFA0A0;
    border-color: #f04040;
    border-bottom: 3px solid #f04040;
}

.gform_wrapper .gfield_password_strength.strong {
    background-color: #C3FF88;
    border-color: #8DFF1C;
    border-bottom: 3px solid #8DFF1C;
}

.gform_wrapper table.gfield_list,
.gform_wrapper table.gfield_list caption,
.gform_wrapper table.gfield_list tbody,
.gform_wrapper table.gfield_list tfoot,
.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr,
.gform_wrapper table.gfield_list th,
.gform_wrapper table.gfield_list td {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

.gform_wrapper table.gfield_list {
    border-spacing: 0;
    border-collapse: collapse;
}

/* fix for twentyfifteen theme table layout issue */

.gform_wrapper table.gfield_list {
    table-layout: auto !important ;
}

.gform_wrapper table.gfield_list thead th {
    font-weight: 700;
    text-align: left;
}

.gform_wrapper table.gfield_list colgroup {
    width: 100%;
    display: block;
}

.gform_wrapper li.gfield.gfield_error table.gfield_list thead th {
    color: #790000;
}

.gform_wrapper table.gfield_list thead,
.gform_wrapper table.gfield_list tr {
    padding: 0;
    margin: 0;
}

.gform_wrapper table.gfield_list th,
.gform_wrapper table.gfield_list td {
    padding: 4px 0 4px 0;
}

.gform_wrapper ul.gform_fields:not(.top_label) table.gfield_list th {
    padding: 0 0 4px 0;
}

.gform_wrapper table.gfield_list th + th,
.gform_wrapper table.gfield_list td + td {
    padding: 0 0 0 16px;
}

.gform_wrapper .gfield_list {
    width: 100%;
}

.gform_wrapper .gfield_list td.gfield_list_cell input {
    width: 100%;
}

.gfield_icon_disabled {
    cursor: default !important;
    filter: alpha(opacity=60);
    -moz-opacity: .6;
    -khtml-opacity: .6;
    opacity: .6;
}

.gform_wrapper table.gfield_list tr td.gfield_list_icons {
    width: 48px !important;
    padding: 0 0 0 4px  !important;
    box-sizing: border-box;
}

.gform_wrapper table.gfield_list td.gfield_list_icons img {
    display: -moz-inline-stack;
    display: inline-block;
    vertical-align: middle;
}

.gform_wrapper select.chosen-select {
    visibility: hidden;
    height: 28px !important;
    min-height: 28px !important;
}

.gform_wrapper .chosen-container {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: inherit;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
}

.gform_wrapper .chosen-container,
.gform_wrapper .chosen-container * {
    box-sizing: border-box;
}

.gform_wrapper .chosen-container.chosen-container-multi .chosen-choices {
    min-height: 28px !important;
}

.gform_wrapper .ginput_container.ginput_container_multiselect .chosen-container.chosen-container-multi ul.chosen-choices li.search-field input.default {
    height: 24px;
}

.gform_wrapper .chosen-container .chosen-drop {
    position: absolute;
    top: 100%;
    left: -9999px;
    z-index: 1010;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #AAA;
    border-top: 0;
    background: #FFF;
    box-shadow: 0 4px 5px rgba(0, 0, 0, .15);
}

.gform_wrapper .chosen-container.chosen-with-drop .chosen-drop {
    left: 0;
}
.gform_wrapper .chosen-container a {
    cursor: pointer;
}
.gform_wrapper .chosen-container-single .chosen-single {
    position: relative;
    display: block;
    overflow: hidden;
    padding: 0 0 0 8px;
    height: 23px;
    border: 1px solid #AAA;
    border-radius: 5px;
    background-color: #FFF;
    background: linear-gradient(to bottom, #FFF 20%, #F6F6F6 50%, #EEE 52%, #F4F4F4 100%);
    background-clip: padding-box;
    box-shadow: 0 0 3px white inset, 0 1px 1px rgba(0, 0, 0, .1);
    color: #444;
    text-decoration: none;
    white-space: nowrap;
    line-height: 24px;
}

.gform_wrapper .chosen-container-single .chosen-default {
    color: #999;
}

.gform_wrapper .chosen-container-single .chosen-single span {
    display: block;
    overflow: hidden;
    margin-right: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.gform_wrapper .chosen-container-single .chosen-single-with-deselect span {
    margin-right: 38px;
}

.gform_wrapper .chosen-container-single .chosen-single abbr {
    position: absolute;
    top: 6px;
    right: 26px;
    display: block;
    width: 12px;
    height: 12px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAlCAYAAAAN8srVAAACTUlEQVR42u3Wv2sTcRiA8VPBxUKwEAxU3NxPIoFAl1bIkkmwYKAKRbqbRSWQCGJ+rMUibjo4FARBl0AgUIh/QXFxFIpKJHAQKA56r0/hDbyEK5VrDH2hBx+ud+Ga9+G+uSQQkVOv0+lMZNBFHoFRwABZb0F9CCITVdRjQd9b0CoOTNSGiRkidBWkljGGINb9CCECd0FqE7GJqkxeMxccK8UbJzppUPGIO5SfR9DCjINsTIR1RDbKXvAakuB9yqAsvuLaDIN6Jqag5/IaIxjYCxaxDzFGyKUMegdBb4ZBGfQmMUaIXeSmLyhDjHspl9wdiPHgJEGlUumf2UGml96HlJ+hRQwhRoSleQfZgfawlDJoB5KgO4OgDLrIT4UUMEA2xdNpro/t6aA+BJGJKuqxoJ9ikLmzQas4MFEbJmYIHz99GNRaxhiCWPcjhAjcBalNxCaqgsBrUPGIO5T3GGRjIqwjslHegnompqDn8hojGHgLyqA3iTFC7CLnLOh4Z0Gn3FnQf2O3ZrN5iZ9aVw81Go3zQfLmI4iIx/gBUXvtdnvNXZDGbEMI2Gf/BFsQPXffVRADr+jgn1hylwPdOL6Bn7w2brVaV9wEMfALBheGDu3QGvVQ79RtT0FvGDyu1WoXE4JWNKjiack916HXEoJecT7GLTdBLLXrDPwbEX+Xq9XqucPHNzFVzv3B93q9fsHbU+4uhAhh/wXfIMaWqyBdXjfxluE/63fQM/Yt8/je9hQ0vdnQpybqJRZcB2nUI4J+QVB2H6RRHzUoTPo/fwGr9gNcek8bXAAAAABJRU5ErkJggg==) -42px 1px no-repeat;
    font-size: 1px;
}

.gform_wrapper .chosen-container-single .chosen-single abbr:hover {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-single.chosen-disabled .chosen-single abbr:hover {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-single .chosen-single div {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 18px;
    height: 100%;
}

.gform_wrapper .chosen-container-single .chosen-single div b {
    display: block;
    width: 100%;
    height: 100%;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAlCAYAAAAN8srVAAACTUlEQVR42u3Wv2sTcRiA8VPBxUKwEAxU3NxPIoFAl1bIkkmwYKAKRbqbRSWQCGJ+rMUibjo4FARBl0AgUIh/QXFxFIpKJHAQKA56r0/hDbyEK5VrDH2hBx+ud+Ga9+G+uSQQkVOv0+lMZNBFHoFRwABZb0F9CCITVdRjQd9b0CoOTNSGiRkidBWkljGGINb9CCECd0FqE7GJqkxeMxccK8UbJzppUPGIO5SfR9DCjINsTIR1RDbKXvAakuB9yqAsvuLaDIN6Jqag5/IaIxjYCxaxDzFGyKUMegdBb4ZBGfQmMUaIXeSmLyhDjHspl9wdiPHgJEGlUumf2UGml96HlJ+hRQwhRoSleQfZgfawlDJoB5KgO4OgDLrIT4UUMEA2xdNpro/t6aA+BJGJKuqxoJ9ikLmzQas4MFEbJmYIHz99GNRaxhiCWPcjhAjcBalNxCaqgsBrUPGIO5T3GGRjIqwjslHegnompqDn8hojGHgLyqA3iTFC7CLnLOh4Z0Gn3FnQf2O3ZrN5iZ9aVw81Go3zQfLmI4iIx/gBUXvtdnvNXZDGbEMI2Gf/BFsQPXffVRADr+jgn1hylwPdOL6Bn7w2brVaV9wEMfALBheGDu3QGvVQ79RtT0FvGDyu1WoXE4JWNKjiack916HXEoJecT7GLTdBLLXrDPwbEX+Xq9XqucPHNzFVzv3B93q9fsHbU+4uhAhh/wXfIMaWqyBdXjfxluE/63fQM/Yt8/je9hQ0vdnQpybqJRZcB2nUI4J+QVB2H6RRHzUoTPo/fwGr9gNcek8bXAAAAABJRU5ErkJggg==) no-repeat 0px 2px;
}

.gform_wrapper .chosen-container-single .chosen-search {
    position: relative;
    z-index: 1010;
    margin: 0;
    padding: 3px 4px;
    white-space: nowrap;
}

.gform_wrapper .chosen-container-single .chosen-search input[type="text"] {
    box-sizing: border-box;
    margin: 1px 0;
    padding: 4px 20px 4px 5px;
    width: 100%;
    height: auto;
    border: 1px solid #AAA;
    background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAlCAYAAAAN8srVAAACTUlEQVR42u3Wv2sTcRiA8VPBxUKwEAxU3NxPIoFAl1bIkkmwYKAKRbqbRSWQCGJ+rMUibjo4FARBl0AgUIh/QXFxFIpKJHAQKA56r0/hDbyEK5VrDH2hBx+ud+Ga9+G+uSQQkVOv0+lMZNBFHoFRwABZb0F9CCITVdRjQd9b0CoOTNSGiRkidBWkljGGINb9CCECd0FqE7GJqkxeMxccK8UbJzppUPGIO5SfR9DCjINsTIR1RDbKXvAakuB9yqAsvuLaDIN6Jqag5/IaIxjYCxaxDzFGyKUMegdBb4ZBGfQmMUaIXeSmLyhDjHspl9wdiPHgJEGlUumf2UGml96HlJ+hRQwhRoSleQfZgfawlDJoB5KgO4OgDLrIT4UUMEA2xdNpro/t6aA+BJGJKuqxoJ9ikLmzQas4MFEbJmYIHz99GNRaxhiCWPcjhAjcBalNxCaqgsBrUPGIO5T3GGRjIqwjslHegnompqDn8hojGHgLyqA3iTFC7CLnLOh4Z0Gn3FnQf2O3ZrN5iZ9aVw81Go3zQfLmI4iIx/gBUXvtdnvNXZDGbEMI2Gf/BFsQPXffVRADr+jgn1hylwPdOL6Bn7w2brVaV9wEMfALBheGDu3QGvVQ79RtT0FvGDyu1WoXE4JWNKjiack916HXEoJecT7GLTdBLLXrDPwbEX+Xq9XqucPHNzFVzv3B93q9fsHbU+4uhAhh/wXfIMaWqyBdXjfxluE/63fQM/Yt8/je9hQ0vdnQpybqJRZcB2nUI4J+QVB2H6RRHzUoTPo/fwGr9gNcek8bXAAAAABJRU5ErkJggg==) no-repeat 100% -20px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAlCAYAAAAN8srVAAACTUlEQVR42u3Wv2sTcRiA8VPBxUKwEAxU3NxPIoFAl1bIkkmwYKAKRbqbRSWQCGJ+rMUibjo4FARBl0AgUIh/QXFxFIpKJHAQKA56r0/hDbyEK5VrDH2hBx+ud+Ga9+G+uSQQkVOv0+lMZNBFHoFRwABZb0F9CCITVdRjQd9b0CoOTNSGiRkidBWkljGGINb9CCECd0FqE7GJqkxeMxccK8UbJzppUPGIO5SfR9DCjINsTIR1RDbKXvAakuB9yqAsvuLaDIN6Jqag5/IaIxjYCxaxDzFGyKUMegdBb4ZBGfQmMUaIXeSmLyhDjHspl9wdiPHgJEGlUumf2UGml96HlJ+hRQwhRoSleQfZgfawlDJoB5KgO4OgDLrIT4UUMEA2xdNpro/t6aA+BJGJKuqxoJ9ikLmzQas4MFEbJmYIHz99GNRaxhiCWPcjhAjcBalNxCaqgsBrUPGIO5T3GGRjIqwjslHegnompqDn8hojGHgLyqA3iTFC7CLnLOh4Z0Gn3FnQf2O3ZrN5iZ9aVw81Go3zQfLmI4iIx/gBUXvtdnvNXZDGbEMI2Gf/BFsQPXffVRADr+jgn1hylwPdOL6Bn7w2brVaV9wEMfALBheGDu3QGvVQ79RtT0FvGDyu1WoXE4JWNKjiack916HXEoJecT7GLTdBLLXrDPwbEX+Xq9XqucPHNzFVzv3B93q9fsHbU+4uhAhh/wXfIMaWqyBdXjfxluE/63fQM/Yt8/je9hQ0vdnQpybqJRZcB2nUI4J+QVB2H6RRHzUoTPo/fwGr9gNcek8bXAAAAABJRU5ErkJggg==) no-repeat 100% -20px;
    font-size: 1em;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
}

.gform_wrapper .chosen-container-single .chosen-drop {
    margin-top: -1px;
    border-radius: 0 0 4px 4px;
    background-clip: padding-box;
}

.gform_wrapper .chosen-container-single.chosen-container-single-nosearch .chosen-search {
    position: absolute;
    left: -9999px;
}

.gform_wrapper .chosen-container .chosen-results {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 4px 4px 0;
    padding: 0 0 0 4px;
    max-height: 240px;
    -webkit-overflow-scrolling: touch;
}

.gform_wrapper .chosen-container .chosen-results li {
    display: none;
    margin: 0;
    padding: 5px 6px;
    list-style: none;
    line-height: 15px;
    -webkit-touch-callout: none;
}

.gform_wrapper .chosen-container .chosen-results li.active-result {
    display: list-item;
    cursor: pointer;
}

.gform_wrapper .chosen-container .chosen-results li.disabled-result {
    display: list-item;
    color: #CCC;
    cursor: default;
}

.gform_wrapper .chosen-container .chosen-results li.highlighted {
    background-color: #3875d7;
    background-image: linear-gradient(#3875d7 20%, #2a62bc 90%);
    color: #FFF;
}

.gform_wrapper .chosen-container .chosen-results li.no-results {
    display: list-item;
    background: #F4F4F4;
}

.gform_wrapper .chosen-container .chosen-results li.group-result {
    display: list-item;
    font-weight: 700;
    cursor: default;
}

.gform_wrapper .chosen-container .chosen-results li.group-option {
    padding-left: 15px;
}

.gform_wrapper .chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: underline;
}

.gform_wrapper .chosen-container-multi .chosen-choices {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto !important;
    height: 1%;
    border: 1px solid #AAA;
    background-color: #FFF;
    background-image: linear-gradient(#EEE 1%, #FFF 15%);
    cursor: text;
}

.gform_wrapper .chosen-container-multi .chosen-choices li {
    float: left;
    list-style: none;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field {
    margin: 0;
    padding: 0;
    white-space: nowrap;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
    margin: 1px 0;
    padding: 5px;
    height: 15px;
    border: 0 !important;
    background: transparent !important;
    box-shadow: none;
    color: #666;
    font-size: 100%;
    font-family: sans-serif;
    line-height: normal;
    border-radius: 0;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-field .default {
    color: #999;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 5px 0 5px 5px !important;
    padding: 3px 20px 3px 5px;
    border: 1px solid #AAA;
    border-radius: 3px;
    background-color: #E4E4E4;
    background-image: linear-gradient(#F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    background-clip: padding-box;
    box-shadow: 0 0 2px white inset, 0 1px 0 rgba(0, 0, 0, .05);
    color: #333;
    line-height: 13px;
    cursor: default;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: 4px;
    right: 3px;
    display: block;
    width: 12px;
    height: 12px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAlCAYAAAAN8srVAAACTUlEQVR42u3Wv2sTcRiA8VPBxUKwEAxU3NxPIoFAl1bIkkmwYKAKRbqbRSWQCGJ+rMUibjo4FARBl0AgUIh/QXFxFIpKJHAQKA56r0/hDbyEK5VrDH2hBx+ud+Ga9+G+uSQQkVOv0+lMZNBFHoFRwABZb0F9CCITVdRjQd9b0CoOTNSGiRkidBWkljGGINb9CCECd0FqE7GJqkxeMxccK8UbJzppUPGIO5SfR9DCjINsTIR1RDbKXvAakuB9yqAsvuLaDIN6Jqag5/IaIxjYCxaxDzFGyKUMegdBb4ZBGfQmMUaIXeSmLyhDjHspl9wdiPHgJEGlUumf2UGml96HlJ+hRQwhRoSleQfZgfawlDJoB5KgO4OgDLrIT4UUMEA2xdNpro/t6aA+BJGJKuqxoJ9ikLmzQas4MFEbJmYIHz99GNRaxhiCWPcjhAjcBalNxCaqgsBrUPGIO5T3GGRjIqwjslHegnompqDn8hojGHgLyqA3iTFC7CLnLOh4Z0Gn3FnQf2O3ZrN5iZ9aVw81Go3zQfLmI4iIx/gBUXvtdnvNXZDGbEMI2Gf/BFsQPXffVRADr+jgn1hylwPdOL6Bn7w2brVaV9wEMfALBheGDu3QGvVQ79RtT0FvGDyu1WoXE4JWNKjiack916HXEoJecT7GLTdBLLXrDPwbEX+Xq9XqucPHNzFVzv3B93q9fsHbU+4uhAhh/wXfIMaWqyBdXjfxluE/63fQM/Yt8/je9hQ0vdnQpybqJRZcB2nUI4J+QVB2H6RRHzUoTPo/fwGr9gNcek8bXAAAAABJRU5ErkJggg==) -42px 1px no-repeat;
    font-size: 1px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close:hover {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-disabled {
    padding-right: 5px;
    border: 1px solid #CCC;
    background-color: #E4E4E4;
    background-image: linear-gradient(top, #F4F4F4 20%, #F0F0F0 50%, #E8E8E8 52%, #EEE 100%);
    color: #666;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus {
    background: #D4D4D4;
}

.gform_wrapper .chosen-container-multi .chosen-choices li.search-choice-focus .search-choice-close {
    background-position: -42px -10px;
}

.gform_wrapper .chosen-container-multi .chosen-results {
    margin: 0;
    padding: 0;
}

.gform_wrapper .chosen-container-multi .chosen-drop .result-selected {
    display: list-item;
    color: #CCC;
    cursor: default;
}

.gform_wrapper .chosen-container-active .chosen-single {
    border: 1px solid #5897FB;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #AAA;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-image: linear-gradient(#EEE 20%, #FFF 80%);
    box-shadow: 0 1px 0 #fff inset;
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div {
    border-left: none;
    background: transparent;
}

.gform_wrapper .chosen-container-active.chosen-with-drop .chosen-single div b {
    background-position: -18px 2px;
}

.gform_wrapper .chosen-container-active .chosen-choices {
    border: 1px solid #5897FB;
    box-shadow: 0 0 5px rgba(0, 0, 0, .3);
}

.gform_wrapper .chosen-container-active .chosen-choices li.search-field input[type="text"] {
    color: #111 !important;
}

.gform_wrapper .chosen-disabled {
    opacity: .5 !important;
    cursor: default;
}

.gform_wrapper .chosen-disabled .chosen-single {
    cursor: default;
}

.gform_wrapper .chosen-disabled .chosen-choices .search-choice .search-choice-close {
    cursor: default;
}

.gform_wrapper .chosen-rtl {
    text-align: right;
}

.gform_wrapper .chosen-rtl .chosen-single {
    overflow: visible;
    padding: 0 8px 0 0;
}

.gform_wrapper .chosen-rtl .chosen-single span {
    margin-right: 0;
    margin-left: 26px;
    direction: rtl;
}

.gform_wrapper .chosen-rtl .chosen-single-with-deselect span {
    margin-left: 38px;
}

.gform_wrapper .chosen-rtl .chosen-single div {
    right: auto;
    left: 3px;
}

.gform_wrapper .chosen-rtl .chosen-single abbr {
    right: auto;
    left: 26px;
}

.gform_wrapper .chosen-rtl .chosen-choices li {
    float: right;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-field input[type="text"] {
    direction: rtl;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice {
    margin: 3px 5px 3px 0;
    padding: 3px 5px 3px 19px;
}

.gform_wrapper .chosen-rtl .chosen-choices li.search-choice .search-choice-close {
    right: auto;
    left: 4px;
}

.gform_wrapper .chosen-rtl.chosen-container-single-nosearch .chosen-search, .gform_wrapper .chosen-rtl .chosen-drop {
    left: 9999px;
}

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-results {
    margin: 0 0 4px 4px;
    padding: 0 4px 0 0;
}

.gform_wrapper .chosen-rtl .chosen-results li.group-option {
    padding-right: 15px;
    padding-left: 0;
}

.gform_wrapper .chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
    border-right: none;
}

.gform_wrapper .chosen-rtl .chosen-search input[type="text"] {
    padding: 4px 5px 4px 20px;
    background: white url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAlCAYAAAAN8srVAAACTUlEQVR42u3Wv2sTcRiA8VPBxUKwEAxU3NxPIoFAl1bIkkmwYKAKRbqbRSWQCGJ+rMUibjo4FARBl0AgUIh/QXFxFIpKJHAQKA56r0/hDbyEK5VrDH2hBx+ud+Ga9+G+uSQQkVOv0+lMZNBFHoFRwABZb0F9CCITVdRjQd9b0CoOTNSGiRkidBWkljGGINb9CCECd0FqE7GJqkxeMxccK8UbJzppUPGIO5SfR9DCjINsTIR1RDbKXvAakuB9yqAsvuLaDIN6Jqag5/IaIxjYCxaxDzFGyKUMegdBb4ZBGfQmMUaIXeSmLyhDjHspl9wdiPHgJEGlUumf2UGml96HlJ+hRQwhRoSleQfZgfawlDJoB5KgO4OgDLrIT4UUMEA2xdNpro/t6aA+BJGJKuqxoJ9ikLmzQas4MFEbJmYIHz99GNRaxhiCWPcjhAjcBalNxCaqgsBrUPGIO5T3GGRjIqwjslHegnompqDn8hojGHgLyqA3iTFC7CLnLOh4Z0Gn3FnQf2O3ZrN5iZ9aVw81Go3zQfLmI4iIx/gBUXvtdnvNXZDGbEMI2Gf/BFsQPXffVRADr+jgn1hylwPdOL6Bn7w2brVaV9wEMfALBheGDu3QGvVQ79RtT0FvGDyu1WoXE4JWNKjiack916HXEoJecT7GLTdBLLXrDPwbEX+Xq9XqucPHNzFVzv3B93q9fsHbU+4uhAhh/wXfIMaWqyBdXjfxluE/63fQM/Yt8/je9hQ0vdnQpybqJRZcB2nUI4J+QVB2H6RRHzUoTPo/fwGr9gNcek8bXAAAAABJRU5ErkJggg==) no-repeat -30px -20px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAAlCAYAAAAN8srVAAACTUlEQVR42u3Wv2sTcRiA8VPBxUKwEAxU3NxPIoFAl1bIkkmwYKAKRbqbRSWQCGJ+rMUibjo4FARBl0AgUIh/QXFxFIpKJHAQKA56r0/hDbyEK5VrDH2hBx+ud+Ga9+G+uSQQkVOv0+lMZNBFHoFRwABZb0F9CCITVdRjQd9b0CoOTNSGiRkidBWkljGGINb9CCECd0FqE7GJqkxeMxccK8UbJzppUPGIO5SfR9DCjINsTIR1RDbKXvAakuB9yqAsvuLaDIN6Jqag5/IaIxjYCxaxDzFGyKUMegdBb4ZBGfQmMUaIXeSmLyhDjHspl9wdiPHgJEGlUumf2UGml96HlJ+hRQwhRoSleQfZgfawlDJoB5KgO4OgDLrIT4UUMEA2xdNpro/t6aA+BJGJKuqxoJ9ikLmzQas4MFEbJmYIHz99GNRaxhiCWPcjhAjcBalNxCaqgsBrUPGIO5T3GGRjIqwjslHegnompqDn8hojGHgLyqA3iTFC7CLnLOh4Z0Gn3FnQf2O3ZrN5iZ9aVw81Go3zQfLmI4iIx/gBUXvtdnvNXZDGbEMI2Gf/BFsQPXffVRADr+jgn1hylwPdOL6Bn7w2brVaV9wEMfALBheGDu3QGvVQ79RtT0FvGDyu1WoXE4JWNKjiack916HXEoJecT7GLTdBLLXrDPwbEX+Xq9XqucPHNzFVzv3B93q9fsHbU+4uhAhh/wXfIMaWqyBdXjfxluE/63fQM/Yt8/je9hQ0vdnQpybqJRZcB2nUI4J+QVB2H6RRHzUoTPo/fwGr9gNcek8bXAAAAABJRU5ErkJggg==) no-repeat -30px -20px;
    direction: rtl;
}

.gform_wrapper .chosen-rtl.chosen-container-single .chosen-single div b {
    background-position: 6px 2px;
}

.gform_wrapper .chosen-rtl.chosen-container-single.chosen-with-drop .chosen-single div b {
    background-position: -12px 2px;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi) {
    .gform_wrapper .chosen-rtl .chosen-search input[type="text"],
    .gform_wrapper .chosen-container-single .chosen-single abbr,
    .gform_wrapper .chosen-container-single .chosen-single div b,
    .gform_wrapper .chosen-container-multi .chosen-choices .search-choice .search-choice-close,
    .gform_wrapper .chosen-container .chosen-results-scroll-down span,
    .gform_wrapper .chosen-container .chosen-results-scroll-up span {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGgAAABKCAMAAABgpuGuAAAAzFBMVEX///8AAACIiIiIiIiIiIhGRkZGRkZGRkaIiIiHh4eHh4eGhoaAgICGhoaHh4dGRkaHh4eHh4eIiIiHh4eIiIiHh4eIiIiHh4eHh4eHh4eHh4eHh4eAgICHh4eHh4eAgICFhYWIiIiHh4eHh4eHh4eIiIiEhISIiIiIiIiIiIiIiIiHh4d0dHSGhoaHh4eDg4NVVVWDg4OHh4eIiIiAgICHh4eHh4eAgICIiIiHh4eIiIiHh4eIiIiHh4eGhoaHh4eHh4eIiIiIiIhGRkYymc+gAAAAQnRSTlMAAP7wMDDwYGCg/VAQcIDz4CDz0PxAz7D1wPv5CGChFEX64t2QHh2N3Jaa2wsTgiEDKYjYDGaZBO8Rqd+LREqM5n7NGqdwAAACCUlEQVR4Xu3V127jMBCFYZ2h5KJiW5a7UzbJ9t57Hb3/O60Ik+JFsMLCwrkI4P+KV/oAYjSMOAEjE8MVm1HECiNVL8VGlSY1jpW8w5OMeil2RxIUe6k9kCA4YD7nOhG8RHYiWCk4YEKI5wdnHuPuQPyr4w8Df7xhm0xgI/2wASpVSwsdvYJm2jbrgraqWwsdvVSXAVp2QJk2ZQCOfiaw9s4a/4bymYVmOXD0w4fSzaIpO6CJ2nTyH1Cfj6BUV9kHwuFa0AFtPbTtBS0ttOyAMm3L+kB2HtbogG79Ap0Bw0ECVzIYBgilMaWH+odhXTeSc+p62LFeetU4VvKOlTgNai8l7kiCEi+1BxIEB0ynXCeCl8hOBCsFB0wIyfTgTBPcEYh/dfxh4I83/4flryD+UmU9E4Q6Hj5Cp06dOoWmvKhURFZFjjYGlBlVC4l+zpjQuahIulikljrnQZmqXN18ePDwz+O9qGQsKDcqm/tnaHrxdCNichJUiOwf4dDrJzvRggStJH32HK6za9GKBKlKgbaXqQgNSl8F6N6CCb3pgFhX95Z3dZXKu/dwXV6nsiJBhcrVx09u6C6I450bkc3FpXW+fN2I7nPaChKV3bfvP37++r0TVR3zlqqKpIelqrYx85kQm+o+SKSHb2WhqsjHTiJBITiJDQWJDAWpIkNBIkJBIkOhSnUShf4C9DyJBLzMYSsAAAAASUVORK5CYII=) !important;
        background-position: 0 0;
        background-size: 52px 37px !important;
        background-repeat: no-repeat !important;
    }
}

.gform_wrapper .gform_card_icon_container {
    margin: 8px 0 6px 0;
    height: 32px;
}

.gform_wrapper div.gform_card_icon {
    margin-right: 4px;
    text-indent: -9000px;
    background-image: url(/static/media/gf-creditcard-icons.982b1e09.png);
    background-repeat: no-repeat;
    width: 36px;
    height: 32px;
    float: left;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_visa {
    background-position: 0 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa { background-position: 0 -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa { background-position: 0 -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_mastercard {
    background-position: -36px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
    background-position: -36px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
    background-position: -36px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_amex {
    background-position: -72px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
    background-position: -72px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
    background-position: -72px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_discover {
    background-position: -108px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
    background-position: -108px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
    background-position: -108px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_maestro {
    background-position: -144px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
    background-position: -144px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
    background-position: -144px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_jcb {
    background-position: -180px 0;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
    background-position: -180px -32px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style1 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
    background-position: -180px -64px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_visa {
    background-position: 0 -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_visa {
    background-position: 0 -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_visa {
    background-position: 0 -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_mastercard {
    background-position: -36px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_mastercard {
    background-position: -36px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_mastercard {
    background-position: -36px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_amex {
    background-position: -72px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_amex {
    background-position: -72px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_amex {
    background-position: -72px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_discover {
    background-position: -108px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_discover {
    background-position: -108px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_discover {
    background-position: -108px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_maestro {
    background-position: -144px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_maestro {
    background-position: -144px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_maestro {
    background-position: -144px -256px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_jcb {
    background-position: -180px -192px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_selected.gform_card_icon_jcb {
    background-position: -180px -224px;
}

.gform_wrapper .gform_card_icon_container.gform_card_icon_style2 div.gform_card_icon.gform_card_icon_inactive.gform_card_icon_jcb {
    background-position: -180px -256px;
}

.gform_card_icon_container input[type=radio]#gform_payment_method_creditcard {
    float: left;
    position: relative;
    top: 4px!important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right {
    min-width: 85px !important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left label, .gform_wrapper .ginput_complex .ginput_cardinfo_right label {
    white-space: nowrap !important;
}

.gform_wrapper .ginput_complex span.ginput_cardextras {
    display: block;
    overflow: hidden;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container {
    position: relative;
    display: block;
    min-width: 160px !important;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_left span.ginput_card_expiration_container select {
    margin-bottom: 8px;
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right input.ginput_card_security_code {
    max-width: 50% !important
}

.gform_wrapper .ginput_complex .ginput_cardinfo_right span.ginput_card_security_code_icon {
    width: 32px;
    height: 23px;
    background-image: url(/static/media/gf-creditcard-icons.982b1e09.png);
    background-repeat: no-repeat;
    background-position: 0 -128px;
    position: relative;
    top: -1px;
    left: 6px;
    display: -moz-inline-stack;
    display: inline-block;
}

.gform_wrapper .gform_fileupload_multifile .gform_drop_area {
    padding: 24px;
    border: 1px dashed #CCC;
    text-align: center;
    color: #AAA;
    margin-bottom: 16px;
    background: rgba(0, 0, 0, 0.02);
    border-radius: 4px;
}

.gform_wrapper span.gform_drop_instructions {
    display: block;
    margin-bottom: 8px;
}

.gform_delete {
    vertical-align: middle;
    cursor: pointer;
}

.gform_wrapper .copy_values_option_container .copy_values_enabled {
    width: auto;
}

.gform_wrapper li.field_sublabel_above .ginput_complex input[type=text] {
    margin-bottom: 1px;
}

.gform_wrapper .form_saved_message{
    margin: 16px 0 16px 0;
    width: 100%;
    font-size: 1em;
    line-height: 1.8;
    border: 2px solid #91AFC7;
    background-color: #E3F3F3;
    text-align: center;
    border-radius: 2px;
}

.gform_wrapper .form_saved_message form{
    text-align: center;
}

.gform_wrapper .form_saved_message span:first-child {
    display: block;
    padding: 28px 16px 28px 16px;
}

.form_saved_message a.resume_form_link {
    word-wrap: break-word;
}

.form_saved_message a.resume_form_link:before,
.form_saved_message a.resume_form_link:after {
    content: "";
    display: block;
}

.form_saved_message a.resume_form_link:before {
    margin-top: 12px;
}

.form_saved_message a.resume_form_link:after {
    margin-bottom: 12px;
}

.form_saved_message br + br,
.form_saved_message a + br {
    display: none;
}

.form_saved_message .form_saved_message_emailform {
    margin: 16px 0 0 0;
}

div.form_saved_message div.form_saved_message_emailform form input[type=text] {
    line-height: 2.25;
    height: 24px;
    margin-bottom: 4px;
}

div.form_saved_message div.form_saved_message_emailform form input[type=submit] {
    margin: 12px auto 0 auto;
    display: block;
}

.form_saved_message .form_saved_message_emailform .validation_message {
    color: #790000;
}

.form_saved_message_sent {
    margin: 16px 0 16px 0;
    width: 100%;
    font-size: 1em;
    border: 2px solid #CFE2C0;
    background-color: #E9FFD9;
    text-align: center;
    border-radius: 2px;
}

.form_saved_message_sent span:first-child {
    display: block;
    padding: 16px;
}

body #content .entry-content .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area,
body .gform_wrapper form .gform_body .gform_fields .gfield .ginput_container #recaptcha_widget_div #recaptcha_area .recaptchatable tbody tr td .recaptcha_input_area {
    line-height: 1 !important;
}

body .gform_wrapper img.ui-datepicker-trigger {
    width: 17px !important;
    height: 16px !important;
    display: -moz-inline-stack;
    display: inline-block;
}

body .gform_wrapper img.add_list_item,
body .gform_wrapper img.delete_list_item {
    display: -moz-inline-stack;
    display: inline-block;
    margin-top: -2px;
}

.gform_wrapper ul li.gfield.field_sublabel_above.field_description_below + li.gfield {
    margin-top: 16px;
}

.gform_wrapper li.gfield ul.gfield_radio li input[type="radio"] + input[type="text"] {
    width: 45.25%;
}

.gform_wrapper table.recaptchatable {
    table-layout: auto;
}

/* adjusting the captcha margin for left or right label settings */

.gform_wrapper ul.gform_fields:not(.top_label) div.ginput_container_captcha {
    margin-left: 29%;
}

/* rich text editor */

.gfield .wp-editor-container {
    border: 1px solid #ddd;
}

.gfield .wp-switch-editor {
    border-color: #ddd;
}

.gfield .tmce-active .switch-tmce,
.gfield .html-active .switch-html {
    border-bottom-color: #f5f5f5;
}

.gfield .mce-edit-area iframe {
    width: 99.9% !important;
    margin: 0 !important;
}

body .gform_wrapper ul li.gfield {
    margin-top: 16px;
    padding-top: 0;
}

body .gform_wrapper ul.gfields li.gfield label.gfield_label {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

/* do our best to restore styles for lists inside the HTML field */

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html {
    line-height: inherit;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul li {
    list-style-type: disc !important;
    margin: 0 0 8px 0;
    overflow: visible;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ul {
    list-style-type: disc !important;
    margin: 16px 0 16px 28px !important;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li {
    list-style-type: decimal!important;
    overflow: visible;
    margin: 0 0 8px 0;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol {
    list-style-type: decimal !important;
    margin: 16px 0 18px 32px;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul,
body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
    list-style-type: disc !important;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul {
    margin: 16px 0 16px 16px;
}

body .gform_wrapper form div.gform_body ul.gform_fields li.gfield.gfield_html ol li ul li {
    margin: 0 0 8px;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl {
    margin: 0 0 18px 0;
    padding-left: 0;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dt {
    font-weight: 700;
}

body .gform_wrapper div.gform_body ul.gform_fields li.gfield.gfield_html dl dd {
    margin: 0 0 16px 18px;
}

.gform_wrapper span.gf_clear_span {
    display: block;
    height: 1px;
    overflow: hidden;
    width: 100%;
    float: none;
    clear: both;
    margin-top: -1px;
}

@media only screen and (max-width: 641px)  {

    /* make the inputs a little larger for mobile devices */

    .gform_wrapper input:not([type='radio']):not([type='checkbox']):not([type='image']):not([type='file']) {
        line-height: 2;
        min-height: 2rem;
    }

    .gform_wrapper textarea {
        line-height: 1.5;
    }

    .gform_wrapper .chosen-container.chosen-container-single[style] {
        width: 100% !important;
    }

    .gform_wrapper .chosen-container-single .chosen-single {
        height: 40px;
        line-height: 2.8;
    }

    .gform_wrapper .chosen-container-single .chosen-single div b {
        position: relative;
        top: 10px;
    }

    .gform_wrapper div.chosen-container.chosen-container-multi[style] {
        width: 100% !important;
    }

    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice,
    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-field {
        width: calc(100% - 10px);
        line-height: 2;
        float: none;
    }

    .gform_wrapper .chosen-container.chosen-container-multi ul.chosen-choices li.search-choice span {
        margin-bottom: 0;
    }

    .gform_wrapper .chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
        top: calc(50% - 6px);
    }

    .gform_wrapper .ginput_container span:not(.ginput_price) {
        margin-bottom: 8px;
        display: block;
    }

    .gform_wrapper li.field_sublabel_below .ginput_complex {
        margin-top: 12px !important;
    }

    .gform_wrapper ul.gfield_radio li label,
    .gform_wrapper ul.gfield_checkbox li label {
        width: 85%;
        cursor: pointer;
    }

 }

@media only screen and (max-width: 761px), (min-device-width: 768px) and (max-device-width: 1023px)  {

    .gform_wrapper ul.gform_fields li.gfield.gfield_error + li.gfield.gfield_error {
        margin-top: 32px;
    }

    /* force the list table to not behave like a table anymore */

    .gform_wrapper table.gfield_list {
      border: 0;
    }

    .gform_wrapper table.gfield_list thead  {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .gform_wrapper table.gfield_list tr {
        margin-bottom: 10px;
        display: block;
        background: #fff;
        position:relative;
        border: 1px solid #CCC;
        border-top: 2px solid #CCC;
        border-bottom: 4px solid #CCC;
    }

    .gform_wrapper table.gfield_list td {
        display: block;
        margin-left: 8px;
        margin-right: 8px;
    }

    .gform_wrapper table.gfield_list td.gfield_list_icons {
        border-bottom: 0;
        margin: 16px 0 0 0 !important;
    }

    .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before,
    .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
        content: attr(data-label);
        font-size: .875em;
        letter-spacing: 0.5pt;
        white-space: nowrap;
        display: block;
        clear: both;
    }

    .gform_wrapper ul.gform_fields.form_sublabel_above table.gfield_list td:before {
        margin: 8px 0 3px 8px;
    }

    .gform_wrapper ul.gform_fields.form_sublabel_below table.gfield_list td:after {
      margin: 3px 0 8px 8px;
    }

    .gform_wrapper table.gfield_list th + th,
    .gform_wrapper table.gfield_list td + td {
        padding: 4px 0 0 0;
    }

    .gform_wrapper table.gfield_list tr.gfield_list_row_odd,
    .gform_wrapper table.gfield_list tr.gfield_list_row_even {
        margin: 16px 0;
    }

    .gform_wrapper table.gfield_list tr.gfield_list_row_odd:first-child {
         padding-top: 12px;
    }

    .gform_wrapper table.gfield_list tr {
         padding-top: 12px;
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons {
        width: 100% !important;
        padding: 0 4px 4px 4px !important;
        background-color: #EEE;
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons img.add_list_item  {
        margin: 12px 0 0 16px !important;
    }

    .gform_wrapper table.gfield_list tr td.gfield_list_icons img.delete_list_item  {
        margin: 12px 0 0 8px !important;
    }

    .gform_wrapper .gform_footer a.gform_save_link,
    .gform_wrapper .gform_page_footer a.gform_save_link {
        margin-left: 0;
    }

    .gform_wrapper .gfield_list td.gfield_list_cell input {
        width: calc(100% - 16px);
        margin-left: 8px;
        margin-right: 8px;
    }

}

/* media queries - portrait mode tablet or desktop styles */

@media only screen and (min-width: 641px) {

    .gform_wrapper .gform_body {
        width: 100%;
    }

    .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
        max-width: calc(100% - 16px) !important;
    }

    .gform_wrapper .gfield_checkbox li,
    .gform_wrapper .gfield_radio li {
        margin: 0 0 8px 0;
        line-height: 1.3;
    }

    .gform_wrapper .gsection {
        margin: 28px 16px 28px 0;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gfield_label {
        float: left;
        width: 29%;
        padding-right: 16px;
        margin-bottom: 16px;
    }

    .gform_wrapper .right_label .gfield_label {
        text-align: right;
    }

    .gform_wrapper .left_label .gfield_description,
    .gform_wrapper .right_label .gfield_description {
        width: 70%;
    }

    .gform_wrapper ul.right_label li ul.gfield_radio li,
    .gform_wrapper form ul.right_label li ul.gfield_radio li,
    .gform_wrapper ul.left_label li ul.gfield_radio li,
    .gform_wrapper form ul.left_label li ul.gfield_radio li,
    .gform_wrapper ul.right_label li ul.gfield_checkbox li,
    .gform_wrapper form ul.right_label li ul.gfield_checkbox li,
    .gform_wrapper ul.left_label li ul.gfield_checkbox li,
    .gform_wrapper form ul.left_label li ul.gfield_checkbox li {
        margin-bottom: 10px;
    }

    .gform_wrapper .right_label .gfield_description,
    .gform_wrapper .left_label .gfield_description,
    .gform_wrapper .left_label .instruction,
    .gform_wrapper .right_label .instruction {
        margin-left: 29%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gsection .gsection_description {
        margin-left: 0;
        padding-top: 4px;
        padding-left: 0;
        line-height: 1.5;
    }

    .gform_wrapper form ul.gform_fields:not(.top_label) li.gfield_html_formatted {
        margin-left: 29% !important;
        width: 70%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_checkbox,
    .gform_wrapper ul.gform_fields:not(.top_label) ul.gfield_radio {
        overflow: hidden;
    }

    .gform_wrapper .right_label div.ginput_complex,
    .gform_wrapper .left_label div.ginput_complex {
        width: 70%;
        margin-left: 29%;
    }

    .gform_wrapper .right_label input.small,
    .gform_wrapper .right_label select.small,
    .gform_wrapper .left_label input.small,
    .gform_wrapper .left_label select.small {
        width: 15%;
    }

    .gform_wrapper .right_label input.medium,
    .gform_wrapper .right_label select.medium,
    .gform_wrapper .left_label input.medium,
    .gform_wrapper .left_label select.medium {
        width: calc(35% - 8px);
    }

    .gform_wrapper .right_label input.large,
    .gform_wrapper .right_label select.large,
    .gform_wrapper .left_label input.large,
    .gform_wrapper .left_label select.large {
        width: 70%;
    }

    .gform_wrapper .right_label textarea.small,
    .gform_wrapper .left_label textarea.small,
    .gform_wrapper .right_label textarea.medium,
    .gform_wrapper .left_label textarea.medium,
    .gform_wrapper .right_label textarea.large,
    .gform_wrapper .left_label textarea.large {
        width: 70%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .ginput_list {
        width: 70%;
        margin-left: 29% !important;
    }

    .gform_wrapper .top_label .gfield_list {
        width: 100%;
    }

    .gform_wrapper .left_label .gf_list_one_column,
    .gform_wrapper .right_label .gf_list_one_column {
        width: 45%;
    }

    .gform_wrapper .top_label .gf_list_one_column {
        width: 46%;
    }

    .gform_wrapper .left_label li.hidden_label input,
    .gform_wrapper .right_label li.hidden_label input {
        margin-left: 3.7%;
    }

    .gform_wrapper .left_label li.gfield .gfield_password_strength,
    .gform_wrapper .right_label li.gfield .gfield_password_strength {
        margin-left: 29%;
    }

    .gform_wrapper .top_label input.small,
    .gform_wrapper .top_label select.small {
        width: 25%;
    }

    .gform_wrapper .top_label input.medium,
    .gform_wrapper .top_label select.medium {
        width: calc(50% - 8px);
    }

    .gform_wrapper.gform_validation_error .top_label input.medium,
    .gform_wrapper.gform_validation_error .top_label select.medium {
        width: 50%;
    }

    .gform_wrapper .top_label input.large,
    .gform_wrapper .top_label select.large,
    .gform_wrapper .hidden_label input.large,
    .gform_wrapper .hidden_label select.large {
        width: 100%;
    }

    .gform_wrapper .top_label input.small.datepicker,
    .gform_wrapper .top_label input.medium.datepicker,
    .gform_wrapper .top_label input.large.datepicker {
        width: auto;
    }

    .gform_wrapper textarea.small {
        height: 80px;
        width: 100%;
    }

    .gform_wrapper textarea.medium {
        height: 160px;
        width: 100%;
    }

    .gform_wrapper textarea.large {
        height: 320px;
        width: 100%;
    }

    .gform_wrapper .ginput_complex .ginput_left,
    .gform_wrapper .ginput_complex .ginput_right {
        width: 50%;
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        margin: 0 -4px 0 0;
    }

    .gform_wrapper .gfield_error .ginput_complex .ginput_left,
    .gform_wrapper .gfield_error .ginput_complex .ginput_right {
        width: 50%;
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left,
    .gform_wrapper .ginput_complex .ginput_cardinfo_right {
        min-height: 43px;
        position: relative;
        float: left;
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left {
        width: 50%;
    }

    .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_month,
    .gform_wrapper .ginput_complex select.ginput_card_expiration.ginput_card_expiration_year {
        width: 47% !important;
        display: -moz-inline-stack;
        display: inline-block;
    }

    .gform_wrapper .ginput_complex .ginput_cardinfo_left select.ginput_card_expiration.ginput_card_expiration_month {
        margin-right: 4px;
    }

    .gform_wrapper .gf_step {
        width:auto;
        display: -moz-inline-stack;
        display: inline-block;
        margin: 16px 32px 16px 0;
    }

    .gform_wrapper .gform_footer input.button,
    .gform_wrapper .gform_footer input[type=submit],
    .gform_wrapper .gform_footer input[type=image] {
        display: -moz-inline-stack;
        display: inline-block;
    }

    .gform_wrapper .gform_footer input[type=image] {
        vertical-align: middle;
    }

    .gform_wrapper .gform_footer a.gform_save_link,
    .gform_wrapper .gform_page_footer a.gform_save_link {
        font-size: 1em;
        margin-left: 16px;
        display: -moz-inline-stack;
        display: inline-block;
    }

    .gform_wrapper .gform_footer input.button,
    .gform_wrapper .gform_page_footer input.button,
    .gform_wrapper .gform_footer input[type=submit],
    .gform_wrapper .gform_page_footer input[type=submit] {
        font-size: 1em;
        width: auto;
        margin: 0 16px 0 0;
    }

    .gform_wrapper ul.gform_fields li.gfield {
        padding-right: 16px;
    }

    .gform_wrapper .ginput_complex .ginput_left {
        padding-right: 8px;
    }

    .gform_wrapper .left_label .ginput_complex.ginput_container_address:after {
        content: "";
        display: block;
    }

    .gform_wrapper .ginput_complex.ginput_container_address .ginput_left {
        margin-right: 8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address .ginput_right {
        margin-right: -8px;
        padding-right: 8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_full + span.ginput_right {
        padding-right: 8px !important ;
        margin-right: 8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left {
        padding-right: 8px !important ;
        margin-right: -8px;
    }

    .gform_wrapper .ginput_complex.ginput_container_address span.ginput_right + span.ginput_left.address_zip {
        margin-right: 8px;
    }

    .gform_wrapper .gfield_error .ginput_container {
        padding-right: 0;
    }

    .gform_wrapper ul.top_label li.gfield.gfield_error.gfield_contains_required .gfield_password_strength {
        margin: 18px 0 18px 0;
    }

    .gform_wrapper .gform_footer:not(.top_label) {
        padding: 16px 0 10px 0;
        margin-left: 29%;
        width: 70%;
    }

    /* adjusting the name field so it aligns properly */

    .top_label div.ginput_complex.ginput_container.gf_name_has_1,
    .top_label div.ginput_complex.ginput_container.gf_name_has_2,
    .top_label div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(100% + 15px);
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_4 {
        width: calc(100% + 13px);
    }

    .top_label div.ginput_complex.ginput_container.gf_name_has_5 {
        width: calc(100% + 14px);
    }

    /* a little better specificty to avoid problems with forms in widgets */

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_1,
    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_2,
    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(70% + 15px);
        margin-left: 29%;
    }

    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1,
    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2,
    ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 {
        width: calc(70% + 24px);
    }

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_4 {
        width: calc(70% + 13px);
        margin-left: 29%;
    }

    ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.gf_name_has_5 {
        width: calc(70% + 14px);
        margin-left: 29%;
    }

    div.ginput_container_name span {
        display: -moz-inline-stack;
        display: inline-block;
        vertical-align: top;
        padding-right: 16px;
        margin-right: -4px;
    }

    div.ginput_complex.ginput_container.gf_name_has_1 span {
        width: 100%;
    }

    div.ginput_complex.ginput_container.gf_name_has_2 span {
        width: 50%;    }

    div.ginput_complex.ginput_container.gf_name_has_3 span {
        width: 33.3%;
    }

    div.ginput_complex.ginput_container.gf_name_has_4 span {
        width: 25%;
    }

    div.ginput_complex.ginput_container.gf_name_has_5 span {
        width: 19.95%;
    }

    /* tweak the sizes for validation and left/right label layouts */

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_1 span {
        width: 99.75% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_2 span {
        width: 49.75% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_3 span {
        width: 33% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_4 span {
        width: 24.75% !important;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.gf_name_has_5 span {
        width: 19.75% !important;
    }

    html div.ginput_complex.ginput_container.gf_name_has_2 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_3 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_4 span:first-child,
    html div.ginput_complex.ginput_container.gf_name_has_5 span:first-child {
        margin-left: 0!important;
    }

    .gform_wrapper .top_label .ginput_complex.ginput_container.ginput_container_email {
        width: calc(100% + 15px);
    }

    .gform_wrapper ul.gform_fields:not(.top_label) div.ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 16px);
        margin-left: 29%;
    }

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error div.ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 17px);
    }

    li.gfield.gfield_error

    .gform_wrapper ul.gform_fields:not(.top_label) li.gfield.gfield_error .ginput_complex.ginput_container.ginput_container_email {
        width: calc(70% + 8px) !important;
    }

    .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_left,
    .gform_wrapper .ginput_complex.ginput_container.ginput_container_email .ginput_right {
        padding-right: 16px;
    }

    .gform_wrapper ul.left_label li.gfield .gfield_password_strength,
    .gform_wrapper ul.right_label li.gfield .gfield_password_strength {
        width: 70%;
    }

    /* adjusting the address field alignment for some scenarios */

    .gform_wrapper .ginput_complex span.ginput_left + input.gform_hidden + span.ginput_left {
        padding-right: 0;
    }

    .gform_wrapper .ginput_complex span.ginput_left.address_country {
        margin-right: 0;
    }

    html:not[dir="rtl"] .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container:not(.ginput_container_time),
    html:not[dir="rtl"] .gform_wrapper ul.gform_fields:not(.top_label) .clear-multi,
    html:not[dir="rtl"] .gform_wrapper ul.gform_fields:not(.top_label) .gfield_description {
        width: 70%;
        margin-left: 29% !important;
    }

    .gform_wrapper .gf_progressbar {
        width: calc(100% - 16px);
    }

    .gform_wrapper ul.gform_fields:not(.top_label) .gform_fileupload_multifile .gform_drop_area {
        margin-left: 29%;
        width: 70%
    }

    body .gform_wrapper span.ginput_left.address_city + input.gform_hidden + span.ginput_left.address_country {
        width: calc(50% - 8px);
        margin-right: 0;
    }

    /* force the CAPTCHA field over for right and left label layouts */

    .gform_wrapper ul.gform_fields:not(.top_label) .ginput_container.ginput_recaptcha {
        margin-left: 29% !important;
    }

    .gform_wrapper .gform_page_footer .button.gform_previous_button {
        margin-bottom: 0;
    }

    .gform_wrapper .gform_page_footer .button.gform_next_button,
    .gform_wrapper .gform_page_footer .button.gform_button {
        margin-right: 10px;
    }

}

