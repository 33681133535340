@import url('https://fonts.googleapis.com/css?family=Open+Sans:100,200,300,400');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,600,700');

body {
    font-family: 'Montserrat', sans-serif;
    font-size: .8em;
    line-height: 25px;
    color: #4b4b4b;
    overflow: auto;
}

body > div:nth-child(2){
    padding-top: 50px;
}

h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
    font-size: 2.7em;
}

h2 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 2.5em;
}

h2 > span {
    display: block;
    font-size: .38em;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px;
}

p {
    margin: 25px 0;
}

.height-vh {
    height: calc(100vh - 60px);
    min-height: 800px;
}
.min-height-vh {
    min-height: 100vh;
}

.default-background {
    background-image: linear-gradient(to right, #371a90, #2f4cce);
    color: white;
}

.section {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}

.section.supplier{
    padding-bottom: 0;
}

.slanted {
    width: 100%;
    position: absolute;
    bottom: -1px;
}

.slanted.top {
    bottom: inherit;
    top: -1px;
}


/* navbar */

.navbar {
    padding: 0;
    overflow: visible;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: -3px;
}





.navbar .hidden-md-up > div {
    margin-top: 70px;
    box-shadow: 0 8px 8px 2px rgba(0, 0, 0, 0.38);
}
.navbar .hidden-md-up {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}



.navbar-brand {
    height: 10px;
    transform: translateY(25px);
}

.navbar .menu-item a {
    color: #ffffff;
}

.navbar .menu-item {
    padding: 12px 12px;
    padding-bottom: 6px;
    font-size: 1em;
    font-weight: 600;
    height: 100%;
    cursor: pointer;
    font-size: 13px;
    background: #323ebd;
    transition: all .5s ease-in-out;
}
 .navbar.transparant .menu-item,
 .navbar.transparant .button-wrapper {
  background: transparent;
}

.navbar .menu-item:first-child {
  border-bottom-left-radius: 4px;
}

.navbar .menu-item:last-child {
}

.navbar .menu-item.active {
    background: #32baec;
}

/* button */
.button *{
    color: #fff;
}
.button {
    color: white!important;
    background-image: linear-gradient(to right, #50c0d4 0%, #2e7fc1 51%, #50c0d4 100%);
    background-size: 200% auto;
    text-transform: uppercase;
    font-weight: 800;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    text-align: center;
    font-size: 13px;
    letter-spacing: 1.1px;
    transform: scale(1, 0.8);
    -webkit-transform: scale(1, 0.9); /* Safari and Chrome */
    -moz-transform: scale(1, 0.9); /* Firefox */
    -ms-transform: scale(1, 0.9); /* IE 9+ */
    -o-transform: scale(1, 0.9); /* Opera */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: none;
    position: relative;
    padding: 15px 36px 15px 15px;
    min-width: 200px;
    border-radius: 2px;
}

.ReactCollapse--collapse {
  transition: height 350ms;
}

.whitepaper-popup-container {
  position: fixed;
  z-index: 1000;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.2);
}

.whitepaper-popup-content {
  background-color: #fff;
  margin: auto;
  padding: 30px;
  width: 550px;
  border-radius: 3px;
}

.custom-field.two input {
  border-radius: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background:
    linear-gradient(90deg,  #606cfe, #606cfe) center bottom/0 0.15em no-repeat,
    linear-gradient(90deg, #75cbfe, #75cbfe) left bottom/100% 0.15em no-repeat,
    linear-gradient(90deg, #fafafa, #fafafa) left bottom/100% no-repeat;
  transition: background-size 0.3s ease;
}

.custom-field {
  position: relative;
  font-size: 14px;
  border-top: 20px solid transparent;
  margin-bottom: 5px;
  --field-padding: 12px;
}

.custom-field input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 250px;
  outline: none;
  font-size: 14px;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  color: #aaa;
  transition: 
    top 0.3s ease,
    color 0.3s ease,
    font-size 0.3s ease;
}

.custom-field.two input.dirty,
.custom-field.two input:not(:placeholder-shown),
.custom-field.two input:focus {
  background-size: 100% 0.15em, 100% 0.1em, 100%;
}

.custom-field input.dirty + .placeholder,
.custom-field input:focus + .placeholder,
.custom-field input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #606cfe;
}

.button:not(.my-ltx):after {
    content: url('./pages/images/svg/arrow-btn.svg');
    font-family: 'Zapf Dingbats';
    color: #fff;
    position: absolute;
    opacity: 1;
    transition: all 0.2s ease-in-out;
    top: 17px;
    right: 16px;
    font-size: 12px;
}
.button:hover:after {
    opacity: 0;
    right: 50px;
}

.button:hover {
    background-position: right center; /* change the direction of the change here */
    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, .15), 0 5px 20px 0 rgba(0, 0, 0, .1);
    padding: 15px;
}

.button.arrow:after {

}

.button.try {
  box-shadow: none;
  background-image: none;
  background: #3080c2;
  font-weight: normal;
  display: inline-block;
}

.button.alt {
    background-image: linear-gradient(to right, #2f7fc4 0%, #7629c3 51%, #2f7fc4 100%);
}

.button.gform_button  {
    padding: 15px;
}
.button.my-ltx {
    background: #e71e7a;
    width: 150px;
    font-size: 1em;
    margin-top: 10px;
    line-height: 15px;
    height: 45px;
    padding: 15px;
    min-width: 150px;

}
.button.start {
    background: #e71e7a;
}

.button.demo {
  background: #FF7A0F;
}

.button.disable {
  background: gray;
}

/* opening */
.opening {
    /*text-shadow: 0 0 10px rgba(0, 0, 0, 0.07);*/
}

.opening h1 span {
    color: #44c3ff;
}

.opening p {
    font-weight: 600;
    letter-spacing: .9px;
}

@media only screen and (min-width: 768px) {
    .opening .container > .row {
        position: relative;
        top: 35vh;
        transform: translateY(-50%);
    }

    .section:not(.opening) .row > div:not(:first-child) {
        /*padding-left: 50px;*/
    }

    .navbar-expand-md .navbar-nav {
        margin-left: auto;
    }

    .navbar-brand{
        margin-left: 20px;
    }


    .hidden-md-up{
        display: none;
    }
}

/* speed */
.floating-img img {
    position: absolute;
    margin-left: 25px;
    width: calc(100% - 150px);
    left: 0;
    top: 0;
}

@media only screen and (max-width: 768px) {

    .floating-img {
        height: 200px;
    }

    .navbar-brand {
        transform: translateX(85%);
    }

    .opening .container > .row {
        margin-top: 100px;
    }
    .hidden-md-down{
        display: none;
    }

    .navbar.transparant { 
      box-shadow: none;
    }

    .navbar {
      background-image: linear-gradient(to right, #371a90, #2f4cce)!important;
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
      transition: box-shadow .5s ease-in-out;
    }
    .navbar > .container-fluid 
    {
      padding: 10px
    }
    .navbar .button-wrapper {
      background: transparent;
    }
    .button-wrapper .button.my-ltx {
      margin-top: 0;
    }

    .navbar-brand {
    padding-top: 0.7rem;
    }
}

/* tabs */
.icon-tabs {

}
.collab .col-10 p {
    margin-top: 0;
}
.tabs ul {
    list-style: none;
    display: flex;
    padding: 0;
    text-align: center;

}

.tabs li {
    cursor: pointer;
    transition: all .1s ease-in-out;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.tabs .content > div {
    opacity: 0;
    transition: opacity .15s ease-in-out;
}

.tabs .content > div.active {
    opacity: 1;
}

.icon-tabs li {

    flex: 1;
}

.icon-tabs li .img-wrapper {
    height: 30px;
}

.icon-tabs li span {
    display: block;
    padding-top: 20px;
    border-top: 2px solid #dee3e6;
    margin-top: 20px;
    position: relative;
    text-transform: uppercase;
    font-weight: bold;
}

.icon-tabs li span::after {
    transition: all .1s ease-in-out;
    bottom: 0;
    content: "";
    display: block;
    margin: 0 auto;
    position: absolute;
    left: calc(50% - 6px);
    top: -6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
    border: 3px solid #dee3e6;
}

.icon-tabs .content {
    background: #edf2f5;
    height: 300px;
    position: relative;
}

.icon-tabs .content > div {
position: absolute;
top: 50px;
left: 50px;
right: 50px;
}

.icon-tabs li.active {
    color: #64a7e2;
}

.icon-tabs li.active span::after {
    left: calc(50% - 9px);
    top: -9px;
    width: 18px;
    height: 18px;
    border-color: #64a7e2;
    border-width: 5px;
}

.title {
    font-size: 2em;
    font-weight: bold;
}

.text-tabs ul {
    color: #8480bc;
    margin: 70px 0 30px;
}

.text-tabs li.active {
    color: white;
}

/* prices */
.price-card-wrapper {
 padding: 0
}


.price-card {
    background: white;
    color: #363636;
    text-align: center;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    margin-top: 30px;
    position: relative;
    padding: 30px 10px 0 10px;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
}

.price-card::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 4px;
    background-image: linear-gradient(to right, #75cbfe, #606cfe);
}

.col-md-4:nth-child(2) .price-card::before {
    background-image: linear-gradient(to right, #fedd01, #fe5a01);
}

.col-md-4:nth-child(3) .price-card::before {
    background-image: linear-gradient(to right, #56ff74, #d75cfe);
}

.col-md-4:nth-child(4) .price-card::before {
    background-image: linear-gradient(to right, #fedd01, #fe5a01);
}

.price-card .name {
    font-size: 1.8em;
    /* font-weight: bold; */
    color: #3334b0;
}

.card-premium .name {
  font-size: 1.8em;
  color: #3334b0;
}

.price-card .price {
    margin-top: 20px;
    font-size: 2em;
    font-weight: 600;
}
.default-background .price-card hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 30px ;
  margin-bottom: 30px ;
}

.price-card .note {
  font-size: 1.1em;
}

.card-premium .price {
  margin-top: 20px;
  font-size: 2em;
  font-weight: 600;
}
.default-background .card-premium hr {
border-top: 1px solid rgba(0, 0, 0, 0.1);
margin-top: 20px;
margin-bottom: 10px;
width: 80%;
}

.card-premium .note {
font-size: 1.1em;
}

.lrg-price {
    font-size: 1.5em;
    color: #75cbfe;
    margin-top: 20px;

}

/* new cards style */

.card-container-premium {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.card-premium {
  transition: 0.4s ease-out;
  position: relative;
  left: 0px;
  background: white;
  color: #363636;
  text-align: center;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  position: relative;
  padding: 30px 5px 0 0;
  height: 28rem;
  border-radius: 3px;
  overflow: hidden;
}

.card-premium:not(:first-child) {
    margin-left: -50px;
}

.card-premium:hover {
  transition: 0.4s ease-out;
  min-width: 120px;
}

.card-premium:hover ~ .card-premium {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}


.card-premium::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 4px;
  background-image: linear-gradient(to right, #75cbfe, #606cfe);
}


@media (max-width: 768px) {
  .card-container-premium {
    flex-direction: column;
  }
  
  .card-premium {
    margin-top: 30px;
  }

  .card-premium:hover {
    transform: none;
  }

  .card-premium:not(:first-child) {
    margin-left: 0;
  }  

  .card-premium:hover ~ .card-premium {
    left: 0;
    transition: none;
  }
}



/* footer */

.footer {
    padding-top: 50px;
    background: rgb(5, 8, 59);
    color: rgba(255, 255, 255, 0.5);

}

.footer * {
    color: rgba(255, 255, 255, 0.5);

}

.footer h4 {
    color: #ffffff;
    font-size: 1.2em;
    font-weight: bold;
}

.footer .bottom-bar {
    background: rgb(3, 4, 48);
}

.footer .bottom-bar .textwidget {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: space-between;
    margin: 0 20px;
}

.footer p {
    margin: 15px 0;
}


/* animations */
.reveal
{
    opacity: 0;
    transition: all .5s ease-in-out;
    transform: translateY(20px);
}

.reveal.reveal_visible{
    opacity: 1;
    transform: none;
}


a:hover
{
    text-decoration: none;
}


/* input */
.ginput_container > * {
    background: #f2f2f2;
    border: 0;
    transition: background-color .2s ease-in-out;
}
.ginput_container > * {
    background: #e6e6e6;
}


.list-style-none {
    list-style-type: none;
    padding-left: 0;

}

.contact-gegevens a{
    color: white;
}
.contact-gegevens img{
    margin: 10px;
    width: 30px;
}

.card-item {
}

.card-item > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 12px;
  font-size: 1.1em;
}


.intro-card {
  background: white;
  border-radius: 2px;
  color: #4b4b4b;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
  padding-top: .2rem;
  padding-bottom: .2rem;

}

.default-background hr {
  border-color: rgba(255, 255, 255, 0.2);
}

hr {
  margin-top: .2rem;
  margin-bottom: .2rem;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,600,700');


.navbar, .navbar>.container, .navbar>.container-fluid {
  position: sticky;
  background: none;
}

.app {
  background-image: linear-gradient(90deg,#371a90,#2f4cce);
}


.button-wrapper {
  background:  #323ebd; 
  padding-left: 6px; 
  padding-right: 6px; 
  transition: all .5s ease-in-out;
}

.navbar > .container, .navbar > .container-fluid {
  align-items: initial;
  padding-right: 0;
}


.join-steps .card-item img {
  width: 50px;
  padding: 7px;
}


.lang-switch {
  width: 26px;
  height: 26px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  border: 3px solid white
}

.prices > div{
padding: 20px
}

@media only screen and (min-width: 768px) { 
  .prices > div{
  padding: 0
  }
  .price-pos-1 {
    z-index: 1;
    transform: translateY(-5px)
  }
  .price-pos-2 {
    z-index: 2;
    transform: translateY(-10px)
  }

  
}
